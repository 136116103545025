import { FC } from "react";
import {
  RiAppleFill,
  RiGoogleFill,
  RiHandCoinFill,
  RiAccountPinCircleFill,
  RiMastercardFill,
  RiPaypalFill,
  RiWallet3Fill,
  RiArrowDownSLine,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";

type Props = {
  dropDownRef: any;
  setCurrencyDropwDown: any;
  selectedMethod: any;
  openCurrencyDropDown: any;
  paymentMethods: any;
  selectMethod: any;
};

const PaymentOptions: FC<Props> = ({
  dropDownRef,
  setCurrencyDropwDown,
  selectedMethod,
  paymentMethods,
  openCurrencyDropDown,
  selectMethod,
}) => {
  const navigate = useNavigate();

  //Component ===============
  return (
    <div
      ref={dropDownRef}
      className="w-full h-[4rem] flex justify-between items-center rounded-md bg-slate-50 dark:bg-black-700
       hover:border-cyan-700/60 transition-all cursor-pointer select-none relative group
        border border-slate-300 dark:border-black-600"
    >
      {/**Display Selected Method ==================================== */}
      <div
        onClick={() => {
          setCurrencyDropwDown(true);
        }}
        className="flex justify-between items-center w-full h-full p-2 px-3"
      >
        <div className="h-full w-[80%] flex gap-6 items-center">
          <div
            className="h-9 w-9 min-w-[2.25rem] border border-slate-200 dark:border-black-600 bg-slate-100 dark:bg-black-800
            rounded overflow-hidden flex items-center justify-center ring-1 ring-offset-4 dark:ring-offset-black-900 ring-slate-300
             dark:ring-black-600 text-xl text-slate-700 dark:text-black-100"
          >
            {selectedMethod?.name === "paypal" ? (
              <RiPaypalFill />
            ) : selectedMethod?.name === "card" ? (
              <RiMastercardFill />
            ) : selectedMethod?.name === "account" ? (
              <RiAccountPinCircleFill />
            ) : selectedMethod?.name === "cash" ? (
              <RiHandCoinFill />
            ) : selectedMethod?.name === "google pay" ? (
              <RiGoogleFill />
            ) : selectedMethod?.name === "apple pay" ? (
              <RiAppleFill />
            ) : (
              <RiWallet3Fill />
            )}
          </div>
          <span
            className="text-xs font-semibold text-slate-700 dark:text-black-200 capitalize w-[calc(100%-4rem)] overflow-hidden
                whitespace-nowrap text-ellipsis truncate"
          >
            Paying with {selectedMethod?.name}
          </span>
        </div>
        <RiArrowDownSLine
          className={`text-2xl text-slate-500 dark:text-black-300 transition-all ${
            openCurrencyDropDown && "rotate-180"
          }`}
        />
      </div>

      {/**Payment Options Drop Down ================================== */}
      <div
        className={`absolute -right-0.5 -left-0.5 overflow-hidden bg-white dark:bg-black-700 border border-slate-300 dark:border-black-500
               rounded-lg p-4 transition-all duration-200 flex flex-col space-y-2 ${
                 openCurrencyDropDown
                   ? "min-h-[2.5rem] h-fit top-[4.5rem] z-10 shadow-2xl drop-shadow-2xl"
                   : "h-0 top-0 -z-10"
               }`}
      >
        <div className="overflow-hidden overflow-y-scroll max-h-[13.5rem] h-fit flex flex-col space-y-2 pr-1">
          {paymentMethods?.map((method: any) => {
            return (
              <div
                onClick={() => {
                  selectMethod(method);
                  setCurrencyDropwDown(false);
                }}
                key={method?.name}
                className={`h-12 min-h-[3rem] w-full rounded flex items-center space-x-3 px-3 p-1 bg-slate-50 dark:bg-black-800
                 hover:text-cyan-800 transition-all border ${
                   method?.name === selectedMethod?.name
                     ? "text-cyan-800 border-cyan-700/60"
                     : "border-slate-200 dark:border-black-600 dark:text-black-300 text-slate-600"
                 } cursor-pointer`}
              >
                <div
                  className="h-6 w-6 min-w-[1.5rem] border border-slate-200 dark:border-black-600 dark:bg-black-800 bg-slate-100
                   rounded-sm overflow-hidden flex items-center justify-center ring-1
                   ring-offset-4 dark:ring-offset-black-900 ring-slate-300 dark:ring-black-700 text-sm text-slate-700 dark:text-black-200"
                >
                  {method?.name === "paypal" ? (
                    <RiPaypalFill />
                  ) : method?.name === "card" ? (
                    <RiMastercardFill />
                  ) : method?.name === "account" ? (
                    <RiAccountPinCircleFill />
                  ) : method?.name === "cash" ? (
                    <RiHandCoinFill />
                  ) : method?.name === "google pay" ? (
                    <RiGoogleFill />
                  ) : method?.name === "apple pay" ? (
                    <RiAppleFill />
                  ) : (
                    <RiWallet3Fill />
                  )}
                </div>
                <span className="text-xs text-center font-semibold capitalize whitespace-normal truncate">
                  {method?.name}
                </span>
              </div>
            );
          })}
        </div>

        {/**Add New Method Btn ======================================= */}
        <button
          type="button"
          onClick={() => {
            setCurrencyDropwDown(false);
            navigate("/app/settings");
          }}
          className="h-12 w-full text-xs uppercase text-white font-semibold rounded-md
               bg-cyan-800 hover:bg-cyan-700 transitio-all outline-none focus:outline-none"
        >
          add payment method
        </button>
      </div>
    </div>
  );
};

export default PaymentOptions;
