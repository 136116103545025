import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { retriveObjectLocal } from "./SettingsSlice";
import { crypt } from "../../Custom Functions/Functions";

interface InitialStateType {
  cart: any;
  selected_product: any;
  previewOpen: boolean;
}

const initialState: InitialStateType = {
  cart: (() => {
    return retriveObjectLocal("cart") || {};
  })(),
  selected_product: null,
  previewOpen: false,
};

export const OrderSlice = createSlice({
  name: "OrderData",
  initialState,
  reducers: {
    updateCart: (state, action: PayloadAction<any>) => {
      state.cart = action.payload;
      action.payload &&
        window.localStorage.setItem(
          "cart",
          JSON.stringify(
            crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
          )
        );
    },
    openPreview: (state, action: PayloadAction<boolean>) => {
      state.previewOpen = action.payload;
    },
    customizeProduct: (
      state,
      action: PayloadAction<InitialStateType["selected_product"]>
    ) => {
      state.selected_product = action.payload;
    },
  },
});

export const { updateCart, customizeProduct, openPreview } = OrderSlice.actions;

export default OrderSlice.reducer;
