import{ FC } from "react";
import { RiSearch2Line } from "react-icons/ri";

type Props = {
  inputRef: any;
  setSearch: any;
  search: any;
};

const SearchBar :FC<Props> = ({
  inputRef,
  setSearch,
  search,
}) => {
  //Component ===========================
  return (
    <div
      className="w-full h-14 transition-all flex items-center justify-between shrink-0 relative
        border-b-2 border-slate-300 dark:border-black-500 focus-within:border-b-cyan-800/50 overflow-hidden px-4"
    >
      <RiSearch2Line className="absolute left-0 top-4 text-slate-500 text-2xl" />
      <input
        ref={inputRef}
        onChange={(e) => {
          setSearch(e.target.value);
          //setFocusIndex(-1);
        }}
        autoFocus
        value={search}
        autoComplete="off"
        type="search"
        name="main_search"
        id="main_search"
        placeholder="Search by keyword ..."
        className="w-full h-full border-0 rounded-sm bg-inherit pl-5 text-xs text-slate-600
         placeholder:text-slate-400 focus:ring-0 focus:border-0 transition-all
           focus:outline-none outline-none"
      />
    </div>
  );
};

export default SearchBar;
