import { FC } from "react";
import { TbChevronLeft } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "../../Components/Date Picker/DatePicker";
import { changeSalesDate } from "../../Redux/Slices/SalesSlice";
import { RootState } from "../../Redux/store";
import { Select } from "@mantine/core";

type Props = {
  filtersOpen: boolean;
  setFilters: any;
  currentView: any;
  setView: any;
  setSort: any;
  sortBy: any;
};

const Filters: FC<Props> = ({
  filtersOpen,
  setFilters,
  currentView,
  setView,
  setSort,
  sortBy,
}) => {
  const sales_date = useSelector((state: RootState) => state.Sales.sales_date);
  const dispatch = useDispatch();

  //Component ===============================
  return (
    <div
      className={`flex flex-col bg-white dark:bg-black-900 fixed top-0 ${
        filtersOpen ? "left-0 right-0" : "-left-[200%] right-[200%]"
      } bottom-0 z-[9999] p-4 transition-all duration-200 overflow-hidden overflow-y-scroll
      no-scrollbar no-scrollbar::webkit-scrollbar`}
    >
      <button
        onClick={() => {
          setFilters(false);
        }}
        className="bg-inherit h-8 w-8 focus:outline-none stroke-[4] text-xl text-slate-600 dark:text-black-100"
      >
        <TbChevronLeft />
      </button>
      <div className="mt-4 w-full h-fit flex flex-col gap-3">

      <DatePicker
        dates={sales_date}
        additionalStyles={`h-12 w-full bg-white dark:bg-black-700 rounded-lg md:rounded border border-slate-300
        dark:border-black-600 text-xs dark:text-black-200 text-slate-500 font-medium px-2`}
        changeDate={(e: any) => dispatch(changeSalesDate(e))}
        parentWidth="flex w-full"
      />

      <div
        className="rounded-lg md:rounded h-12 w-full bg-white dark:bg-black-700 relative border border-slate-300
         dark:border-black-600 hover:border-cyan-800/50 transition-all flex items-center justify-between
          px-3 text-xxs text-slate-600 dark:text-black-200 font-semibold cursor-pointer group"
      >
        <Select
          classNames={{
            input: `text-xxs text-slate-600 dark:text-black-200 font-semibold uppercase focus:ring-0 border-none px-0 w-full bg-inherit`,
            root: `text-xxs text-slate-600 dark:text-black-200 font-semibold uppercase focus:ring-0 border-none px-0 w-full bg-inherit`,
            item: `selected:bg-cyan-800 text-xxs text-slate-600 dark:text-black-200 font-semibold uppercase`,
          }}
          placeholder={currentView}
          transitionProps={{
            transition: "pop-top-left",
            duration: 80,
            timingFunction: "ease",
          }}
          onChange={(e) => {
            setView(e);
            window.localStorage.setItem("salesView", JSON.stringify(e));
          }}
          data={[
            { value: "completed sales", label: "completed sales" },
            { value: "parked sales", label: "parked sales" },
          ]}
        />
      </div>

      <div
        className="rounded-lg md:rounded h-12 w-full bg-white dark:bg-black-700 relative border border-slate-300
        dark:border-black-600 hover:border-cyan-800/50 transition-all flex items-center justify-between
         px-3 text-xxs text-slate-600 font-semibold cursor-pointer group"
      >
        <Select
          classNames={{
            input: `text-xxs text-slate-600 dark:text-black-200 font-semibold uppercase focus:ring-0 border-none px-0 w-full bg-inherit`,
            root: `text-xxs text-slate-600 dark:text-black-200 font-semibold uppercase focus:ring-0 border-none px-0 w-full bg-inherit`,
            item: `selected:bg-cyan-800 text-xxs text-slate-600 dark:text-black-200 font-semibold uppercase`,
          }}
          placeholder={"Sort by " + sortBy}
          transitionProps={{
            transition: "pop-top-left",
            duration: 80,
            timingFunction: "ease",
          }}
          onChange={(e) => {
            setSort(e);
            window.localStorage.setItem("salesSort", JSON.stringify(e));
          }}
          data={[
            { value: "date", label: "Date" },
            { value: "total", label: "Total" },
            { value: "status", label: "status" },
            { value: "sale_channel", label: "Sales Channel" },
            { value: "payment method", label: "Payment Method" },
          ]}
        />
      </div>
      </div>
    </div>
  );
};

export default Filters;
