import { FC, useState } from "react";
import ProductsHalfContainer from "./Containers/ProductsHalfContainer";
import CartContainer from "./Containers/CartContainer";


const PointOfSell: FC = () => {
  const [smallScreenCart, setSmCart] = useState<boolean>(false);

  //Component =========
  return (
    <div
      className="w-full h-full print:hidden flex justify-between overflow-hidden px-4 py-4 pb-1
       lg:py-0 gap-4 bg-light-bg dark:bg-black-700 relative"
    >
      <ProductsHalfContainer setSmCart={setSmCart} />
      <CartContainer smallScreenCart={smallScreenCart} setSmCart={setSmCart} />
    </div>
  );
};

export default PointOfSell;
