import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialInterface {
  alerts: string | any;
  messages: string | any;
}

const initialState: InitialInterface = {
  alerts: [],
  messages: [],
};

const NotificationsSlice = createSlice({
  name: "NotificationsData",
  initialState,
  reducers: {
    updateAlert: (state, action: PayloadAction<string[] | any>) => {
      if (Array.isArray(action.payload)) {
        state.alerts = action.payload;
      } else {
        state.alerts = [
          ...state.alerts,
          {
            ...action.payload,
            id: new Date()?.valueOf(),
            color:
              action.payload?.color === "green"
                ? "bg-green-200"
                : action.payload?.color === "red"
                ? "bg-red-200"
                : action.payload?.color === "yellow"
                ? "bg-yellow-200"
                : action.payload?.color,
          },
        ];
      }
    },
    setMessages: (state, action: PayloadAction<string[] | any[]>) => {
      state.messages = action.payload;
    },
  },
});
export const { updateAlert, setMessages } = NotificationsSlice.actions;

export default NotificationsSlice.reducer;
