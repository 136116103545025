import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  CACHE_SIZE_UNLIMITED,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "firebase/firestore";

//Config Firebase ==================================
export const firebaseConfig = {
  apiKey: import.meta.env.VITE_API_KEY,
  authDomain: import.meta.env.VITE_AUTH_DOMAIN,
  projectId: "stall-d635a",
  storageBucket: "stall-d635a.appspot.com",
  messagingSenderId: import.meta.env.VITE_MS_ID,
  appId: import.meta.env.VITE_APP_ID,
  measurementId: "G-4PJ9S2MJP4",
};

// Detect if it is dev mode
declare global {
  interface Window {
    FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string;
  }
}

if (typeof window !== "undefined" && window.location.hostname === "localhost") {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = import.meta.env
    .VITE_APP_CHECK_KEY as string;
}

// initializeApp() is called before getAuth() for sure
export const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
let AppCheckInstance = null;

if (typeof window !== "undefined" && !AppCheckInstance) {
  import("firebase/app-check").then(async (firebaseAppCheck) => {
    const captachp = import.meta.env.VITE_APP_CHECK_KEY as string;
    AppCheckInstance = firebaseAppCheck.initializeAppCheck(app, {
      provider: new firebaseAppCheck.ReCaptchaEnterpriseProvider(captachp),
      isTokenAutoRefreshEnabled: true,
    });
    console.log("AppCheckInstance", AppCheckInstance);
  });
}

// init services for firestore =========================
export const db = initializeFirestore(app, {
  localCache: persistentLocalCache(
    /*settings*/ {
      tabManager: persistentMultipleTabManager(),
      cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    }
  ),
});

//Get Current Logged User Related Data
export const auth = getAuth();
