/* eslint-disable no-unsafe-optional-chaining */
import { FC, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import Sale from "./Sale";
import { Link } from "react-router-dom";
import { parkSales } from "../../Redux/Slices/SalesSlice";
import ActionPanel from "../../Components/Misc/ActionPanel";
import SalePreview from "./SalePreview";
import Filters from "./Filters";
import TopNav from "./TopNav";
import { Ring } from "@uiball/loaders";
import { TbChevronLeft, TbChevronRight } from "react-icons/tb";

const Sales: FC = () => {
  const fetched_sales = useSelector(
    (state: RootState) => state.Sales.completed_sales
  );
  const parked_sales = useSelector(
    (state: RootState) => state.Sales.parked_sales
  );
  const initialView = window.localStorage.getItem("salesView");
  const [currentView, setView] = useState<any>(
    initialView ? JSON.parse(initialView) : "completed sales"
  );
  const initialSort = window.localStorage.getItem("salesSort");
  const [sortBy, setSort] = useState<string>(
    initialSort ? JSON.parse(initialSort) : "date"
  );
  const [searchValue, setSearch] = useState<any>("");
  // initial limit of items to display
  const [pagination, setPagination] = useState<any>({ start: 0, end: 30 });
  const sales = useMemo(() => {
    const data =
      currentView === "completed sales" ? fetched_sales : parked_sales;
    return [
      ...data?.filter(
        (data: any) =>
          !data?.isDeleted &&
          (Object.values(data)?.some((item: any) =>
            item
              ?.toString()
              ?.toLowerCase()
              ?.replace(/\s/gim, "")
              ?.includes(
                searchValue?.toString()?.toLowerCase()?.replace(/\s/gim, "")
              )
          ) ||
            Object.values(data?.customers_details)?.some((item: any) =>
              item
                ?.toString()
                ?.toLowerCase()
                ?.replace(/\s/gim, "")
                ?.includes(
                  searchValue?.toString()?.toLowerCase()?.replace(/\s/gim, "")
                )
            ) ||
            Object.values(data?.user)?.some((item: any) =>
              item
                ?.toString()
                ?.toLowerCase()
                ?.replace(/\s/gim, "")
                ?.includes(
                  searchValue?.toString()?.toLowerCase()?.replace(/\s/gim, "")
                )
            ) ||
            data?.products?.some((item: any) =>
              item?.name
                ?.toString()
                ?.toLowerCase()
                ?.replace(/\s/gim, "")
                ?.includes(
                  searchValue?.toString()?.toLowerCase()?.replace(/\s/gim, "")
                )
            ))
      ),
    ]
      ?.sort((a: any, b: any) => (a[sortBy] > b[sortBy] ? -1 : 1))
      ?.slice(pagination?.start, pagination?.end);
  }, [
    fetched_sales,
    parked_sales,
    searchValue,
    currentView,
    sortBy,
    pagination,
  ]);
  const [markedArray, markItem] = useState<any[]>([]);
  const dispatch = useDispatch();
  const [filtersOpen, setFilters] = useState<boolean>(false);
  const [openPanel, setActionPanel] = useState<boolean>(false);
  const [currentSale, setCurrentSale] = useState<any>(null);
  const salesDataLoading = useSelector(
    (state: RootState) => state.Sales.salesDataLoading
  );

  //Delete parked cart
  const deleteParked = () => {
    markedArray.forEach((sale: any) => {
      if (currentView === "parked sales") {
        //Update State
        dispatch(
          parkSales([
            ...(parked_sales?.length >= 1
              ? parked_sales?.filter(
                  (record: any) => record?.transact_id !== sale?.transact_id
                )
              : []),
          ])
        );
        //Save Data Locally
        window.localStorage.setItem(
          "parked_sales",
          JSON.stringify([
            ...(parked_sales?.length >= 1
              ? parked_sales?.filter(
                  (record: any) => record?.transact_id !== sale?.transact_id
                )
              : []),
          ])
        );
        markItem([]);
      }
    });
  };

  //Component ==========
  return (
    <>
      <div
        className="w-full h-full overflow-hidden p-4 bg-light-bg dark:bg-black-800 flex flex-col
         gap-4 overflow-y-scroll no-scrollbar webkit::-scrollbar"
      >
        {/**Top Nav ============ */}
        <TopNav
          setSort={setSort}
          sortBy={sortBy}
          setView={setView}
          currentView={currentView}
          markedArray={markedArray}
          setActionPanel={setActionPanel}
          setSearch={setSearch}
          searchValue={searchValue}
          setPagination={setPagination}
          setFilters={setFilters}
        />
        {/**Top Nav ============ */}

        {/**Sales Table */}
        <div
          className="flex flex-col h-[calc(100%-3rem)] bg-transparent md:bg-white dark:bg-black-800 md:rounded md:border
         border-slate-300 dark:border-black-600 overflow-hidden"
        >
          <table
            className="w-full h-full md:h-[calc(100%-3rem)] relative overflow-hidden flex
           flex-col bg-transparent"
          >
            <thead className="hidden md:flex h-fit w-full">
              <tr
                className="h-12 w-full bg-slate-50/30 border-b border-slate-200 dark:border-black-600 dark:bg-black-700
                grid grid-cols-5 lg:grid-cols-12 gap-1 text-slate-600 dark:text-black-300"
              >
                <th
                  className="h-full col-span-1 overflow-hidden hidden
               lg:flex items-center justify-center text-ellipsis whitespace-nowrap"
                >
                  <input
                    type="checkbox"
                    name="select_all"
                    id="select_all"
                    className="rounded h-4 w-4 border-slate-400 dark:border-black-600 bg-light-bg dark:bg-black-500"
                    checked={
                      markedArray?.length === sales.length &&
                      markedArray?.length >= 1
                        ? true
                        : false
                    }
                    onChange={(e: any) => {
                      if (e.target.checked === true) {
                        markItem(sales);
                      } else {
                        markItem([]);
                      }
                    }}
                  />
                </th>
                <th
                  className="h-full pl-4 lg:pl-0 col-span-3 lg:col-span-2 overflow-hidden flex items-center 
                     text-xss font-bold text-ellipsis whitespace-nowrap uppercase"
                >
                  customer&apos;s Name
                </th>
                <th
                  className="h-full col-span-3 overflow-hidden hidden lg:flex items-center 
               text-xss font-bold text-ellipsis whitespace-nowrap uppercase"
                >
                  payment date
                </th>
                <th
                  className="h-full col-span-2 overflow-hidden hidden lg:flex items-center 
               text-xss font-bold text-ellipsis whitespace-nowrap uppercase"
                >
                  Payment Method
                </th>
                <th
                  className="h-full col-span-1 overflow-hidden hidden lg:flex items-center 
               text-xss font-bold text-ellipsis whitespace-nowrap uppercase"
                >
                  Status
                </th>
                <th
                  className="h-full col-span-2 overflow-hidden hidden lg:flex items-center 
                  text-xss font-bold text-ellipsis whitespace-nowrap uppercase"
                >
                  Fulfillment
                </th>
                <th
                  className="h-full col-span-1 overflow-hidden flex items-center justify-end 
                    text-xss font-bold text-ellipsis whitespace-nowrap uppercase px-4"
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody
              className="w-full h-full md:h-[calc(100%-3rem)] overflow-hidden overflow-y-scroll flex flex-col
            no-scrollbar no-scrollbar::-webkit-scrollbar relative"
            >
              <Sale
                sales={sales}
                currentView={currentView}
                markItem={markItem}
                markedArray={markedArray}
                setCurrentSale={setCurrentSale}
                currentSale={currentSale}
              />
              {sales?.length <= 0 && (
                <tr className="w-full h-full flex flex-col items-center justify-center gap-6">
                  <span className="text-xs text-slate-500 dark:text-black-400 font-medium text-center px-6">
                    You currently have no sales recorded, <br />
                    Visit the POS or campaigns page to increase your sales
                  </span>
                  <Link to="/">
                    <div
                      className="h-9 w-36 whitespace-nowrap bg-cyan-800 hover:bg-cyan-800 transition-all 
                      text-white text-xs text-center font-medium capitalize flex items-center justify-center
                       rounded-full hover:opacity-80"
                    >
                      Point of sale
                    </div>
                  </Link>
                </tr>
              )}
            </tbody>
          </table>
          {sales?.length >= 1 && (
            <div className="w-full h-12 hidden md:flex items-center justify-between gap-4 px-4 border-t border-slate-200
             dark:border-black-600 dark:bg-black-800 bg-slate-50/30">
              <span className="text-xxs uppercase font-bold text-slate-600 dark:text-black-200">
                Showing {pagination?.start + 1} - {pagination?.end} /{" "}
                {fetched_sales?.length}
              </span>
              <div
                className="flex items-center h-7 w-20 rounded border border-slate-300 dark:border-black-600
               bg-slate-50/30 dark:bg-black-900 overflow-hidden"
              >
                <button
                  onClick={() => {
                    setPagination((prev: any) => ({
                      start: prev?.start - 30,
                      end: prev?.end - 30 <= 39 ? 30 : prev?.end - 30,
                    }));
                  }}
                  disabled={pagination?.start === 0}
                  className="w-10 h-full flex items-center justify-center text-base dark:text-black-200
               text-slate-500 hover:text-cyan-700 transition-all disabled:cursor-not-allowed"
                >
                  <TbChevronLeft />
                </button>
                <button
                  onClick={() => {
                    setPagination((prev: any) => ({
                      start: prev?.start + 30,
                      end: prev?.end + 30,
                    }));
                  }}
                  disabled={pagination?.end >= fetched_sales?.length}
                  className="w-10 h-full flex items-center justify-center text-base dark:text-black-200
               text-slate-500 hover:text-cyan-700 transition-all disabled:cursor-not-allowed"
                >
                  <TbChevronRight />
                </button>
              </div>
            </div>
          )}
        </div>

        {/**Show Loading */}
        <div
          className={`fixed -top-4 left-0 right-0 bottom-0 z-[99999999] bg-black-800/60
          ${salesDataLoading ? "flex" : "hidden"} justify-center items-center backdrop-blur-sm`}
        >
          <Ring size={70} lineWeight={5} speed={1.5} color="#ffffff" />
        </div>
      </div>

      {/**Sales Actions Overlay */}
      <SalePreview
        setCurrentSale={setCurrentSale}
        currentSale={currentSale}
      />
      {/**Delete Action Prompt */}
      <ActionPanel
        openPanel={openPanel}
        setActionPanel={setActionPanel}
        deleteSelected={deleteParked}
        option="Praked Sale"
      />

      {/**Filetrs */}
      <Filters
        filtersOpen={filtersOpen}
        setFilters={setFilters}
        currentView={currentView}
        setView={setView}
        setSort={setSort}
        sortBy={sortBy}
      />
    </>
  );
};

export default Sales;
