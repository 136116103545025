import { FC, useEffect } from "react";
import { onSnapshot, query, where } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../Redux/store";
import { parkedSalesRef, salesRef } from "./Firestore_Func";
import {
  addSales,
  parkSales,
  setLoadingStatus,
} from "../Redux/Slices/SalesSlice";

const SalesFirebase: FC = () => {
  const user = useSelector((state: RootState) => state.UserInfo.user);
  const dispatch: AppDispatch = useDispatch();
  const sales_date = useSelector((state: RootState) => state.Sales.sales_date);

  //===================Fetch Data ============================

  // //Fetch Sales Order Data
  useEffect(() => {
    dispatch(setLoadingStatus(true));
    const salesQuery =
      user?.role === "root" || user?.role === "admin"
        ? query(
            salesRef,
            where("date", ">=", new Date(sales_date?.start).getTime()),
            where(
              "date",
              "<=",
              new Date(Number(sales_date?.end) + 86400000).getTime()
            )
          )
        : query(
            salesRef,
            where("user.email", "==", user?.email),
            where("date", ">=", new Date(sales_date?.start).getTime()),
            where(
              "date",
              "<=",
              new Date(Number(sales_date?.end) + 86400000).getTime()
            )
          );

    const unsubscribe = onSnapshot(
      salesQuery,
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        const data = snapshot.docs.map((doc: { data: () => any; id: any }) => ({
          ...doc.data(),
          id: doc.id,
        }));
        dispatch(addSales(data));
        dispatch(setLoadingStatus(false));
      },
      (error) => {
        dispatch(setLoadingStatus(false));
        console.log(error);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [dispatch, sales_date, user]);

  //Fetch Parked Sales
  useEffect((): any => {
    return onSnapshot(
      parkedSalesRef,
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        dispatch(
          parkSales(
            snapshot.docs.map((doc: { data: () => any; id: any }) => ({
              ...doc.data(),
              id: doc.id,
            }))
          )
        );
      }
    );
  }, [dispatch]);

  //Component
  return <></>;
};

export default SalesFirebase;
