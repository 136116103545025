import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { updateCart } from "../../../Redux/Slices/OrderSlice";

type Props = {
  tipObj: any;
  setTip: any;
};

const Tip: FC<Props> = ({ tipObj, setTip }) => {
  const dispatch = useDispatch();
  const selectedCurrency = useSelector(
    (state: RootState) => state.SettingsData.selectedCurrency
  );
  const cart = useSelector((state: RootState) => state.OrderData.cart);

  //Component ===================
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (tipObj?.type === "amount") {
          const value = Number(tipObj?.value) / selectedCurrency?.rate_multiplier;
          dispatch(
            updateCart({
              ...cart,
              tip_type: tipObj?.type,
              tip_amount: value,
              tip_percent: ((value / cart?.total) * 100)?.toFixed(0),
            })
          );
          e.currentTarget.reset();
          setTip({
            type: "amount",
            value: "",
          });
        } else if (tipObj?.type === "percent") {
          const value = (Number(tipObj?.value) / 100) * cart?.total;
          dispatch(
            updateCart({
              ...cart,
              tip_type: tipObj?.type,
              tip_amount: value,
              tip_percent: tipObj?.value,
            })
          );
          e.currentTarget.reset();
          setTip({
            type: "amount",
            value: "",
          });
        }
      }}
      className="flex items-center justify-between"
    >
      <input
        disabled={cart?.tip_amount > 0}
        onChange={(e) => {
          setTip((prev: any) => ({
            ...prev,
            value: Number(e.target.value?.replace(/%/gim, "")),
          }));
        }}
        value={tipObj?.value}
        autoComplete="off"
        type="text"
        name="tip"
        id="tip"
        placeholder="Tip ..."
        className="discount_n_tipInput"
      />
      <select
        disabled={cart?.tip_amount > 0}
        onChange={(e) => {
          setTip((prev: any) => ({
            ...prev,
            type: e.target.value,
          }));
        }}
        className="h-full w-28 border border-l-0 focus:border-l border-slate-300 dark:border-black-600
        bg-slate-50/50 dark:bg-black-700 text-xs text-slate-600 dark:text-black-300 focus:ring-0  focus:border-cyan-800
         transition-all overflow-hidden disabled:cursor-not-allowed font-medium dark:placeholder:text-black-400"
      >
        <option value="amount">Amount</option>
        <option value="percent">Percent</option>
      </select>
      <button
        disabled={cart?.tip_amount > 0}
        type="submit"
        className="w-20 h-full bg-cyan-800 text-[0.65rem] uppercase font-medium text-white rounded-r
    hover:bg-cyan-800 transition-all disabled:cursor-not-allowed"
      >
        apply
      </button>
    </form>
  );
};

export default Tip;
