import { FC } from "react";

type Props = {
  openPanel: boolean;
  setActionPanel: any;
  deleteSelected: any;
  option: string;
};

const ActionPanel: FC<Props> = ({
  openPanel,
  setActionPanel,
  deleteSelected,
  option,
}) => {
  //component ======
  return (
    <div
      className={`fixed left-0 right-0 -top-8 bottom-0 z-[99999] bg-black-900/30 backdrop-blur-sm
       flex justify-center items-end md:items-center drop-shadow-2xl shadow-2xl p-4 overflow-hidden ${
         openPanel ? "" : "hidden"
       }`}
    >
      <div
        id="actionPanel"
        className={`drop-shadow-2xl shadow-2xl bg-slate-50 dark:bg-black-800 rounded-2xl md:rounded-md h-44 w-full md:w-[20rem] 
        flex flex-col justify-between p-4 border border-slate-200 dark:border-slate-700 transition-all
         duration-300 z-[999]`}
      >
        <div className="flex w-full justify-between items-center text-slate-800 dark:text-slate-300 text-sm font-medium">
          <h2>{`Delete this ${option} ?`}</h2>
        </div>

        <p className="text-slate-800 dark:text-slate-300 text-xs">
          {`Are you sure you want to delete this ${option}? This action is
          destructive and can not be undone. All the data related to this ${option}
          will be permanently removed.`}
        </p>
        <div className="flex justify-between items-center space-x-4">
          <button
            type="button"
            onClick={() => {
              setActionPanel(false);
            }}
            className="h-8 rounded text-xs font-normal hover:opacity-80 transition-all duration-200 px-6 bg-cyan-700 text-slate-50 outline-none focus:outline-none"
          >
            Cancel action
          </button>
          <button
            type="button"
            onClick={() => {
              deleteSelected();
              setActionPanel(false);
            }}
            className="h-8 rounded text-xs font-normal hover:opacity-80 transition-all duration-200 px-6 bg-red-600 text-slate-50 outline-none focus:outline-none"
          >
            {`Delete ${option}`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActionPanel;
