import { FC } from "react";
//Firestore ===================
import {
  collection,
  addDoc,
  doc,
  deleteDoc,
  updateDoc,
  getDoc,
  getDocs,
  query,
  where,
  increment,
  setDoc,
  //arrayUnion,
  //deleteField,
} from "firebase/firestore";
import InventoryFirebase from "./InventoryFirebase";
import SalesFirebase from "./SalesFirebase";
import CashFloatFirebase from "./CashFloatFirebase";
import SettingsFirebase from "./SettingsFirebase";
import CustomersFirebase from "./CustomersFirebase";
import { db } from "./Firebase";
import TeamData from "./TeamData";
import { retriveObjectLocal } from "../Redux/Slices/SettingsSlice";

export const org =
  retriveObjectLocal("current_workspace")?.workspace_name || "";

// collection ref
//const usersRef: any = org && collection(db, `companies/${org}/users`);
export const systemRef: any = org && collection(db, `system_data`);
export const logsRef: any = org && collection(db, `system_data/analytics/logs`);
export const channelsRef: any =
  org && collection(db, `system_data/channels/all_channels`);
export const inventoryRef: string | any =
  org && collection(db, `companies/${org}/inventory_data`);
export const categoriesRef: string | any =
  org && collection(db, `companies/${org}/categories`);
export const stockOrderRef: string | any =
  org && collection(db, `companies/${org}/stock_orders`);
export const vendorsRef: string | any =
  org && collection(db, `companies/${org}/vendors`);
export const salesRef: string | any =
  org && collection(db, `companies/${org}/completed_sales`);
export const parkedSalesRef: string | any =
  org && collection(db, `companies/${org}/parked_sales`);
export const evaluationRef: string | any =
  org && collection(db, `companies/${org}/stockEvaluations`);
export const voidedSalesRef: string | any =
  org && collection(db, `companies/${org}/voided_sales`);
export const cash_floatRef: string | any =
  org && collection(db, `companies/${org}/cash_float`);
export const currenciesRef: string | any =
  org && collection(db, `companies/${org}/settings/all_settings/currencies`);
export const fulfillRef: string | any =
  org &&
  collection(
    db,
    `companies/${org}/settings/all_settings/fulfillment_providers`
  );
export const authPermissionsRef: string | any =
  org &&
  collection(db, `companies/${org}/settings/all_settings/auth_permissions`);
export const accessPermissionsRef: string | any =
  org &&
  collection(db, `companies/${org}/settings/all_settings/access_permissions`);
export const paymentsRef: string | any =
  org &&
  collection(db, `companies/${org}/settings/all_settings/payment_methods`);
export const workspaceSettingsRef: string | any =
  org &&
  collection(db, `companies/${org}/settings/all_settings/workspace_settings`);
export const customersRef: string | any =
  org && collection(db, `companies/${org}/customers`);
export const teammembersRef: string | any =
  org && collection(db, `companies/${org}/users`);
export const allusersRef: string | any = org && collection(db, `all_users`);

//System ========================================
export const addLog = async (obj: any) => {
  await addDoc(logsRef, obj);
};
export const addChannel = async (obj: any) => {
  await addDoc(channelsRef, obj);
};
//=================================== Invetory ===========================================
export const addStock = async (obj: any) => {
  return await addDoc(inventoryRef, { ...obj });
};

//Edit Stock
export const updateStock = async (obj: any) => {
  const docRef = doc(db, `companies/${org}/inventory_data`, obj?.id);
  return await updateDoc(docRef, {
    ...obj,
  });
};

// deleting Inventory
export const deleteStock = async (id: string) => {
  const docRef = doc(db, `companies/${org}/inventory_data`, id);
  return await deleteDoc(docRef);
};

// Add Category
export const addCategory = async (obj: any) => {
  return await addDoc(categoriesRef, {
    ...obj,
    isNew: false,
    isDeleted: false,
    edited: false,
  });
};

//Delete Category
export const deleteCategory = async (id: string) => {
  const docRef = doc(db, `companies/${org}/categories`, id);
  return await deleteDoc(docRef);
};

//Adding Stock Order
export const addStockOrder = async (obj: any) => {
  return await addDoc(stockOrderRef, {
    ...obj,
    isNew: false,
    isDeleted: false,
    edited: false,
  });
};

//Updating Stock Order
export const updateStockOrder = async (obj: any) => {
  const docRef = doc(db, `companies/${org}/stock_orders`, obj?.id);
  return await updateDoc(docRef, {
    ...obj,
    isNew: false,
    isDeleted: false,
    edited: false,
  });
};

//Delete Stock Order
export const deleteStockOrder = async (id: string) => {
  const docRef = doc(db, `companies/${org}/stock_orders`, id);
  return await deleteDoc(docRef);
};

//Adding Vendor
export const addVendor = async (obj: any) => {
  return await addDoc(vendorsRef, {
    ...obj,
    isNew: false,
    isDeleted: false,
    edited: false,
  });
};

//Updating Stock Order
export const updateVendor = async (obj: any) => {
  const docRef = doc(db, `companies/${org}/vendors`, obj?.id);
  return await updateDoc(docRef, {
    ...obj,
    isNew: false,
    isDeleted: false,
    edited: false,
  });
};

//Delete Stock Order
export const deleteVendor = async (id: string) => {
  const docRef = doc(db, `companies/${org}/vendors`, id);
  return await deleteDoc(docRef);
};

//Adding Evaluation
export const addEvaluation = async (obj: any) => {
  return await addDoc(evaluationRef, {
    ...obj,
  });
};

//Delete Evaluation
export const deleteEvaluation = async (id: string) => {
  const docRef = doc(db, `companies/${org}/stockEvaluations`, id);
  return await deleteDoc(docRef);
};

//===================================Sales / Transactions ===========================================
export const addSale = async (obj: any) => {
  return await addDoc(salesRef, obj);
};

export const addVoidedSale = async (obj: any) => {
  return await addDoc(voidedSalesRef, {
    ...obj,
  });
};

//Updating Sales
export const updateSale = async (obj: any) => {
  const docRef = doc(db, `companies/${org}/completed_sales`, obj?.id);
  return await updateDoc(docRef, obj);
};

//Delete Sales
export const deleteSale = async (id: string) => {
  const docRef = doc(db, `companies/${org}/completed_sales`, id);
  return await deleteDoc(docRef);
};

//Add Parked Sales
export const parkSale = async (obj: any) => {
  return await addDoc(parkedSalesRef, obj);
};

//Delete Parked Sales
export const deleteParkedSale = async (id: string) => {
  const docRef = doc(db, `companies/${org}/parked_sales`, id);
  return await deleteDoc(docRef);
};

//Add Cash Float
export const addFloat = async (obj: any) => {
  return await addDoc(cash_floatRef, {
    ...obj,
    note: obj?.note ?? "",
    isNew: false,
    isDeleted: false,
    edited: false,
  });
};

//Updating Float
export const updateFloat = async (obj: any) => {
  const docRef = doc(db, `companies/${org}/cash_float`, obj?.id);
  return await updateDoc(docRef, {
    ...obj,
    note: obj?.note ?? "",
  });
};

//Delete Float
export const deleteFloat = async (id: string) => {
  const docRef = doc(db, `companies/${org}/cash_float`, id);
  return await deleteDoc(docRef);
};

//=================================== Settings ===========================================
// export const deleteAndAddDocument = async (documentData: any) => {
//   const documentId = documentData.id;
//   const docRef: any = doc(db, "all_users", documentId);

//   // Delete the existing document
//   await deleteDoc(docRef);

//   // Extract UID from workspace array
//   const uid = documentData.workspaces[0].uid;

//   // Set the new document data
//   await setDoc(doc(allusersRef, uid), {...documentData,id:uid});
// };

//Add Cash Float
export const addCurrency = async (obj: any) => {
  return await addDoc(currenciesRef, {
    ...obj,
  });
};

//Updating Currencies
export const updateCurrency = async (obj: any) => {
  const docRef = doc(
    db,
    `companies/${org}/settings/all_settings/currencies`,
    obj?.id
  );
  return await updateDoc(docRef, {
    ...obj,
  });
};

//Delete Currencies
export const deleteCurrency = async (id: string) => {
  const docRef = doc(
    db,
    `companies/${org}/settings/all_settings/currencies`,
    id
  );
  return await deleteDoc(docRef);
};

//Add Fulfillment Provider
export const addFulfiller = async (obj: any) => {
  return await addDoc(fulfillRef, {
    ...obj,
  });
};

//Updating Fulfillment Provider
export const updateFulfiller = async (obj: any) => {
  const docRef = doc(
    db,
    `companies/${org}/settings/all_settings/fulfillment_providers`,
    obj?.id
  );
  return await updateDoc(docRef, {
    ...obj,
  });
};

//Delete Fulfillment Provider
export const deleteFulfiller = async (id: string) => {
  const docRef = doc(
    db,
    `companies/${org}/settings/all_settings/fulfillment_providers`,
    id
  );
  return await deleteDoc(docRef);
};

//Update Authorization settings
export const updateAuth = async (obj: any, auth_data: any) => {
  if (auth_data?.id) {
    const docRef = doc(
      db,
      `companies/${org}/settings/all_settings/auth_permissions`,
      auth_data?.id
    );
    return await updateDoc(docRef, {
      ...obj,
    });
  } else {
    return await addDoc(authPermissionsRef, {
      ...obj,
      name: "authorization_pin",
    });
  }
};

//Update Authorization Permissions
export const updateAuthPermissions = async (
  obj: any,
  auth_permissions: any
) => {
  if (auth_permissions?.id) {
    const docRef = doc(
      db,
      `companies/${org}/settings/all_settings/auth_permissions`,
      auth_permissions?.id
    );
    return await updateDoc(docRef, {
      ...obj,
    });
  } else {
    return await addDoc(authPermissionsRef, {
      ...obj,
      name: "authorization_permissions",
    });
  }
};

//Update Access Permissions
export const updateAcessPermissions = async (obj: any) => {
  if (obj?.id) {
    const docRef = doc(
      db,
      `companies/${org}/settings/all_settings/access_permissions`,
      obj?.id
    );
    return await updateDoc(docRef, {
      ...obj,
    });
  } else {
    return await addDoc(accessPermissionsRef, {
      ...obj,
    });
  }
};

//Updating workspace
export const updateWorkspaceDetails = async (obj: any) => {
  const docRef = doc(
    db,
    `companies/${org}/settings/all_settings/workspace_settings`,
    obj?.id
  );
  return await updateDoc(docRef, {
    ...obj,
  });
};

//Updating workspace
export const updateReceiptOptions = async (array: any) => {
  const receiptDocRef = doc(
    db,
    `companies/${org}/settings/all_settings/workspace_settings/receipt_settings`
  );

  // Check if the receipt_settings document already exists
  const receiptDocSnapshot = await getDoc(receiptDocRef);

  if (!receiptDocSnapshot.exists()) {
    // If the receipt_settings document does not exist, create a new document with the specified ID
    await setDoc(receiptDocRef, { data: array });
  }

  // Update the workspace_settings document with the new receipt options
  return await updateDoc(receiptDocRef, {
    data: array,
  });
};

//=================================== Payments Settings ===========================================

//Update or Add Payment Method
export const managePaymentMethod = async (obj: any) => {
  const collectionRef: any = query(
    collection(db, `companies/${org}/settings/all_settings/payment_methods`),
    where("name", "==", obj.name.trim().toLowerCase())
  );
  if (obj?.id) {
    const docRef = doc(
      db,
      `companies/${org}/settings/all_settings/payment_methods`,
      obj?.id
    );
    return await updateDoc(docRef, {
      ...obj,
    });
  } else {
    // Check if a document with the same name already exists
    const querySnapshot = await getDocs(collectionRef);
    if (!querySnapshot.empty) {
      throw new Error(
        `A payment method with the name "${obj.name}" already exists.`
      );
    }

    // Create a new document
    return await addDoc(paymentsRef, {
      ...obj,
      name: obj.name.trim().toLowerCase(), // Normalize the name by removing whitespace and converting to lowercase
    });
  }
};

//Delete Method
export const deletePaymentMethod = async (obj: any) => {
  const docRef = doc(
    db,
    `companies/${org}/settings/all_settings/payment_methods`,
    obj?.id
  );
  if (obj?.id_default) {
    return new Error("Failed to delete");
  } else {
    return await deleteDoc(docRef);
  }
};

export const addPaymentTransaction = async (cart: any, method: any) => {
  const docRef = doc(
    db,
    `companies/${org}/settings/all_settings/payment_methods`,
    method?.id
  );
  return await updateDoc(docRef, {
    ...method,
    transactions_count: increment(1),
    balance: increment(cart?.total),
  });
};

//=================================== Customers ===========================================

//Customers
export const addCustomer = async (obj: any) => {
  return await addDoc(customersRef, {
    ...obj,
  });
};

export const addCustomerTransaction = async (obj: any) => {
  return await addDoc(
    collection(db, `companies/${org}/customers/${obj?.uid}/transactions`),
    {
      ...obj,
    }
  );
};

//Updating Customers
export const updateCustomer = async (obj: any) => {
  const docRef = doc(db, `companies/${org}/customers`, obj?.id);
  return await updateDoc(docRef, {
    ...obj,
  });
};

//Delete Customers
export const deleteCustomer = async (id: string) => {
  const docRef = doc(db, `companies/${org}/customers`, id);
  return await deleteDoc(docRef);
};

// export const deleteFields = async (obj: any) => {
//   const docRef = doc(db, `companies/${org}/customers`, obj?.id);
//   return await updateDoc(docRef, {
//     transactions_history: deleteField(),
//     orders: deleteField(),
//     activity_logs: deleteField(),
//     isDeleted: deleteField(),
//     isNew: deleteField(),
//     edited: deleteField(),
//   });
// };

//=================================== User & Users Functions ===========================================

//Updating Customers
export const updatePersonalInfo = async (obj: any) => {
  const docRef = doc(db, `all_users`, obj?.main_id);
  const userRef: any = query(
    collection(db, "all_users"),
    where("email", "==", obj?.email)
  );
  return await getDocs(userRef).then(async (snapshot) => {
    if (snapshot.docs?.length >= 1) {
      const user: any = snapshot.docs.map((doc: any) => ({
        ...doc.data(),
        id: doc.id,
      }))[0];
      return await updateDoc(docRef, {
        ...user,
        workspaces: [
          ...user?.workspaces?.filter(
            (data: any) => data?.workspace_name !== obj?.workspace_name
          ),
          obj,
        ],
      })
        .then(() => {
          // Get the updated document
          return getDoc(docRef);
        })
        .then((doc) => {
          return doc
            ?.data()
            ?.workspaces?.find(
              (data: any) => data?.workspace_name === obj?.workspace_name
            );
        });
    }
  });
};

//Component ==================================
const FirestoreFunc: FC = () => {
  //Component
  return (
    <>
      <InventoryFirebase />
      <SalesFirebase />
      <CashFloatFirebase />
      <SettingsFirebase />
      <CustomersFirebase />
      <TeamData />
    </>
  );
};

export default FirestoreFunc;
