import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./Custom-Hooks/useAuth";
import Loader from "./Components/Misc/Loader";
import { useSelector } from "react-redux";
import { RootState } from "./Redux/store";
import Workspaces from "./Pages/Auth/Workspaces";

const PrivateRoutes = () => {
  const current_workspace = useSelector(
    (state: RootState) => state.UserInfo.current_workspace
  );
  const { loading, currentUser } = useAuth();

  //Component ==============
  return loading ? (
    <Loader />
  ) : currentUser ? (
    <>{current_workspace ? <Outlet /> : <Workspaces />}</>
  ) : (
    <Navigate to="/login" />
  );
};
export default PrivateRoutes;
