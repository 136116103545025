import React from "react";
import { TbChevronRight } from "react-icons/tb";

type Props = {
  setDatesRange: any;
  range: any;
  monthMap: any;
  getDays: any;
  startSelected: any;
  setEnd: any;
  setStartSelect: any;
  startDate: any;
  setStartDate: any;
  end: any;
  overlapEndMonth: any;
};

const RightHallf = ({
  setDatesRange,
  range,
  monthMap,
  getDays,
  startSelected,
  setEnd,
  setStartSelect,
  setStartDate,
  startDate,
  end,
  overlapEndMonth,
}: Props) => {
  return (
    <div className="col-span-1 w-full gap-1 p-2 overflow-hidden hidden md:block">
      {/**Top Nav =================================== */}
      <div className="h-10 w-full flex items-center justify-between pl-1">
        <div
          className="text-xs font-sans font-semibold uppercase flex items-center gap-2 
        justify-end md:justify-start overflow-hidden whitespace-nowrap overflow-ellipsis tracking-wider"
        >
          <select
            onChange={(e) => {
              setDatesRange((prev: any) => ({
                ...prev,
                firstHalf: {
                  ...prev?.firstHalf,
                  month:
                    Number(e.target.value) <= 0
                      ? 0
                      : Number(e.target.value) - 1,
                },
                secondHalf: {
                  ...prev?.secondHalf,
                  month: Number(e.target.value),
                },
              }));
            }}
            name="month_prev"
            id="month_prev"
            className="selectDate"
          >
            <option value={range?.secondHalf?.month}>
              {monthMap?.[range?.secondHalf?.month]}
            </option>
            <option value={0}>jan</option>
            {[0, ...Array(11)]
              .map((_, i) => i + 1)
              ?.map((month: number) => {
                return (
                  <option key={month} value={month}>
                    {monthMap?.[month]}
                  </option>
                );
              })}
          </select>
          <select
            onChange={(e) => {
              setDatesRange((prev: any) => ({
                ...prev,
                firstHalf: {
                  ...prev?.firstHalf,
                  year:prev?.month>=11? Number(e.target.value) - 1:Number(e.target.value),
                },
                secondHalf: {
                  ...prev?.secondHalf,
                  year: Number(e.target.value),
                },
              }));
            }}
            name="year_prev"
            id="year_prev"
            className="selectDate"
          >
            <option value={range?.secondHalf?.year}>
              {range?.secondHalf?.year}
            </option>
            {[
              ...[...Array(20)]
                .map((_, i) => i + 1)
                ?.map((num: number) => range?.secondHalf?.year + num),
              ...[...Array(20)]
                .map((_, i) => i + 1)
                ?.map((num: number) => range?.secondHalf?.year - num),
            ]?.sort((a: number, b: number) => a - b)
              ?.map((year: number) => {
                return <option key={year}>{year}</option>;
              })}
          </select>
        </div>
        <button
          type="button"
          onClick={() => {
            setDatesRange((prev: any) => ({
              ...prev,
              firstHalf: {
                month:
                  prev.firstHalf?.month < 11 ? prev.firstHalf?.month + 1 : 0,
                year:
                  prev.firstHalf?.month < 11
                    ? prev.firstHalf?.year
                    : prev.firstHalf?.year + 1,
              },
              secondHalf: {
                month:
                  prev.secondHalf?.month < 11 ? prev.secondHalf?.month + 1 : 0,
                year:
                  prev.secondHalf?.month < 11
                    ? prev.secondHalf?.year
                    : prev.secondHalf?.year + 1,
              },
            }));
          }}
          className="order-first md:order-last h-8 w-8 flex items-center justify-center 
          bg-black-100 dark:bg-black-600 hover:opacity-80 transition-all rounded bow-shadow-one"
        >
          <TbChevronRight />
        </button>
      </div>
      {/**Heding ===================== */}
      <div className="h-7 w-full grid grid-cols-7">
        {["su", "mo", "tu", "we", "th", "fr", "sa"].map((day) => {
          return (
            <div
              key={day + "k"}
              className="col-span-1 h-full flex items-center justify-center text-xs capitalize font-bold"
            >
              {day}
            </div>
          );
        })}
      </div>
      {/**Day ===================== */}
      <div className="mt-3 h-[10rem] w-full grid grid-cols-7 grid-rows-6 gap-1">
        {/**Overlap ============= */}
        {overlapEndMonth()?.map((day: any) => {
          return (
            <div
              key={day + "f"}
              className="col-span-1 row-span-1 h-full flex items-center justify-center text-xs capitalize font-medium"
            ></div>
          );
        })}
        {/**Days ============= */}
        {getDays(range?.secondHalf?.year, range?.secondHalf?.month)?.map(
          (day: any) => {
            return (
              <button
                type="button"
                onClick={() => {
                  if (!startSelected) {
                    setStartDate(day.dayMilli);
                    setEnd(null);
                    setStartSelect(true);
                  } else if (startSelected && day?.dayMilli >= startDate) {
                    setEnd(day?.dayMilli);
                    setStartSelect(false);
                  } else if (day?.dayMilli < startDate) {
                    setStartDate(day?.dayMilli);
                    setEnd(null);
                    setStartSelect(true);
                  }
                }}
                key={day.dayMilli}
                className={`col-span-1 h-full row-span-1 flex items-center justify-center text-xs capitalize 
              transition-all outline-none focus:outline-none rounded peer font-medium ${
                new Date(day.dayMilli)?.toDateString() ===
                  new Date()?.toDateString() &&
                new Date(day.dayMilli)?.toDateString() !==
                  new Date(startDate)?.toDateString() &&
                new Date(day.dayMilli)?.toDateString() !==
                  new Date(end)?.toDateString()
                  ? "border border-black-600 dark:border-black-700"
                  : new Date(day.dayMilli)?.toDateString() ===
                      new Date(startDate)?.toDateString() ||
                    new Date(day.dayMilli)?.toDateString() ===
                      new Date(end)?.toDateString()
                  ? "bg-cyan-800 dark:bg-black-700 text-black-50"
                  : day.dayMilli >= startDate && day.dayMilli <= end
                  ? "bg-black-200 dark:bg-black-600"
                  : ""
              }`}
              >
                {day?.day}
              </button>
            );
          }
        )}
      </div>
    </div>
  );
};

export default RightHallf;
