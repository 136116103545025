export const toUpper = (str: string) => {
  const firstChar = str?.charAt(0).toUpperCase();
  const otherPart = str?.split("");
  otherPart?.shift();
  return firstChar + otherPart?.join("")?.toLowerCase();
};

//Email Validation function ============
export const isEmail = (email: any) => {
  return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
};

//Funtion to convert string to html
export const ConvertStringToHTML = function (str: string): any {
  const template = document.createElement("template");
  str = str.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = str;
  return template.content.firstChild;
};

//Number Spacing ====
export const numberWithSpaces = (x: any) => {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
};

//Replace Number With Stars
export function replaceWithStars(str: string) {
  const maskedString =
    str?.substring(0, 2) +
    "****" +
    str?.substring(7, 9) +
    "****" +
    str?.substring(13, 16);
  return maskedString;
}

//Clear Site Data
export function clearAllData() {
  // Clear all caches
  caches.keys().then(function (cacheNames) {
    cacheNames.forEach(function (cacheName) {
      caches.delete(cacheName);
    });
  });

  // Clear local storage
  localStorage.clear();
  // Clear all site data
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    registrations.forEach(function (registration) {
      registration.unregister();
    });
  });
  document.cookie = "";
}

//Calculate Display Amount
export const calcDisplayAmount = (rate: number, amount: number): string => {
  return numberWithSpaces((rate * amount)?.toFixed(2)?.toString()?.padStart(2));
};
