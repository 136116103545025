import { createSlice, PayloadAction } from "@reduxjs/toolkit";
//import { crypt } from "../../Utils/Reusable_Func";
import { retriveObjectLocal } from "./SettingsSlice";

interface InitialStateType {
  customers: any;
}

const initialState: InitialStateType = {
  customers: retriveObjectLocal("customers"),
};

export const CustomersSlice = createSlice({
  name: "Sales",
  initialState,
  reducers: {
    updateCustomers: (state, action: PayloadAction<any>) => {
      state.customers = action.payload;
      // window.localStorage.setItem(
      //   "customers",
      //   JSON.stringify(
      //       crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
      //     )
      // );
    },
  },
});

export const { updateCustomers } = CustomersSlice.actions;

export default CustomersSlice.reducer;
