import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import {
  calcDisplayAmount,
  numberWithSpaces,
} from "../../../Reusable Functions/Functions";
import CustomSale from "./CustomSale";
import TopSearch from "./TopSearch";
import ItemsList from "./ItemsList";
import { updateCart } from "../../../Redux/Slices/OrderSlice";
import { useLocation } from "react-router-dom";
import Authorize from "../../../Components/Authorize/Authorize";
import {
  fulfillmentProviders,
  openDrawer,
} from "../../../Redux/Slices/SettingsSlice";
import { motion, useMotionValue, useTransform } from "framer-motion";

type Props = {
  openCheckout: any;
  smallScreenCart: boolean;
  setSmCart: any;
};

const Cart: FC<Props> = ({ openCheckout, smallScreenCart, setSmCart }) => {
  const dispatch = useDispatch();
  const fulfillment_providers = useSelector(
    (state: RootState) => state.SettingsData.fulfillment_providers
  );
  const cart = useSelector((state: RootState) => state.OrderData?.cart);
  const selectedCurrency = useSelector(
    (state: RootState) => state.SettingsData.selectedCurrency
  );
  const total: any = useMemo((): any => {
    return cart?.products?.length >= 1
      ? cart?.products
          ?.map(
            (prod: any) =>
              (prod?.prod_obj?.has_discounted_price
                ? prod?.prod_obj?.discount_price
                : prod?.prod_obj?.price_in_usd) * prod?.quantity
          )
          ?.reduce((acc: any, value: any) => Number(acc) + Number(value), 0)
      : 0.0;
  }, [cart]);
  const profit = useMemo((): any => {
    return cart?.products?.length >= 1
      ? total -
          cart?.products
            ?.map(
              (prod: any) =>
                prod?.prod_obj?.buying_price_in_usd * prod?.quantity
            )
            ?.reduce((acc: any, value: any) => Number(acc) + Number(value), 0)
      : 0.0;
  }, [cart, total]);
  const [searchValue, searchCart] = useState<string>("");
  const tax = 0;
  const [type, setType] = useState<string>("float");
  const [isCustomOpen, setCustom] = useState<boolean>(false);
  const location = useLocation();
  const [showAuthorize, setAuthorize] = useState<boolean>(false);
  const auth_permissions = useSelector(
    (state: RootState) => state.SettingsData.auth_permissions
  );
  const scrollY = useMotionValue(0);
  const opacity = useTransform(scrollY, [0, 100], [1, 0]);
  const translateY = useTransform(scrollY, [0, 100], [0, 100]);

  const handleDrag = (event: any, info: any) => {
    const { offset } = info;

    if (Math.abs(offset.y) > Math.abs(offset.x)) {
      // vertical drag
      scrollY.set(-offset.y);
    } else {
      // horizontal drag
      event.preventDefault();
    }
  };

  const handleDragEnd = (
    _: any,
    { offset, velocity }: { offset: any; velocity: any }
  ) => {
    if (Math.abs(offset.y) > Math.abs(offset.x)) {
      // vertical drag
      if (offset.y > 50 || velocity.y > 500) {
        setSmCart(false);
      }
      scrollY.set(0);
    }
  };

  //Open Chechout Function
  const openCheckOut = () => {
    const fulfiller = fulfillment_providers?.find(
      (data: fulfillmentProviders) =>
        data?.status === "active" && data?.channels?.includes("pos")
    );
    //Generate Unique ID
    const uniqueID = () => {
      const name = "trans".replace(/[^a-zA-Z]|\s/gi, "");
      const combined = `${
        name?.split("")?.slice(0, 4)?.join("")?.toUpperCase() +
        new Date().getFullYear().toString().slice(2, 4) +
        new Date().toISOString().slice(5, 7) +
        new Date().toISOString().slice(8, 10) +
        "-" +
        new Date().getMilliseconds()?.toString()?.charAt(0) +
        new Date().toISOString().slice(11, 13) +
        new Date().toISOString().slice(14, 16) +
        new Date().toISOString().slice(17, 19)
      }`;
      return combined?.replace(/\s/g, "");
    };
    dispatch(
      updateCart({
        ...cart,
        currency_name: selectedCurrency?.name,
        currency_id: selectedCurrency?.id,
        profit: profit,
        tax_percentage: 0,
        tax_in_usd: 0,
        transact_id: uniqueID(),
        origin: "pos",
        total: total + (Number(fulfiller?.fee_in_usd) || 0),
        fulfillment_type: fulfiller?.type,
        fulfillment_provider_id: fulfiller?.id,
        service_fee: Number(fulfiller?.fee_in_usd) || 0,
        fulfillment_status: "pending",
      })
    );
    openCheckout(true);
  };

  //Auth Action
  //Open Float with Auth Funct
  const authAction = (value: any) => {
    if (value) {
      setCustom(true);
      setAuthorize(false);
    }
  };

  //Open Float with Auth Funct
  const authFloat = (value: any) => {
    if (value) {
      dispatch(openDrawer(true));
      setAuthorize(false);
      setType("custom sale");
    } else {
      dispatch(openDrawer(false));
    }
  };

  //open Float function
  const opeFloat = () => {
    if (
      auth_permissions?.data?.find(
        (permission: any) => permission?.name === "cash register"
      )?.active
    ) {
      setType("float");
      setAuthorize(true);
    } else {
      dispatch(openDrawer(true));
    }
  };

  //Shorcut Event Listener =======
  useEffect(() => {
    const listenForShortcut = (e: any) => {
      if (location.pathname === "/app") {
        if (
          e.key?.toString() === "F4" &&
          !e.shiftKey &&
          cart?.products?.length >= 1
        ) {
          e.preventDefault();
          openCheckOut();
        }
      }
    };
    window.addEventListener("keydown", listenForShortcut, true);
    return () => window.removeEventListener("keydown", listenForShortcut, true);
  });

  //Component
  return (
    <>
      <motion.div
        style={{ opacity, y: translateY }}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        className={`min-h-full lg:h-full w-full lg:w-[27rem] bg-black-800/40 lg:bg-transparent
        backdrop-blur-sm p-0 sm:p-6 lg:px-0 lg:py-4 flex items-center justify-center ${
         smallScreenCart
           ? "z-[99999] -top-0 bottom-0 left-0 right-0"
           : "-left-[200%]"
       } fixed lg:left-auto lg:right-0 lg:top-auto lg:relative transition-all duration-300`}
      >
        <div
          className="h-full w-full max-w-[40rem] sm:rounded-lg bg-white dark:bg-black-900
            flex flex-col justify-between items-center  overflow-hidden relative p-4 border border-slate-300
            dark:border-black-600 gap-4"
        >
          {/**Search =================== */}
          <TopSearch
            searchCart={searchCart}
            searchValue={searchValue}
            setSmCart={setSmCart}
          />
          {/**Search =================== */}

          {/**Cart List ================ */}
          <ItemsList searchValue={searchValue} opeFloat={opeFloat} />
          {/**Cart List ================ */}

          {/**Totals and Controls */}
          <div className="w-full h-[11rem] flex flex-col justify-between gap-4 shrink-0">
            <ul
              className="w-full h-[calc(100%-2.75rem)] grid grid-rows-5 p-2 border-y border-slate-300
              lg:border-slate-200 dark:border-black-500 text-xs font-semibold text-slate-800 dark:text-black-200"
            >
              <li className="row-span-1 w-full flex items-center justify-between">
                <span>Tip</span>
                <span>{selectedCurrency?.symbol}&nbsp;0.00</span>
              </li>
              <li className="row-span-1 w-full flex items-center justify-between">
                <span>Discount</span>
                <span>{selectedCurrency?.symbol}&nbsp;0.00</span>
              </li>
              <li className="row-span-1 w-full flex items-center justify-between">
                <span>Tax (%)</span>
                <span>
                  {selectedCurrency?.symbol}&nbsp;
                  {cart?.products?.length >= 1
                    ? numberWithSpaces(
                        (selectedCurrency?.rate_multiplier * tax).toFixed(2)
                      )
                    : "0.00"}
                </span>
              </li>
              <li className="row-span-1 w-full flex items-center justify-between">
                <span>Subtotal</span>
                <span>
                  {selectedCurrency?.symbol}&nbsp;
                  {cart?.products?.length >= 1
                    ? numberWithSpaces(
                        (
                          selectedCurrency?.rate_multiplier * total -
                          selectedCurrency?.rate_multiplier * tax
                        ).toFixed(2)
                      )
                    : "0.00"}
                </span>
              </li>
              <li className="row-span-1 w-full flex items-center justify-between">
                <span>Total</span>
                <span>
                  {selectedCurrency?.symbol}&nbsp;
                  {cart?.products?.length >= 1
                    ? calcDisplayAmount(
                        selectedCurrency?.rate_multiplier,
                        total
                      )
                    : "0.00"}
                </span>
              </li>
            </ul>

            <div
              className="w-full h-12 min-h-[3rem] grid grid-cols-2 rounded-md overflow-hidden
                 divide-x divide-slate-300 dark:divide-black-400"
            >
              <button
                onClick={() => {
                  if (
                    auth_permissions?.data?.find(
                      (permission: any) => permission?.name === "custom sale"
                    )?.active
                  ) {
                    setType("custom sale");
                    setAuthorize(true);
                  } else {
                    setCustom(true);
                  }
                }}
                className="h-full col-span-1 bg-cyan-800 text-white text-xs uppercase font-bold
                  disabled:cursor-not-allowed transition-all hover:bg-cyan-700"
              >
                custom sale
              </button>
              <button
                onClick={() => openCheckOut()}
                disabled={cart?.products?.length <= 0 || !cart?.products}
                className="h-full col-span-1 bg-cyan-800 text-white text-xs uppercase font-bold
                   disabled:cursor-not-allowed transition-all hover:bg-cyan-700"
              >
                check-out
              </button>
            </div>
          </div>

          {/**Custom Sale */}
          <CustomSale isCustomOpen={isCustomOpen} setCustom={setCustom} />
        </div>
      </motion.div>
      {/**Auth */}
      <Authorize
        showAuthorize={showAuthorize}
        setAuthorize={setAuthorize}
        passedAuthFunc={type === "float" ? authFloat : authAction}
        showReason={false}
      />
    </>
  );
};

export default Cart;
