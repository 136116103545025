/* eslint-disable no-unsafe-optional-chaining */
import { FC, useEffect, useState, useMemo, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import SearchBar from "./SearchBar";
import ItemsList from "./ItemsList";
import useClickOutside from "../../../Custom-Hooks/useOnClickOutsideRef";
import { customizeProduct, updateCart } from "../../../Redux/Slices/OrderSlice";
import { AnimatePresence, motion } from "framer-motion";

type Props = {
  isSeachOpen: boolean;
  openSearch: any;
};

const Search: FC<Props> = ({ isSeachOpen, openSearch }) => {
  const dispatch = useDispatch();
  const selected_product = useSelector(
    (state: RootState) => state.OrderData.selected_product
  );
  const cart = useSelector((state: RootState) => state.OrderData.cart);
  const [quantity, setQuantity] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [focusIndex, setFocusIndex] = useState<number>(-1);
  const inventory_data = useSelector(
    (state: RootState) => state.Inventory.inventory_data
  );
  const results = useMemo(() => {
    const data = [...inventory_data]
      .filter(
        (item: any) =>
          Object.values(item).some((val: any) =>
            val
              ?.toString()
              ?.toLowerCase()
              ?.replace(/\s/gim, "")
              ?.includes(
                search?.toString()?.toLowerCase()?.replace(/\s/gim, "")
              )
          ) && item?.platforms_visibility?.includes("pos")
      )
      ?.slice(0, 20)
      ?.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
    return data;
  }, [inventory_data, search]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const modalRef = useClickOutside(() => {
    openSearch(false);
    setSearch("");
    setQuantity(1);
    setFocusIndex(-1);
  });

  //Close Fucntion ===================
  const closeFunc = useCallback(() => {
    dispatch(
      customizeProduct({
        customization: [],
        prod_cart_uid: "",
        prod_obj: {},
        quantity: 1,
      })
    );
    setQuantity(1);
    setSearch("");
    setFocusIndex(-1);
    openSearch(false);
  }, [dispatch, openSearch]);

  //Add Product To Cart =============================
  const addToCart = useCallback(
    (prod: any) => {
      //Check if selected product is on the card
      const prodIsOnCart =
        cart?.products?.find((data: any) => data?.prod_cart_uid === prod?.id) ||
        {};

      //Update State
      dispatch(
        updateCart({
          ...cart,
          products: [
            ...(cart?.products
              ? cart?.products?.filter(
                  (data: any) => data?.prod_cart_uid !== prod?.id
                )
              : []),
            {
              ...(selected_product || prod),
              ...prodIsOnCart,
              prod_cart_uid: prod?.id,
              prod_obj: prod,
              quantity: (prodIsOnCart?.quantity || 0) + (quantity || 1),
            },
          ],
        })
      );
    },
    [cart, dispatch, quantity, selected_product]
  );

  //Shorcut Event Listener =======
  useEffect(() => {
    const searchShortcuts = (e: any) => {
      if (e.key === "Escape" && !e.shiftKey && isSeachOpen) {
        e.preventDefault();
        openSearch(false);
        setQuantity(1);
        setFocusIndex(-1);
      }
    };
    window.addEventListener("keydown", searchShortcuts, true);
    return () => window.removeEventListener("keydown", searchShortcuts, true);
  });

  //Navigate Through A List
  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (isSeachOpen) {
        const selectedProd = results[focusIndex];
        if (event.key === "ArrowUp") {
          setFocusIndex(Math.max(focusIndex - 1, 0));
          setQuantity(1);
        } else if (event.key === "ArrowDown") {
          setFocusIndex(Math.min(focusIndex + 1, results.length - 1));
          setQuantity(1);
        } else if (event.key === "ArrowLeft") {
          setQuantity((prev: number) => (Number(prev) > 0 ? prev - 1 : prev));
        } else if (event.key === "ArrowRight") {
          setQuantity((prev: number) =>
            Number(selectedProd?.in_stock) > Number(prev) ||
            !selectedProd?.has_stock_count
              ? prev + 1
              : prev
          );
        } else if (
          event.key === "Enter" &&
          !event.shiftKey &&
          focusIndex >= 0 &&
          ((Number(selectedProd?.in_stock) > 0 &&
            selectedProd?.has_stock_count) ||
            !selectedProd?.has_stock_count)
        ) {
          addToCart(selectedProd);
          closeFunc();
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    focusIndex,
    results.length,
    openSearch,
    quantity,
    results,
    setQuantity,
    isSeachOpen,
    addToCart,
    closeFunc,
  ]);

  //Component
  return (
    <AnimatePresence>
      {isSeachOpen && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          exit={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className={`fixed left-0 right-0 top-0 bottom-0 z-[9999999] flex bg-slate-600/40 
          justify-center p-4 pt-8 md:pt-28 backdrop-blur-sm`}
        >
          <div
            ref={modalRef}
            className={`w-full md:w-[35rem] h-[30rem] bg-white dark:bg-black-700 border border-slate-200
             dark:border-black-500 ${
               isSeachOpen ? "scale-100" : "scale-0"
             } transition-all duration-300 delay-300 rounded-xl flex flex-col p-4 pt-2 overflow-hidden`}
          >
            <SearchBar
              inputRef={inputRef}
              setSearch={setSearch}
              search={search}
            />

            {/**Search Results List =============== */}
            <div className="w-full h-full flex pb-4 overflow-hidden">
              <ItemsList
                focusIndex={focusIndex}
                addToCart={addToCart}
                quantity={quantity}
                setQuantity={setQuantity}
                results={results}
                openSearch={openSearch}
              />
            </div>

            {/**Shortcuts =========== ======= */}
            <div className="w-full h-12 shrink-0 pt-2 bg-inherit hidden md:flex items-center justify-between">
              <p className="text-xss text-slate-500 dark:text-slate-300 font-medium line-clamp-2">
                Long press to customize or use arrow
                <div
                  className="w-fit h-fit mx-1 px-1 border border-slate-200 dark:border-slate-700
                 bg-slate-50 dark:bg-stone-700 inline-block items-center justify-center shrink-0 rounded"
                >
                  <kbd className="text-xxs font-bold">&#x25B2; &#x25BC;</kbd>
                </div>
                keys to navigate through the list and{" "}
                <div
                  className="w-fit h-fit mx-1 px-1 border border-slate-200 dark:border-slate-700
                 bg-slate-50 dark:bg-stone-700 inline-block items-center justify-center shrink-0 rounded"
                >
                  <kbd className="text-xxs font-bold">Enter</kbd>
                </div>
                to add to cart
              </p>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Search;
