/* eslint-disable no-unsafe-optional-chaining */
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { numberWithSpaces } from "../../Reusable Functions/Functions";
import { updateCart } from "../../Redux/Slices/OrderSlice";
import { deleteParkedSale } from "../../Firebase/Firestore_Func";
import { motion } from "framer-motion";
import { notifications } from "@mantine/notifications";

type Props = {
  sales: any;
  currentView: string;
  markedArray: any;
  markItem: any;
  setCurrentSale: any;
  currentSale: any;
};

const Sale: FC<Props> = ({
  sales,
  currentView,
  markedArray,
  markItem,
  setCurrentSale,
  currentSale,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedCurrency = useSelector(
    (state: RootState) => state.SettingsData.selectedCurrency
  );

  const saleClickFunc = (sale: any) => {
    if (currentView === "parked sales") {
      dispatch(updateCart(sale));
      deleteParkedSale(sale?.id);
      navigate("/app");
    } else {
      setCurrentSale(sale);
    }
  };

  //component
  return (
    <>
      {sales?.length >= 1 &&
        sales?.map((sale: any, index: number) => {
          return (
            <motion.tr
              initial={{opacity: 0 }}
              exit={{opacity: 0 }}
              animate={{opacity: 1 }}
              transition={{ durtion: 0.2 }}
              key={sale?.transact_id + index}
              className={`h-16 min-h-[4rem] w-full overflow-hidden border-b border-slate-200 md:border-slate-100 grid
               grid-cols-5 lg:grid-cols-12 gap-1 cursor-pointer bg-tranparent ${
                 currentSale?.transact_id === sale?.transact_id && "bg-slate-50"
               } dark:border-black-600 transition-all hover:opacity-80 relative`}
            >
              <td
                className="h-full col-span-1 overflow-hidden hidden lg:flex items-center
               justify-center text-ellipsis whitespace-nowrap"
              >
                <input
                  type="checkbox"
                  name="select_all"
                  id="select_all"
                  className="rounded-full h-3 w-3 border-slate-400 dark:border-black-600 bg-light-bg dark:bg-black-500"
                  checked={
                    markedArray?.some(
                      (data: any) => data?.transact_id === sale?.transact_id
                    )
                      ? true
                      : false
                  }
                  onChange={(e: any) => {
                    if (e.target.checked === true) {
                      if (
                        markedArray?.some(
                          (data: any) => data?.transact_id === sale?.transact_id
                        )
                      ) {
                        notifications.show({
                          message: "Item is already selected",
                          color: "green",
                        });
                      } else {
                        markItem((prev: any) => [...prev, sale]);
                      }
                    } else {
                      markItem((prev: any) => [
                        ...prev?.filter(
                          (data: any) => data?.transact_id !== sale?.transact_id
                        ),
                      ]);
                    }
                  }}
                />
              </td>
              <td
                onClick={() => {
                  saleClickFunc(sale);
                }}
                className="h-full col-span-3 lg:col-span-2 overflow-hidden flex 
                items-center gap-3 md:pl-4 lg:pl-0"
              >
                <div
                  className="h-8 w-8 shrink-0 rounded-md bg-slate-100 dark:bg-black-700 border
                   border-slate-300 dark:border-black-600 flex items-center justify-center text-slate-700 text-xs 
                  dark:text-black-200 uppercase font-bold p-1 overflow-hidden"
                >
                  {sale?.customers_details?.name
                    ? sale?.customers_details?.name?.charAt(0)
                    : "U"}
                </div>
                <span
                  className="text-slate-600 dark:text-black-100 font-semibold text-xss capitalize w-full whitespace-nowrap
                   overflow-hidden text-ellipsis"
                >
                  {sale?.customers_details?.name
                    ? sale?.customers_details?.name
                    : "Unkown Customer"}
                </span>
              </td>
              <td
                onClick={() => {
                  saleClickFunc(sale);
                }}
                className="h-full col-span-3 overflow-hidden hidden lg:flex flex-col justify-center space-y-0 
                text-xss text-slate-700 dark:text-black-300 font-medium text-ellipsis whitespace-nowrap uppercase"
              >
                <span
                  className="font-medium text-xss capitalize w-full whitespace-nowrap overflow-hidden
                   text-ellipsis"
                >
                  {new Date(sale?.date)?.toString()?.split("GMT")[0]}
                </span>
              </td>
              <td
                onClick={() => {
                  saleClickFunc(sale);
                }}
                className="h-full col-span-2 overflow-hidden hidden lg:flex flex-col justify-center space-y-0 
                text-xss text-slate-700 dark:text-black-300 font-medium text-ellipsis whitespace-nowrap uppercase"
              >
                <span
                  className="font-medium text-xss capitalize w-full whitespace-nowrap overflow-hidden
                  text-ellipsis"
                >
                  {sale?.payment_method
                    ? sale?.payment_method + " payment"
                    : "no payment"}
                </span>
              </td>
              <td
                onClick={() => {
                  saleClickFunc(sale);
                }}
                className={`h-full col-span-1 overflow-hidden hidden lg:flex items-center gap-1
               text-ellipsis whitespace-nowrap uppercase text-xxs font-medium text-slate-700 dark:text-black-300`}
              >
                <div
                  className={`h-1.5 w-1.5 rounded-sm ${
                    sale?.status === "paid"
                      ? "bg-green-500"
                      : sale?.status === "exchange"
                      ? "bg-blue-600"
                      : sale?.status === "refund"
                      ? "bg-red-600"
                      : sale?.status === "pending"
                      ? "bg-yellow-600"
                      : ""
                  }`}
                ></div>
                <span>{sale?.status}</span>
              </td>
              <td
                onClick={() => {
                  saleClickFunc(sale);
                }}
                className="h-full col-span-2 overflow-hidden hidden lg:flex items-center gap-1 pr-2
                text-xxs text-slate-700 dark:text-black-300 font-medium text-ellipsis whitespace-nowrap uppercase"
              >
                <div
                  className={`h-1.5 w-1.5 rounded-sm ${
                    sale?.fulfillment_status === "pending"
                      ? "bg-yellow-500"
                      : sale?.fulfillment_status === "completed"
                      ? "bg-green-500"
                      : sale?.fulfillment_status === "failed"
                      ? "bg-red-600"
                      : ""
                  }`}
                ></div>
                <span>{sale?.fulfillment_status || "completed"}</span>
              </td>
              <td
                onClick={() => {
                  saleClickFunc(sale);
                }}
                className="h-full col-span-2 md:col-span-1 overflow-hidden flex items-center  justify-end
                text-xss text-slate-700 dark:text-black-300 font-medium text-ellipsis whitespace-nowrap uppercase md:px-4"
              >
                {selectedCurrency?.symbol}&nbsp;
                {numberWithSpaces(
                  (
                    selectedCurrency?.rate_multiplier * Number(sale?.total)
                  ).toFixed(2)
                )}
              </td>
            </motion.tr>
          );
        })}
    </>
  );
};

export default Sale;
