/* eslint-disable no-unsafe-optional-chaining */
import { FC, useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { useReactToPrint } from "react-to-print";
import CheckoutInfo from "./CheckoutInfo";
import Confirmation from "./Confirmation";
import {
  addCustomerTransaction,
  addPaymentTransaction,
  addSale,
  updateCustomer,
  updateFloat,
  updateStock,
} from "../../../Firebase/Firestore_Func";
import { notifications } from "@mantine/notifications";
import { capitalize } from "moderndash";
import { numberWithSpaces } from "../../../Utils/Reusable_Func";
import Reciept from "../../../Components/Sales/Reciept";

type Props = {
  isCheckout: any;
  openCheckout: any;
  setSmCart: any;
};

const CheckOut: FC<Props> = ({ isCheckout, openCheckout, setSmCart }) => {
  const cart = useSelector((state: RootState) => state.OrderData.cart);
  const inventory_data = useSelector(
    (state: RootState) => state.Inventory.inventory_data
  );
  const selectedCurrency = useSelector(
    (state: RootState) => state.SettingsData.selectedCurrency
  );
  const paymnet_methods = useSelector(
    (state: RootState) => state.SettingsData.payment_methods
  );
  const cash_float = useSelector((state: RootState) => state.Sales.cash_float);
  const user = useSelector((state: RootState) => state.UserInfo.user);
  const [additional_details, setDetails] = useState<any>({
    customers_details: {
      name: cart?.customers_details?.name,
      email: cart?.customers_details?.email,
      "primary phone": cart?.customers_details?.["primary phone"],
      address: cart?.customers_details?.address,
    },
    note: cart?.note ?? "",
  });
  const [invoiceOptions, setInvoiceOptions] = useState<any[]>(
    ((): any[] => {
      const data = window.localStorage.getItem("invoiceOpt");
      return data ? JSON.parse(data) : ["print", "email"];
    })()
  );
  const [change, calcChange] = useState<number | null>(null);
  const [transactProcess, startTransact] = useState<boolean>(false);
  const [showProcessDone, setProcessDone] = useState<boolean>(false);
  const [tipObj, setTip] = useState<any>({
    type: "amount",
    value: "",
  });
  const [currentSlide, setSlide] = useState<string>("info");
  const [selectedMethod, selectMethod] = useState<any>(null);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  //Fetch Default Payment Method
  useEffect(() => {
    if (!selectedMethod || !selectedMethod?.id) {
      const method = paymnet_methods
        ?.filter(
          (method: any) =>
            method?.status === "active" &&
            (method?.currencies?.includes(selectedCurrency?.name) ||
              method?.currencies?.length >= 1)
        )
        ?.sort((a: any, b: any) =>
          a?.name === "cash" ? -1 : b?.name === "cash" ? 1 : 1
        )[0];
      method && selectMethod(method);
    }
  }, [selectMethod, paymnet_methods, selectedCurrency, selectedMethod]);

  //Add Initial Change
  useEffect(() => {
    if (!change) {
      calcChange(cart?.change || 0);
    }
  }, [cart?.change, change]);

  //Notify Option ==========
  const optionSelected = async () => {
    //Print If Print Selected
    if (invoiceOptions?.includes("print")) {
      handlePrint();
    }

    //Send Email If Selected
    if (invoiceOptions?.includes("email") && cart?.customers_details?.email) {
      try {
        const response = await fetch(import.meta.env.VITE_SALE_EMAIL_CONFI, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...cart,
            products: cart?.products.map((prod: any) => ({
              ...prod,
              cost: `${selectedCurrency?.symbol} 
              ${numberWithSpaces(
                (
                  selectedCurrency?.rate_multiplier *
                  Number(prod?.prod_obj?.price_in_usd) *
                  Number(prod?.quantity)
                ).toFixed(2)
              )}`,
            })),
            total: `${selectedCurrency?.symbol} 
            ${numberWithSpaces(
              (selectedCurrency?.rate_multiplier * cart?.total).toFixed(2)
            )}`,
            tax: `${selectedCurrency?.symbol} 
            ${numberWithSpaces(
              (selectedCurrency?.rate_multiplier * cart?.tax_in_usd).toFixed(2)
            )}`,
            company_name: capitalize(user?.company_name),
          }),
        });

        if (response.ok) {
          const data = await response.json();
          notifications.show({
            title: "Email confirmation sent",
            message: data?.message,
            color: "green",
          });
        }
      } catch (error: any) {
        notifications.show({
          title: "Falied send email",
          message: error?.message,
          color: "red",
        });
        console.error("Function call failed:", error);
      }
    }

    //Send Sms
    if (invoiceOptions?.includes("smss")) {
      fetch("https://2waychat.com/2wc/single-sms/v1/api", {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: import.meta.env.VITE_SMS_TOKEN,
          destination: "263717070834",
          messageText: `Hi ${
            cart?.customers_details?.name || ""
          }, thank you for your purchase!
               Your purchase has been proceesed successfully. If you have any questions, please contact us at 
               . Thank you for choosing Zonke Tech`,
          messageReference: cart?.transact_id || "io09090",
          messageDate: new Date()
            .toISOString()
            .replace(/[^0-9]/g, "")
            .slice(0, 14),
          messageValidity: "03:00",
        }),
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  //Clear After Sale
  const clearFunc = () => {
    selectMethod(null);
    calcChange(0);
    setDetails({
      customers_details: {
        name: "",
        email: "",
        address: "",
        "primary phone": "",
      },
      note: "",
    });
    startTransact(false);
    setProcessDone(true);
  };

  //Transact Func
  const createTransaction = () => {
    //Start process and show loader
    startTransact(true);
    setSmCart(false);

    if (selectedMethod?.name === "cash") {
      setTimeout(() => {
        //Add Sale
        addSale({
          ...cart,
          status: "paid",
          fulfillment_status: "completed",
          user: { name: user?.name, email: user?.email, id: user?.uid || "" },
          date: new Date().getTime(),
        });

        //Add Sale Under Payment Method
        addPaymentTransaction(
          cart,
          paymnet_methods?.find(
            (method: any) => method?.name === selectedMethod?.name
          )
        );

        //Deduct Stock From Inventory
        cart?.products?.forEach((prod: any) => {
          if (prod?.prod_obj?.has_stock_count) {
            updateStock({
              ...prod?.prod_obj,
              in_stock:
                Number(
                  [...inventory_data]?.filter(
                    (data: any) => data?.id_two === prod?.prod_obj?.id_two
                  )[0]?.in_stock
                ) - Number(prod?.quantity) ?? 0,
              last_editedAt: new Date().getTime(),
            });
          }
        });

        //Update Cash Float
        const openFloat = (() => {
          return cash_float?.length >= 1
            ? cash_float?.find((data: any) => data.status === "open")
            : null;
        })();
        if (openFloat) {
          updateFloat({
            id: openFloat?.id,
            total: cart?.total + Number(openFloat?.total),
            sales: Number(openFloat?.sales) + Number(cart?.total),
            activities: [
              ...openFloat?.activities,
              {
                note: "Cash payment",
                amount: cart?.total,
                time: new Date()?.getTime(),
                currency: selectedCurrency?.name,
              },
            ],
          });
        }

        //Notify Options
        optionSelected();

        //Clear Cart
        clearFunc();
      }, 1000);
    } else if (selectedMethod?.name === "account") {
      const transaction = {
        currency_id: cart?.currency_id,
        currency_name: cart?.currency_name,
        origin: cart?.origin,
        transact_id: cart?.transact_id,
        total: cart.total,
        uid: cart?.customers_details?.id,
        type: "money-out",
        date: new Date().getTime(),
        payment_method: selectedMethod?.name,
      };

      setTimeout(() => {
        //Add Sale
        addSale({
          ...cart,
          fulfillment_status: "completed",
          user: { name: user?.name, email: user?.email, id: user?.uid || "" },
          status: "paid",
          date: new Date().getTime(),
        });

        //Add Sale Under Payment Method
        addPaymentTransaction(
          cart,
          paymnet_methods?.find(
            (method: any) => method?.name === selectedMethod?.name
          )
        );

        //Add Transaction On User Collection
        addCustomerTransaction(transaction);

        //Notify
        optionSelected();

        //Deduct Stock From Inventory
        cart?.products?.forEach((prod: any) => {
          if (prod?.prod_obj?.has_stock_count) {
            updateStock({
              ...prod?.prod_obj,
              in_stock:
                Number(
                  [...inventory_data]?.filter(
                    (data: any) => data?.id_two === prod?.prod_obj?.id_two
                  )[0]?.in_stock
                ) - Number(prod?.quantity) ?? 0,
              last_editedAt: new Date().getTime(),
            });
          }
        });

        //Deduct Funds From Account
        const customer = additional_details?.customers_details;
        updateCustomer({
          ...customer,
          account: {
            ...customer?.account,
            balance:
              customer?.account?.balance - cart?.total >= 0
                ? customer?.account?.balance - cart?.total
                : 0,
            outstanding:
              customer?.account?.balance - cart?.total > 0
                ? Number(
                    customer?.account?.outstanding?.toString()?.replace("-", "")
                  )
                : Number(
                    customer?.account?.outstanding?.toString()?.replace("-", "")
                  ) +
                  Number(
                    (customer?.account?.balance - cart?.total)
                      ?.toString()
                      ?.replace("-", "")
                  ),
          },
        });

        //Clear Cart
        clearFunc();
      }, 1000);
    } else {
      setTimeout(() => {
        //Add Sale
        addSale({
          ...cart,
          user: { name: user?.name, email: user?.email, id: user?.uid || "" },
          fulfillment_status: "completed",
          status: "paid",
          date: new Date().getTime(),
        });

        //Add Sale Under Payment Method
        addPaymentTransaction(
          cart,
          paymnet_methods?.find(
            (method: any) => method?.name === selectedMethod?.name
          )
        );

        //Notify
        optionSelected();

        //Deduct Stock From Inventory
        cart?.products?.forEach((prod: any) => {
          if (prod?.prod_obj?.has_stock_count) {
            updateStock({
              ...prod?.prod_obj,
              in_stock:
                Number(
                  [...inventory_data]?.filter(
                    (data: any) => data?.id_two === prod?.prod_obj?.id_two
                  )[0]?.in_stock
                ) - Number(prod?.quantity) ?? 0,
              last_editedAt: new Date().getTime(),
            });
          }
        });

        //Clear Cart
        clearFunc();
      }, 1000);
    }
  };

  //Shorcut Event Listener =======
  useEffect(() => {
    const cancelCheckOutShortcut = (e: any) => {
      if (e.key?.toString() === "Escape") {
        openCheckout(false);
      }
    };
    window.addEventListener("keydown", cancelCheckOutShortcut, true);
    return () =>
      window.removeEventListener("keydown", cancelCheckOutShortcut, true);
  });

  //Component ========
  return (
      <div
        className={`fixed top-0 bottom-0 transition-all duration-300 min-w-screen z-[99999] ${
          isCheckout ? "right-0 left-0" : "-right-[200%] left-[200%]"
        }  print:hidden flex justify-end overflow-hidden backdrop-blur-sm bg-cyan-900/30`}
      >
        <div className="h-full w-full md:w-[31rem] bg-white dark:bg-black-900 overflow-hidden relative flex">
          {/**Payment and customer Info collection */}
          <CheckoutInfo
            currentSlide={currentSlide}
            selectedMethod={selectedMethod}
            selectMethod={selectMethod}
            setDetails={setDetails}
            additional_details={additional_details}
            openCheckout={openCheckout}
            setSlide={setSlide}
            calcChange={calcChange}
            change={change}
          />

          {/**Confirmatio Step ========================================== */}
          <Confirmation
            currentSlide={currentSlide}
            setSlide={setSlide}
            showProcessDone={showProcessDone}
            setInvoiceOptions={setInvoiceOptions}
            invoiceOptions={invoiceOptions}
            additional_details={additional_details}
            createTransaction={createTransaction}
            setDetails={setDetails}
            openCheckout={openCheckout}
            transactProcess={transactProcess}
            setProcessDone={setProcessDone}
            selectMethod={selectMethod}
            tipObj={tipObj}
            setTip={setTip}
          />

          {/**Reciept ===================== */}
          <div className="w-fit h-fit hidden print:flex">
            <Reciept sale={cart} componentRef={componentRef} />
          </div>
        </div>
      </div>
  );
};

export default CheckOut;
