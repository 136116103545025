import React, { FC, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import {
  changeLocation,
  setCurrent_workspace,
  updateUserData,
} from "../../Redux/Slices/UserSlice";
import { useNavigate } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { FcShop } from "react-icons/fc";
import { Ring } from "@uiball/loaders";
import { auth } from "../../Firebase/Firebase";
import { signOut } from "firebase/auth";
import { clearAllData } from "../../Utils/Reusable_Func";
import { TbChevronRight } from "react-icons/tb";
import { motion } from "framer-motion";
import Loader from "../../Components/Misc/Loader";

type Props = {
  setOverlay?: any;
};

const Workspaces: FC<Props> = ({ setOverlay }) => {
  const [selectedWorkspace, setWorkspace] = useState<any>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const saved_workspaces = useSelector(
    (state: RootState) => state.UserInfo.saved_workspaces
  );
  const [searchValue, setSearch] = useState<string>("");
  const filteredWorkspaces = useMemo(() => {
    return (
      (saved_workspaces &&
        saved_workspaces
          ?.filter((data: any) =>
            data?.workspace_name
              ?.toLowerCase()
              ?.replace(/\s/gi, "")
              ?.includes(searchValue?.toLowerCase()?.replace(/\s/gi, ""))
          )
          ?.sort((a: any, b: any) =>
            a.workspace_name < b.workspace_name ? -1 : 1
          )) ||
      []
    );
  }, [saved_workspaces, searchValue]);

  //Component ============================
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      exit={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className={`fixed top-0 bottom-0 left-0 right-0 bg-light-bg bg-no-repeat bg-center bg-cover
       w-screen overflow-hidden`}
    >
      {saved_workspaces?.length >= 1 ? (
        <div className="h-full w-full flex flex-col justify-center items-center gap-4 px-4 py-8 bg-inherit backdrop-blur-[2px]">
          <div className="w-[95%] md:w-fit h-fit relative">
            <label htmlFor="search_workspace">
              <input
                type="search"
                name="search"
                id="search"
                value={searchValue}
                autoComplete="off"
                placeholder="Search store ..."
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                className="h-14 w-full md:w-[25rem] p-2 px-4 text-xs text-slate-700 placeholder:text-slate-400
              bg-white/40 border-0 border-b-2 border-slate-300 focus:border-cyan-900/50 focus:ring-0
               focus:outline-none transition-all"
              />
            </label>
            <RiSearch2Line className="absolute right-4 top-4 text-slate-500 text-xl" />
          </div>

          {/**Stores List */}
          <div
            className="h-[25rem] w-[95%] md:w-[25rem] p-1 overflow-hidden overflow-y-scroll no-scrollbar 
           no-scrollbar::-webkit-scrollbar flex flex-col gap-3 auto-rows-min"
          >
            {filteredWorkspaces?.length >= 1 &&
              filteredWorkspaces?.map((space: any) => {
                return (
                  <button
                    key={space?.workspace_name}
                    onClick={() => {
                      setWorkspace(space?.workspace_name);
                      setTimeout(() => {
                        dispatch(setCurrent_workspace(space));
                        dispatch(updateUserData(space));
                        setOverlay && setOverlay(false);
                        navigate("/");
                        window.location.reload();
                      }, 2000);
                    }}
                    className="w-full h-fit rounded-lg shadow bg-white p-3 gap-2 flex items-center group focus:shadow-md 
                 hover:shadow-lg shadow-cyan-800/30 transition-all focus:border-cyan-800/50 select-none cursor-pointer
                  hover:border-cyan-800/50 relative overflow-hidden shrink-0"
                  >
                    <div
                      className="h-11 w-11 rounded-md text-slate-600 text-[2rem] uppercase
                  font-bold flex items-center justify-center group-hover:text-cyan-800 shrink-0"
                    >
                      <FcShop />
                    </div>
                    <span
                      className="whitespace-nowrap text-ellipsis truncate text-xss uppercase font-bold text-slate-700
                       group-hover:text-cyan-800 w-fit"
                    >
                      {space?.company_name}
                    </span>
                    <TbChevronRight className="absolute right-4 top-6 text-xl text-slate-500 group-hover:text-cyan-800" />
                    {selectedWorkspace === space?.workspace_name && (
                      <div
                        className="absolute top-0.5 left-0.5 right-0.5 bottom-0.5 bg-white/20 backdrop-blur-sm
                    flex justify-center items-center p-2"
                      >
                        <Ring
                          size={30}
                          lineWeight={5}
                          speed={2}
                          color="#155e75"
                        />
                      </div>
                    )}
                  </button>
                );
              })}

            <div className="w-full flex items-center justify-between gap-2 mt-2">
              {/**Combined Analytics */}
              <button
                disabled
                title="Coming Soon"
                className="h-11 w-1/2 md:w-40 rounded-lg bg-white border border-cyan-800/50 text-xs capitalize 
                font-semibold text-cyan-800 shadow disabled:cursor-not-allowed whitespace-nowrap truncate px-3"
              >
                combined analytics
              </button>

              {/**Add New Workspace or Store */}
              <button
                onClick={() => {
                  signOut(auth).then(() => {
                    dispatch(changeLocation("Stall"));
                    dispatch(updateUserData(null));
                    clearAllData();
                    document.title = "Stall";
                    navigate("/signup");
                    window.location.reload();
                  });
                }}
                className="h-11 w-1/2 md:w-40 rounded-lg bg-white border border-cyan-800/50 text-xs capitalize font-semibold
               text-cyan-800 shadow hover:border-cyan-800 transition-all whitespace-nowrap truncate px-3"
              >
                add new store
              </button>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </motion.div>
  );
};

export default Workspaces;
