import { FC, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { numberWithSpaces } from "../../Reusable Functions/Functions";
import Authorize from "../../Components/Authorize/Authorize";
import Refund from "./Refund";
import { useReactToPrint } from "react-to-print";
import { AnimatePresence, motion } from "framer-motion";
import {
  RiIndeterminateCircleFill,
  RiMailSendFill,
  RiMenuLine,
  RiMessage2Fill,
  RiPrinterFill,
  RiRefundFill,
} from "react-icons/ri";
import { Menu } from "@mantine/core";
import { voidSale } from "./Helpers";
import Reciept from "../../Components/Sales/Reciept";
import { notifications } from "@mantine/notifications";
import { capitalize } from "moderndash";

type Props = {
  setCurrentSale: any;
  currentSale: any;
};

const SalePreview: FC<Props> = ({ setCurrentSale, currentSale }) => {
  const user = useSelector((state: RootState) => state.UserInfo.user);
  const selectedCurrency = useSelector(
    (state: RootState) => state.SettingsData.selectedCurrency
  );
  const currencies = useSelector(
    (state: RootState) => state.SettingsData.currencies
  );
  const auth_permissions = useSelector(
    (state: RootState) => state.SettingsData.auth_permissions
  );
  const cash_float = useSelector((state: RootState) => state.Sales.cash_float);
  const [showAuthorize, setAuthorize] = useState<boolean>(false);
  const [type, setType] = useState<string>("refund");
  const [reason, setReason] = useState<string>("");
  const inventory_data = useSelector(
    (state: RootState) => state.Inventory.inventory_data
  );
  const [showRefund, openRefund] = useState<boolean>(false);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  //Prompt For Voids Authorization
  const voidTrans = (verify: any) => {
    voidSale(
      verify,
      currentSale,
      reason,
      cash_float,
      selectedCurrency,
      inventory_data,
      setReason,
      setAuthorize,
      setCurrentSale
    );
  };

  const launchRefund = (approved: boolean) => {
    if (approved) {
      openRefund(true);
      setType("refund");
      setAuthorize(false);
    } else {
      notifications.show({ message: "failed to authorize", color: "red" });
    }
  };

  //Component =======
  return (
    <>
      <AnimatePresence>
        {currentSale && (
          <motion.div
            initial={{ right: "-100%", left: "100%", y: 0 }}
            exit={{ right: "-100%", left: "100%", y: 0 }}
            animate={{
              y: currentSale ? 0 : "100%",
              right: 0,
              left: 0,
              position: "fixed",
            }}
            transition={{ duration: 0.2 }}
            className={`h-full w-full fixed top-0 bottom-0 bg-white/40 z-[99999]
           left-auto flex justify-end backdrop-blur-sm`}
          >
            <div
              className={`w-full md:w-[30rem] print:w-full h-full bg-white drop-shadow-2xl p-6 space-y-4`}
            >
              <div className="w-full h-fit flex items-center justify-between">
                {/**Close button */}
                <button
                  onClick={() => {
                    setCurrentSale(null);
                  }}
                  className="h-8 w-8 bg-white text-slate-500 font-medium text-xl rounded
                 hover:bg-red-100 hover:transition-all border border-slate-300 block"
                >
                  -
                </button>
                {/**Close button */}

                {/**Menu ===========*/}
                <Menu
                  transitionProps={{
                    transition: "rotate-right",
                    duration: 40,
                  }}
                  position="bottom-end"
                >
                  <Menu.Target>
                    <button
                      title="Menu"
                      className="h-8 w-8 bg-white text-slate-600 font-medium text-lg rounded
                    hover:text-cyan-800 hover:transition-all border border-slate-300 flex
                     items-center justify-center"
                    >
                      <RiMenuLine />
                    </button>
                  </Menu.Target>
                  <Menu.Dropdown className="shadow-2xl">
                    {new Date()?.getTime() - currentSale?.date < 86400000 && (
                      <Menu.Item
                        onClick={() => {
                          if (
                            auth_permissions?.data?.find(
                              (permission: any) =>
                                permission?.name === "void a sale"
                            )?.active
                          ) {
                            setType("void");
                            setAuthorize(true);
                          } else {
                            voidSale(
                              true,
                              currentSale,
                              reason,
                              cash_float,
                              selectedCurrency,
                              inventory_data,
                              setReason,
                              setAuthorize,
                              setCurrentSale
                            );
                          }
                        }}
                        className="text-xxs uppercase font-bold text-slate-600"
                        icon={
                          <RiIndeterminateCircleFill
                            className="text-slate-600 text-lg hover:text-cyan-800
                          hover:transition-all"
                          />
                        }
                      >
                        Void Sale/order
                      </Menu.Item>
                    )}
                    <Menu.Item
                      onClick={() => {
                        if (
                          auth_permissions?.data?.find(
                            (permission: any) => permission?.name === "refund"
                          )?.active
                        ) {
                          setType("refund");
                          setAuthorize(true);
                        } else {
                          launchRefund(true);
                        }
                      }}
                      className="text-xxs uppercase font-bold text-slate-600"
                      icon={
                        <RiRefundFill
                          className="text-slate-600 text-lg hover:text-cyan-800
                          hover:transition-all"
                        />
                      }
                    >
                      refund sale/order
                    </Menu.Item>
                    <Menu.Item
                      onClick={async () => {
                        const selectedCurrency = currencies?.find(
                          (currency: any) =>
                            currency?.name?.toLowerCase() ===
                            currentSale?.currency_name?.toLowerCase()
                        );
                        //Send Email If Selected
                        if (currentSale?.customers_details?.email) {
                          try {
                            const response = await fetch(
                              import.meta.env.VITE_SALE_EMAIL_CONFI,
                              {
                                method: "POST",
                                headers: {
                                  "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                  ...currentSale,
                                  products: currentSale?.products.map(
                                    (prod: any) => ({
                                      ...prod,
                                      cost: `${selectedCurrency?.symbol} 
              ${numberWithSpaces(
                (
                  selectedCurrency?.rate_multiplier *
                  Number(prod?.prod_obj?.price_in_usd) *
                  Number(prod?.quantity)
                ).toFixed(2)
              )}`,
                                    })
                                  ),
                                  total: `${selectedCurrency?.symbol} 
            ${numberWithSpaces(
              (selectedCurrency?.rate_multiplier * currentSale?.total).toFixed(
                2
              )
            )}`,
                                  tax: `${selectedCurrency?.symbol} 
            ${numberWithSpaces(
              (
                selectedCurrency?.rate_multiplier * currentSale?.tax_in_usd
              ).toFixed(2)
            )}`,
                                  company_name: capitalize(user?.company_name),
                                }),
                              }
                            );

                            if (response.ok) {
                              const data = await response.json();
                              notifications.show({
                                title: "Email confirmation sent",
                                message: data?.message,
                                color: "green",
                              });
                            }
                          } catch (error: any) {
                            notifications.show({
                              title: "Falied send email",
                              message: error?.message,
                              color: "red",
                            });
                            console.error("Function call failed:", error);
                          }
                        }
                      }}
                      className="text-xxs uppercase font-bold text-slate-600"
                      icon={
                        <RiMailSendFill
                          className="text-slate-600 text-lg hover:text-cyan-800
                          hover:transition-all"
                        />
                      }
                    >
                      Email reciept
                    </Menu.Item>
                    <Menu.Item
                      className="text-xxs uppercase font-bold text-slate-600"
                      icon={
                        <RiMessage2Fill
                          className="text-slate-600 text-lg hover:text-cyan-800
                          hover:transition-all"
                        />
                      }
                    >
                      Sms Reciept
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        handlePrint();
                      }}
                      className="text-xxs uppercase font-bold text-slate-600"
                      icon={
                        <RiPrinterFill
                          className="text-slate-600 text-lg hover:text-cyan-800
                          hover:transition-all"
                        />
                      }
                    >
                      Print Reciept
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
                {/**End Of Menu ===========*/}
              </div>

              <div
                className="w-full h-[calc(100%-2.5rem)] flex flex-col items-center pb-14
        overflow-hidden overflow-y-scroll no-scrollbar no-scrollbar::-webkit-scrollbar space-y-6"
              >
                <div className="w-full h-fit px-2 print:hidden">
                  <div className="text-lg font-semibold text-slate-700 flex items-center justify-between w-full">
                    <span className="w-[50%] whitespace-nowrap overflow-hidden text-ellipsis">
                      Total
                    </span>
                    <span className="text-end w-[50%] whitespace-nowrap overflow-hidden text-ellipsis">
                      {selectedCurrency?.symbol}&nbsp;
                      {numberWithSpaces(
                        (
                          currentSale?.total * selectedCurrency?.rate_multiplier
                        )?.toFixed(2)
                      )}
                    </span>
                  </div>
                  <span className="text-xs text-slate-600 w-full whitespace-nowrap overflow-hidden text-ellipsis capitalize">
                    {new Date(currentSale?.date)?.toString()?.split("(")[0]}
                  </span>
                  <div
                    className="text-xs text-slate-600 flex items-center
               justify-between w-full border-t border-slate-200 pt-2 mt-1"
                  >
                    <span className="w-[50%] whitespace-nowrap overflow-hidden text-ellipsis font-medium">
                      Customer&apos;s Name
                    </span>
                    <span className="text-end w-[50%] whitespace-nowrap overflow-hidden text-ellipsis">
                      {currentSale?.customers_details?.name ?? "Unknown"}
                    </span>
                  </div>
                  <div
                    className="text-xs text-slate-600 flex items-center
               justify-between w-full py-0.5"
                  >
                    <span className="w-[50%] whitespace-nowrap overflow-hidden text-ellipsis font-medium">
                      Customer&apos;s Email
                    </span>
                    <span className="text-end w-[50%] whitespace-nowrap overflow-hidden text-ellipsis lowercase">
                      {currentSale?.customers_details?.email
                        ? currentSale?.customers_details?.email
                        : "none"}
                    </span>
                  </div>
                  <div
                    className="text-xs text-slate-600 flex items-center
               justify-between w-full pb-1"
                  >
                    <span className="w-[50%] whitespace-nowrap overflow-hidden text-ellipsis font-medium">
                      Customer&apos;s Address
                    </span>
                    <span className="text-end w-[50%] whitespace-nowrap overflow-hidden text-ellipsis">
                      {currentSale?.customers_details?.address
                        ? currentSale?.customers_details?.address
                        : "No Address"}
                    </span>
                  </div>
                  <div
                    className="text-xs text-slate-600 flex items-center
               justify-between w-full border-t border-slate-200 pt-2 py-0.5 mt-1"
                  >
                    <span className="w-[50%] whitespace-nowrap overflow-hidden text-ellipsis font-medium">
                      Payment Method
                    </span>
                    <span className="text-end w-[50%] whitespace-nowrap overflow-hidden text-ellipsis capitalize">
                      {currentSale?.payment_method}
                    </span>
                  </div>
                  <div
                    className="text-xs text-slate-600 flex items-center
               justify-between w-full py-0.5"
                  >
                    <span className="w-[50%] whitespace-nowrap overflow-hidden text-ellipsis font-medium">
                      Payment Status
                    </span>
                    <span className="text-end w-[50%] whitespace-nowrap overflow-hidden text-ellipsis capitalize">
                      {currentSale?.status}
                    </span>
                  </div>
                  {currentSale?.refund_amount && (
                    <div
                      className="text-xs text-slate-600 flex items-center
               justify-between w-full py-0.5"
                    >
                      <span className="w-[50%] whitespace-nowrap overflow-hidden text-ellipsis font-medium">
                        Refund Amount
                      </span>
                      <span className="text-end w-[50%] whitespace-nowrap overflow-hidden text-ellipsis capitalize">
                        {selectedCurrency?.symbol}&nbsp;
                        {numberWithSpaces(
                          (
                            currentSale?.refund_amount *
                            selectedCurrency?.rate_multiplier
                          )?.toFixed(2)
                        )}
                      </span>
                    </div>
                  )}

                  {currentSale?.refund_date && (
                    <div
                      className="text-xs text-slate-600 flex items-center
               justify-between w-full py-0.5"
                    >
                      <span className="w-[50%] whitespace-nowrap overflow-hidden text-ellipsis font-medium">
                        Refund Date
                      </span>
                      <span className="text-end w-[50%] whitespace-nowrap overflow-hidden text-ellipsis capitalize">
                        {
                          new Date(currentSale?.refund_date)
                            ?.toString()
                            ?.split("(")[0]
                        }
                      </span>
                    </div>
                  )}
                  <div
                    className="text-xs text-slate-600 flex items-center
               justify-between w-full py-0.5"
                  >
                    <span className="w-[50%] whitespace-nowrap overflow-hidden text-ellipsis font-medium">
                      Origin
                    </span>
                    <span className="text-end w-[50%] whitespace-nowrap overflow-hidden text-ellipsis capitalize">
                      {currentSale?.origin}
                    </span>
                  </div>
                  <div
                    className="text-xs text-slate-600 flex items-center
               justify-between w-full py-0.5"
                  >
                    <span className="w-[50%] whitespace-nowrap overflow-hidden text-ellipsis font-medium">
                      Service Type
                    </span>
                    <span className="text-end w-[50%] whitespace-nowrap overflow-hidden text-ellipsis capitalize">
                      {currentSale?.service}
                    </span>
                  </div>
                  <div
                    className="text-xs text-slate-600 flex items-center
               justify-between w-full py-0.5"
                  >
                    <span className="w-[50%] whitespace-nowrap overflow-hidden text-ellipsis font-medium">
                      Served By
                    </span>
                    <span className="text-end w-[50%] whitespace-nowrap overflow-hidden text-ellipsis capitalize">
                      {currentSale?.user?.name}
                    </span>
                  </div>
                  {currentSale?.refund_reason && (
                    <div className="text-xs text-slate-600 flex flex-col space-y-1 w-full py-0.5 pt-2 mt-2 border-t border-slate-300">
                      <span className="w-full whitespace-nowrap overflow-hidden text-ellipsis font-medium">
                        Refund Reason
                      </span>
                      <span className="w-full whitespace-wrap overflow-hidden text-ellipsis capitalize">
                        {currentSale?.refund_reason}
                      </span>
                    </div>
                  )}
                  {currentSale?.note && (
                    <div className="text-xs text-slate-600 flex flex-col space-y-1 w-full py-0.5 pt-2 mt-2 border-t border-slate-300">
                      <span className="w-full whitespace-nowrap overflow-hidden text-ellipsis font-medium">
                        Note
                      </span>
                      <span className="w-full whitespace-wrap overflow-hidden text-ellipsis capitalize">
                        {currentSale?.note}
                      </span>
                    </div>
                  )}
                </div>
                <span className="text-sm font-semibold text-slate-700 w-full">
                  Invoice Preview
                </span>
                {/**Slip Preview */}
                <Reciept componentRef={componentRef} sale={currentSale} />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/**Authorise dialog */}
      <Authorize
        showAuthorize={showAuthorize}
        setAuthorize={setAuthorize}
        passedAuthFunc={type === "void" ? voidTrans : launchRefund}
        reason={reason || ""}
        setReason={setReason}
        showReason={type === "void"}
      />

      {/**Refund Dialog */}
      <Refund
        showRefund={showRefund}
        openRefund={openRefund}
        currentSale={currentSale}
        setCurrentSale={setCurrentSale}
      />
    </>
  );
};

export default SalePreview;
