import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { crypt, decrypt } from "../../Custom Functions/Functions";
import { defaultReceiptOptions } from "../../Utils/default_objects";

//Get Theme From Local Storage ==============
const atLocal = window.localStorage.getItem("atLocal");

export const retriveObjectLocal = (name: string) => {
  const data = window.localStorage.getItem(name);
  return data
    ? JSON.parse(decrypt(import.meta.env.VITE_API_KEY, JSON.parse(data)))
    : null;
};

const defaultCurrency = {
  name: "usd",
  symbol: "$",
  rate_multiplier: 1,
  id: "defaultusd94273987023",
};

export interface channels {
  name: string;
  description: string;
  id: string;
}
export interface fulfillmentProviders {
  id?: string;
  name: string;
  type: string;
  isDefault: boolean;
  status: string;
  fee_calculation_type:
    | "fixed"
    | "locations"
    | "distance"
    | "order amount"
    | "none";
  fee_in_usd: number;
  minimum_order_amount: number;
  locations: any[];
  channels: any[];
}

interface InitialStateType {
  system_data: any[];
  currencies: any[];
  selectedCurrency: any;
  local_ref: string;
  fireFloat: boolean;
  measurement_units: any;
  auth_data: any;
  auth_permissions: any;
  access_permissions: any;
  payment_methods: any;
  workspace_details: any;
  receipt_settings: any;
  customer_display_settings: any;
  channels: [channels];
  theme: string;
  fulfillment_providers: [fulfillmentProviders] | any;
}

const initialState: InitialStateType = {
  system_data: retriveObjectLocal("system_data"),
  local_ref: atLocal ? JSON.parse(atLocal) : "58595859",
  currencies: retriveObjectLocal("currencies") || [],
  selectedCurrency:
    retriveObjectLocal("selectedCurrency") ||
    retriveObjectLocal("currencies")?.[0] ||
    defaultCurrency,
  fireFloat: false,
  measurement_units: [
    {
      name: "item",
      measurement: 1,
      decimal_precision: "none",
      symbol: "",
      base: null,
    },
    {
      name: "item with decimals",
      measurement: 1,
      decimal_precision: 10,
      symbol: "",
      base: null,
    },
    {
      name: "milligram",
      measurement: 0.000001,
      decimal_precision: 6,
      symbol: "mg",
      base: "kilogram",
    },
    {
      name: "gram",
      measurement: 0.001,
      decimal_precision: 4,
      symbol: "g",
      base: "kilogram",
    },
    {
      name: "ounce",
      measurement: 0.02834952,
      decimal_precision: 8,
      symbol: "oz",
      base: "kilogram",
    },
    {
      name: "kilogram",
      measurement: 1,
      decimal_precision: 4,
      symbol: "kg",
      base: "kilogram",
    },
    {
      name: "pound",
      measurement: 2.20462,
      decimal_precision: 5,
      symbol: "lb",
      base: "kilogram",
    },
    {
      name: "milliliter",
      measurement: 0.001,
      decimal_precision: 4,
      symbol: "ml",
      base: "litre",
    },
    {
      name: "fluid once",
      measurement: 0.0295735,
      decimal_precision: 7,
      symbol: "fl oz",
      base: "litre",
    },
    {
      name: "cup",
      measurement: 0.236588,
      decimal_precision: 4,
      symbol: "c",
      base: "litre",
    },
    {
      name: "litre",
      measurement: 1,
      decimal_precision: 4,
      symbol: "l",
      base: "litre",
    },
    {
      name: "gallon",
      measurement: 3.78541,
      decimal_precision: 5,
      symbol: "gal",
      base: "litre",
    },
    {
      name: "centimeter",
      measurement: 0.00001,
      decimal_precision: 5,
      symbol: "cm",
      base: "kilometer",
    },
    {
      name: "meter",
      measurement: 0.001,
      decimal_precision: 3,
      symbol: "m",
      base: "kilometer",
    },
    {
      name: "foot",
      measurement: 0.0003048,
      decimal_precision: 7,
      symbol: "ft",
      base: "kilometer",
    },
    {
      name: "inch",
      measurement: 0.0000508,
      decimal_precision: 7,
      symbol: "in",
      base: "kilometer",
    },
    {
      name: "kilometer",
      measurement: 1,
      decimal_precision: 4,
      symbol: "km",
      base: "kilometer",
    },
    {
      name: "mile",
      measurement: 1.609344,
      decimal_precision: 6,
      symbol: "mi",
      base: "kilometer",
    },
    {
      name: "millisecond",
      measurement: 1,
      decimal_precision: 0,
      symbol: "ms",
      base: null,
    },
    {
      name: "second",
      measurement: 1,
      decimal_precision: 0,
      symbol: "sec",
      base: null,
    },
    {
      name: "minute",
      measurement: 1,
      decimal_precision: 0,
      symbol: "min",
      base: null,
    },
    {
      name: "hour",
      measurement: 1,
      decimal_precision: 0,
      symbol: "hr",
      base: null,
    },
    {
      name: "day",
      measurement: 1,
      decimal_precision: 0,
      symbol: "d",
      base: null,
    },
    {
      name: "quarter",
      measurement: 0.25,
      decimal_precision: 2,
      symbol: "qtr",
      base: "full",
    },
    {
      name: "half",
      measurement: 0.5,
      decimal_precision: 2,
      symbol: "hlf",
      base: "full",
    },
    {
      name: "full",
      measurement: 1,
      decimal_precision: 2,
      symbol: "full",
      base: "full",
    },
  ],
  auth_data: retriveObjectLocal("auth_data"),
  auth_permissions: retriveObjectLocal("auth_permissions"),
  access_permissions: retriveObjectLocal("access_permissions"),
  payment_methods: retriveObjectLocal("payment_methods"),
  workspace_details: retriveObjectLocal("workspace_details"),
  receipt_settings:
    retriveObjectLocal("receipt_settings") || defaultReceiptOptions,
  customer_display_settings: retriveObjectLocal("customer_display_settings"),
  channels: retriveObjectLocal("channels"),
  fulfillment_providers: retriveObjectLocal("fulfillment_providers"),
  theme:
    // window.matchMedia &&
    // window.matchMedia("(prefers-color-scheme: dark)").matches
    //   \? "dark"
    //   :
    retriveObjectLocal("theme") || "light",
};

export const SettingsSlice = createSlice({
  name: "SettingsData",
  initialState,
  reducers: {
    loadSystemSettings: (state, action: PayloadAction<any>) => {
      state.system_data = action.payload;
      action.payload &&
        window.localStorage.setItem(
          "system_data",
          JSON.stringify(
            crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
          )
        );
    },
    loadCurrencies: (state, action: PayloadAction<any[]>) => {
      state.currencies = action.payload;
      window.localStorage.setItem(
        "currencies",
        JSON.stringify(
          crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
        )
      );

      if (
        state.selectedCurrency === undefined ||
        state.selectedCurrency === null ||
        state.selectedCurrency?.id === "defaultusd94273987023"
      ) {
        if (action.payload?.[0]) {
          state.selectedCurrency = action.payload?.[0];
          window.localStorage.setItem(
            "selectedCurrency",
            JSON.stringify(
              crypt(
                import.meta.env.VITE_API_KEY,
                JSON.stringify(action.payload[0])
              )
            )
          );
        } else {
          state.selectedCurrency = defaultCurrency;
          window.localStorage.setItem(
            "selectedCurrency",
            JSON.stringify(
              crypt(
                import.meta.env.VITE_API_KEY,
                JSON.stringify(defaultCurrency)
              )
            )
          );
        }
      }
    },
    setCurrency: (state, action: PayloadAction<any[]>) => {
      state.selectedCurrency = action.payload;
      window.localStorage.setItem(
        "selectedCurrency",
        JSON.stringify(
          crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
        )
      );
    },
    updateLocalRef: (state, action: PayloadAction<string>) => {
      state.local_ref = action.payload;
    },
    openDrawer: (state, action: PayloadAction<boolean>) => {
      state.fireFloat = action.payload;
    },
    updateAuthData: (state, action: PayloadAction<any>) => {
      state.auth_data = action.payload;
      window.localStorage.setItem(
        "auth_data",
        JSON.stringify(
          crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
        )
      );
    },
    updateAuthPermissionData: (state, action: PayloadAction<any>) => {
      state.auth_permissions = action.payload;
      action.payload &&
        window.localStorage.setItem(
          "auth_permissions",
          JSON.stringify(
            crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
          )
        );
    },
    updateAccessPermissionData: (state, action: PayloadAction<any>) => {
      state.access_permissions = action.payload;
      action.payload &&
        window.localStorage.setItem(
          "access_permissions",
          JSON.stringify(
            crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
          )
        );
    },
    updateWorkspaceDetails: (state, action: PayloadAction<any>) => {
      state.workspace_details = action.payload;
      action.payload &&
        window.localStorage.setItem(
          "workspace_details",
          JSON.stringify(
            crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
          )
        );
    },
    updateReceiptSettings: (state, action: PayloadAction<any>) => {
      state.receipt_settings = action.payload;
      action.payload &&
        window.localStorage.setItem(
          "receipt_settings",
          JSON.stringify(
            crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
          )
        );
    },
    updateCustomerDispaySettings: (state, action: PayloadAction<any>) => {
      state.customer_display_settings = action.payload;
      action.payload &&
        window.localStorage.setItem(
          "customer_display_settings",
          JSON.stringify(
            crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
          )
        );
    },
    updatePaymentsMethods: (state, action: PayloadAction<any>) => {
      state.payment_methods = action.payload;
      action.payload &&
        window.localStorage.setItem(
          "payment_methods",
          JSON.stringify(
            crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
          )
        );
    },
    loadChannels: (state, action: PayloadAction<[channels] | any>) => {
      state.channels = action.payload;
      action.payload &&
        window.localStorage.setItem(
          "channels",
          JSON.stringify(
            crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
          )
        );
    },
    loadProviders: (
      state,
      action: PayloadAction<[fulfillmentProviders] | any>
    ) => {
      state.fulfillment_providers = action.payload;
      action.payload &&
        window.localStorage.setItem(
          "fulfillment_providers",
          JSON.stringify(
            crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
          )
        );
    },
    switchTheme: (state, action: PayloadAction<string>) => {
      state.theme = action.payload;
      action.payload &&
        window.localStorage.setItem(
          "theme",
          JSON.stringify(
            crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
          )
        );
    },
  },
});

export const {
  loadCurrencies,
  setCurrency,
  updateLocalRef,
  openDrawer,
  updateAuthData,
  updateAuthPermissionData,
  updateAccessPermissionData,
  updateWorkspaceDetails,
  updatePaymentsMethods,
  loadSystemSettings,
  loadChannels,
  loadProviders,
  switchTheme,
  updateReceiptSettings,
  updateCustomerDispaySettings,
} = SettingsSlice.actions;

export default SettingsSlice.reducer;
