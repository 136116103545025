import { FC } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import Item from "./Item";
type Props = {
  focusIndex: any;
  addToCart: any;
  quantity: any;
  setQuantity: any;
  results: any;
  openSearch:any
};

const ItemsList: FC<Props> = ({
  focusIndex,
  addToCart,
  quantity,
  setQuantity,
  results,
  openSearch
}) => {
  const [searchProductsList] = useAutoAnimate(/* optional config */);

  //Components ============================
  return (
    <ul
      ref={searchProductsList}
      className="w-full h-[calc(100%-3rem)] oveflow-hidden oveflow-y-scroll no-scrollbar 
    no-scrollbar::-webkit-scrollbar py-4 gap-2 flex flex-col"
    >
      {results?.length >= 1 &&
        results?.map((data: any, index: number) => {
          return (
            <Item
              key={index}
              data={data}
              addToCart={addToCart}
              focusIndex={focusIndex}
              index={index}
              quantity={quantity}
              setQuantity={setQuantity}
              openSearch={openSearch}
            />
          );
        })}
    </ul>
  );
};

export default ItemsList;
