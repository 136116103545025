import { useState, useRef, useEffect } from "react";

const useLongPress = (
  callback: any,
  { delay = 300, threshold = 400 }: { delay: number; threshold: number }
) => {
  const [pressDuration, setPressDuration] = useState<number>(0);
  const intervalRef = useRef<NodeJS.Timeout | null | any>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const delayRef = useRef<NodeJS.Timeout | null>(null);

  const startLongPress = () => {
    delayRef.current = setTimeout(() => {
      intervalRef.current = setInterval(() => {
        setPressDuration((prevDuration) => prevDuration + 50);
      }, 50);

      timeoutRef.current = setTimeout(() => {
        setPressDuration(0);
        intervalRef?.current && clearInterval(intervalRef?.current);
        callback();
      }, threshold || 400);
    }, delay || 300);
  };

  const endLongPress = () => {
    delayRef.current && clearTimeout(delayRef?.current);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setPressDuration(0);
  };

  useEffect(() => {
    const onMouseUp = () => {
      endLongPress();
    };

    const onTouchEnd = () => {
      endLongPress();
    };

    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("touchend", onTouchEnd);

    return () => {
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("touchend", onTouchEnd);
      delayRef.current && clearTimeout(delayRef.current);
      clearInterval(intervalRef.current);
      timeoutRef?.current && clearTimeout(timeoutRef.current);
    };
  }, []);

  return {
    onMouseDown: startLongPress,
    onTouchStart: startLongPress,
    pressDuration,
  };
};

export default useLongPress;
