import React, { FC, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { updateCart } from "../../../Redux/Slices/OrderSlice";
import Authorize from "../../../Components/Authorize/Authorize";

const Discount: FC = () => {
  const cart = useSelector((state: RootState) => state.OrderData.cart);
  const selectedCurrency = useSelector(
    (state: RootState) => state.SettingsData.selectedCurrency
  );
  const [showAuthorize, setAuthorize] = useState<boolean>(false);
  const discountFormRef = useRef<HTMLFormElement>(null);
  const [discountObj, setDiscount] = useState<any>({
    type: "amount",
    value: "",
  });
  const dispatch = useDispatch();
  const auth_permissions = useSelector(
    (state: RootState) => state.SettingsData.auth_permissions
  );

  //Apply Discount Function
  const applyDiscount = () => {
    if (discountObj?.type === "amount") {
      if (cart?.discount_amount > 0) {
        const value =
          Number(discountObj?.value) / selectedCurrency?.rate_multiplier;
        dispatch(
          updateCart({
            ...cart,
            profit: cart?.profit + value,
            total: cart?.total + value,
            discount_type: discountObj?.type,
            discount_amount: Number(cart?.discount_amount) - value,
            discount_percent:
              Number(cart?.discount_percent) -
              (value / (cart?.total + value)) * 100,
            discount_code: "none",
          })
        );
        discountFormRef &&
          discountFormRef.current &&
          discountFormRef.current.reset();
        setDiscount({
          type: "amount",
          value: "",
        });
      } else {
        const value =
          Number(discountObj?.value) / selectedCurrency?.rate_multiplier;
        const verifiedValue = cart?.discount_amount < value ? 0 : value;
        dispatch(
          updateCart({
            ...cart,
            profit: cart?.profit - verifiedValue,
            total: cart?.total - verifiedValue,
            discount_type: discountObj?.type,
            discount_amount: verifiedValue,
            discount_percent: (verifiedValue / cart?.total) * 100,
            discount_code: "none",
          })
        );
        discountFormRef &&
          discountFormRef.current &&
          discountFormRef.current.reset();
        setDiscount({
          type: "amount",
          value: "",
        });
      }
    } else if (discountObj?.type === "percent") {
      if (cart?.discount_amount > 0) {
        const value =
          (Number(discountObj?.value) / 100) *
          (cart?.total + cart.discount_amount);
        dispatch(
          updateCart({
            ...cart,
            profit: cart?.profit + value,
            total: cart?.total + value,
            discount_type: discountObj?.type,
            discount_amount: cart?.discount_amount - value,
            discount_percent:
              Number(cart?.discount_percent) - Number(discountObj?.value),
            discount_code: "none",
          })
        );
        discountFormRef &&
          discountFormRef.current &&
          discountFormRef.current.reset();
        setDiscount({
          type: "amount",
          value: "",
        });
      } else {
        const value = (Number(discountObj?.value) / 100) * cart?.total;
        const verifiedValue = cart?.discount_amount < value ? 0 : value;
        dispatch(
          updateCart({
            ...cart,
            profit: cart?.profit - verifiedValue,
            total: cart?.total - verifiedValue,
            discount_type: discountObj?.type,
            discount_amount: verifiedValue,
            discount_percent: discountObj?.value,
            discount_code: "none",
          })
        );
        discountFormRef &&
          discountFormRef.current &&
          discountFormRef.current.reset();
        setDiscount({
          type: "amount",
          value: "",
        });
      }
    }
    setAuthorize(false);
    setDiscount({
      type: "amount",
      value: "",
    });
  };

  //Auth Func
  const authAction = (verify: any) => {
    if (verify) {
      applyDiscount();
    }
  };

  //Component =========================
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (
            auth_permissions?.data?.find(
              (permission: any) => permission?.name === "discount"
            )?.active
          ) {
            setAuthorize(true);
          } else {
            applyDiscount();
          }
        }}
        ref={discountFormRef}
        className="flex items-center justify-between h-10"
      >
        <input
          onChange={(e) => {
            setDiscount((prev: any) => ({
              ...prev,
              value: Number(e.target.value?.replace(/%/gim, "")),
            }));
          }}
          value={discountObj?.value}
          autoComplete="off"
          type="number"
          step={0.01}
          max={
            cart?.discount_amount <= 0
              ? 10000000
              : discountObj?.type === "amount"
              ? cart?.discount_amount
              : cart?.discount_percent
          }
          name="discount"
          id="discount"
          required
          placeholder="Discount ..."
          className="discount_n_tipInput"
        />
        <select
          required
          onChange={(e) => {
            setDiscount((prev: any) => ({
              ...prev,
              type: e.target.value,
            }));
          }}
          className="h-full w-28 border border-l-0 focus:border-l border-slate-300 dark:border-black-600
             bg-slate-50/50 dark:bg-black-700 text-xs text-slate-600 dark:text-black-300 focus:ring-0  focus:border-cyan-800
              transition-all overflow-hidden disabled:cursor-not-allowed font-medium dark:placeholder:text-black-400"
        >
          <option value="">Type</option>
          <option
            disabled={
              cart?.discount_amount > 0 && cart?.discount_type !== "amount"
            }
            value="amount"
          >
            Amount
          </option>
          <option
            disabled={
              cart?.discount_amount > 0 && cart?.discount_type !== "percent"
            }
            value="percent"
          >
            Percent
          </option>
          <option disabled value="code">
            Code
          </option>
        </select>
        <button
          type="submit"
          className="w-20 h-full bg-cyan-800 text-[0.65rem] uppercase font-medium text-white rounded-r
           hover:bg-cyan-800 transition-all disabled:cursor-not-allowed"
        >
          {cart?.discount_amount > 0 ? "Revoke" : "Apply"}
        </button>
      </form>

      {/**Auth */}
      <Authorize
        showAuthorize={showAuthorize}
        setAuthorize={setAuthorize}
        passedAuthFunc={authAction}
        showReason={false}
      />
    </>
  );
};

export default Discount;
