import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//Get Theme From Local Storage ==============
const reports_date = localStorage.getItem("reports_date");

interface InitialStateType {
  reports_date: any;
}

const initialState: InitialStateType = {
  reports_date: reports_date
    ? JSON.parse(reports_date)
    : { start: new Date().getTime() - 86400000, end: new Date().getTime() },
};

export const ReportSlice = createSlice({
  name: "Reports",
  initialState,
  reducers: {
    changeReportsDate: (state, action: PayloadAction<any>) => {
      state.reports_date = action.payload;
    },
  },
});

export const { changeReportsDate } = ReportSlice.actions;

export default ReportSlice.reducer;
