import { FC, useState } from "react";
import ProductsList from "../Products List/ProductsList";
import { useSelector } from "react-redux";
import { BsUpcScan } from "react-icons/bs";
import Search from "../Spotlight Search/Search";
import { RootState } from "../../../Redux/store";
import { useHotkeys } from "@mantine/hooks";
import { TbShoppingBag } from "react-icons/tb";
import Categories from "./Categories";

type Props = {
  setSmCart: any;
};

const ProductsHalfContainer: FC<Props> = ({ setSmCart }) => {
  const cart = useSelector((state: RootState) => state.OrderData.cart);
  const [isSeachOpen, openSearch] = useState<boolean>(false);
  const [selectedCategory, addCategory] = useState<any>([]);

  //Shorcut Event Listener =======
  useHotkeys([
    [
      "f2",
      () =>
        location.pathname === "/app" &&
        openSearch((prev: boolean) => (prev ? false : true)),
    ],
  ]);

  //Component =================================
  return (
    <div className="h-full w-full lg:w-[calc(100%-27rem)] pb-0 lg:py-4 bg-inherit">
      <div className="h-full w-full flex flex-col gap-4 bg-inherit">
        {/** Search ===============================*/}
        <div className="h-12 w-full flex items-center justify-between relative">
          {cart?.products?.length >= 1 && (
            <div
              className="h-5 w-5 rounded-full absolute -right-1 -top-1 z-10  bg-cyan-800 lg:hidden flex items-center justify-center
               text-xxs font-bold text-white overflow-hidden whitespace-nowrap truncate"
            >
              {cart?.products
                ?.map((item: any) => item?.quantity)
                ?.reduce((acc: number, value: number) => acc + value, 0)}
            </div>
          )}
          <div
            className="h-full w-full relative flex border-slate-300 dark:border-black-600
              border focus-within:!border-cyan-700/50 focus-within:ring-2 ring-cyan-800/20
               rounded-full md:rounded-md bg-white dark:bg-black-900 p-1 gap-1"
          >
            <button
              onClick={() => openSearch(true)}
              className="h-full w-full rounded-[inherit] p-2 px-3 text-xs flex items-center bg-inherit
              dark:text-black-400  text-slate-500 focus:ring-0 font-medium border-0"
            >Quick search</button>
            <div
              className="flex items-center h-full w-fit rounded-[inherit] rounded-l-md overflow-hidden
             border border-slate-300 dark:border-black-600 divide-x divide-slate-300 dark:divide-black-600 min-w-fit"
            >
              <button
                className="w-11 md:w-24 h-full bg-slate-50 dark:bg-black-800 uppercase font-bold text-[0.65rem] gap-2
              focus:outline-none text-slate-600 dark:text-black-300 hover:bg-cyan-800 hover:text-white transition-all
               flex items-center justify-center"
              >
                <span className="hidden md:block">scan</span>
                <BsUpcScan className="flex text-lg stroke-[0.5]" />
              </button>
              <button
                onClick={() => setSmCart(true)}
                className="w-10 md:w-24 h-full bg-slate-50 dark:bg-black-800 uppercase font-bold text-xxs flex lg:hidden
                items-center justify-center focus:outline-none gap-1 text-slate-600 dark:text-black-300 hover:bg-cyan-800
                 hover:border-cyan-800 hover:text-white transition-all"
              >
                <span className="hidden md:flex">checkout</span>
                <TbShoppingBag className="flex md:hidden text-xl" />
              </button>
            </div>
          </div>
        </div>

        {/** Categories */}
        <Categories
          selectedCategory={selectedCategory}
          addCategory={addCategory}
        />

        {/** Products */}
        <ProductsList
          selectedCategory={selectedCategory}
        />

        {/** Spotlight Search */}
        <Search isSeachOpen={isSeachOpen} openSearch={openSearch} />
      </div>
    </div>
  );
};

export default ProductsHalfContainer;
