import React from "react";
import { customizeProduct } from "../../../Redux/Slices/OrderSlice";
import { useDispatch } from "react-redux";

type Props = {
  selected_product: any;
};

const Customizations = ({ selected_product }: Props) => {
  const dispatch = useDispatch();

  //COmponent ===================
  return (
    <>
      <span
        className="text-xs h-6 w-full font-bold text-slate-600 dark:text-black-300 whitepsace-nowrap
        shrink-0 overflow-hidden text-ellipsis uppercase"
      >
        Customization
      </span>
      <div className="w-full h-fit md:h-[10rem] md:overflow-hidden md:overflow-y-scroll flex flex-col gap-2 pr-2 py-2">
        {selected_product?.prod_obj?.customization_option?.length >= 1 &&
          selected_product?.prod_obj?.customization_option?.map((cust: any) => {
            return (
              <div key={cust?.id} className="flex flex-col gap-2 shrink-0">
                <span className="text-xss text-slate-600 dark:text-black-300 font-bold uppercase">
                  {cust?.name}
                </span>
                <div className="h-fit w-full grid grid-cols-4 gap-2">
                  {cust?.options?.map((opt: any) => {
                    return (
                      <div
                        className={`h-7 col-span-1 bg-slate-50 dark:bg-black-700 border rounded hover:shadow-md transition-all
                                  flex items-center justify-center space-x-2 select-none cursor-pointer ${
                                    selected_product?.customization?.filter(
                                      (data: any) =>
                                        data?.name === cust?.name &&
                                        data?.option === opt?.name
                                    )?.length >= 1
                                      ? "border-cyan-800"
                                      : "border-slate-300 dark:border-black-500"
                                  } text-slate-600 dark:text-black-300`}
                        onClick={() => {
                          dispatch(
                            customizeProduct({
                              ...selected_product,
                              customization: [
                                ...selected_product?.customization?.filter(
                                  (data: any) => data?.name !== cust?.name
                                ),
                                {
                                  name: cust?.name,
                                  option: opt?.name,
                                },
                              ],
                            })
                          );
                        }}
                        key={opt?.id}
                      >
                        <span
                          className="text-xs font-semibold max-w-[calc(100%-2rem)] overflow-hidden 
                                    whitespace-nowrap text-ellipsis capitalize"
                        >
                          {" "}
                          {opt?.name}
                        </span>
                        {cust?.name
                          ?.toString()
                          ?.toLowerCase()
                          ?.includes("color") && (
                          <div
                            style={{
                              background: opt?.name?.toLowerCase(),
                            }}
                            className="h-3 w-3 rounded-full border border-slate-200 dark:border-black-700"
                          ></div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Customizations;
