import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/Logos/new_dark_short.png";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  sendPasswordResetEmail,
} from "firebase/auth";
//Firestore ===================
import { collection, getDocs, where, query } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { saveWorkSpaces, updateUserData } from "../../Redux/Slices/UserSlice";
import { AppDispatch, RootState } from "../../Redux/store";
import { auth } from "../../Firebase/Firebase";
import AlertsWrapper from "../../Components/Toast Notifications/AlertsWrapper";
import { db } from "../../Firebase/Firebase";
import Workspaces from "./Workspaces";
import { RiAtLine } from "react-icons/ri";
import { notifications } from "@mantine/notifications";
import { TbLock } from "react-icons/tb";
import { AnimatePresence } from "framer-motion";
import { useAuth } from "../../Custom-Hooks/useAuth";
import Loader from "../../Components/Misc/Loader";

const Login: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, currentUser } = useAuth();
  const user = useSelector((state: RootState) => state.UserInfo.user);
  const current_workspace = useSelector(
    (state: RootState) => state.UserInfo.current_workspace
  );
  const [incorrectWarning, setWarning] = useState<boolean>(false);
  const [showOverlay, setOverlay] = useState<boolean>(false);
  const [logging, setLogging] = useState<boolean>(false);
  const [logValues, setValues] = useState<any>({
    email: "",
    passwd: "",
    remember: true,
  });

  //Log In
  const handleLogIn = (e: React.SyntheticEvent) => {
    setLogging(true);
    e.preventDefault();
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        signInWithEmailAndPassword(auth, logValues.email, logValues.passwd)
          .then((currentUser: any) => {
            //Check All Workspaces where User is available ===============
            const userRef: any = query(
              collection(db, "all_users"),
              where(
                "email",
                "==",
                currentUser.user.email?.replace(/\s/g, "")?.toLowerCase()
              )
            );
            //Get User Doc to get path to workspaces
            getDocs(userRef)
              .then((snapshot) => {
                if (snapshot.docs?.length >= 1) {
                  const user = snapshot.docs.map((doc: any) => ({
                    ...doc.data(),
                    id: doc.id,
                  }))[0];
                  window.localStorage.setItem(
                    "main_id",
                    JSON.stringify(user?.id)
                  );

                  //Set Path to workspaces
                  dispatch(saveWorkSpaces(user?.workspaces));
                  dispatch(updateUserData(user?.workspaces[0]));
                  setLogging(false);
                  setOverlay(true);
                  setValues({
                    email: "",
                    passwd: "",
                  });
                }
              })
              .catch((error: any) => {
                notifications.show({
                  title: "We ran into an error",
                  message: error.message,
                  color: "red",
                });
                setLogging(false);
                setOverlay(false);
                setWarning(true);
                setTimeout(() => {
                  setWarning(false);
                }, 5000);
              });
          })
          .catch((error: any) => {
            notifications.show({
              title: "We ran into an error",
              message: error.message?.split(":")[1],
              color: "red",
            });
            setLogging(false);
            setOverlay(false);
            setWarning(true);
            setTimeout(() => {
              setWarning(false);
            }, 5000);
          });
      })
      .catch((error: any) => {
        notifications.show({
          title: "We ran into an error",
          message: error.message,
          color: "red",
        });
        setLogging(false);
        setOverlay(false);
        setWarning(true);
        setTimeout(() => {
          setWarning(false);
        }, 5000);
      });
  };

  //Check If User Is Logged
  useEffect(() => {
    if (currentUser && !loading) {
      if (user && current_workspace) {
        navigate("/");
      } else {
        setOverlay(true);
      }
    }
  }, [navigate, user, current_workspace, currentUser, loading]);

  //component
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {!showOverlay ? (
            <div
              className="w-screeen min-h-screen bg-gradient-to-br from-white via-cyan-800/20 to-light-bg
           relative overflow-hidden flex flex-col items-center justify-center"
            >
              <a
                href="https://stallpos.com"
                rel="noreferrer"
                className="cursor-pointer"
              >
                <img
                  src={logo}
                  alt="logo"
                  className="w-10 h-10 rounded-md bg-white p-2 shadow pointer-events-none"
                />
              </a>
              <h1 className="text-cyan-800 font-semibold text-xl md:text-[1.5rem] text-center mt-4">
                Hi let&apos;s get you selling
              </h1>

              <form
                onSubmit={(e) => handleLogIn(e)}
                className="mt-4 w-[21rem] h-fit flex flex-col items-center overflow-hidden p-1"
              >
                <label
                  htmlFor="login_email"
                  className="w-full h-12 relative group"
                >
                  <RiAtLine className="absolute left-3.5 top-3.5 text-slate-400 text-xl group-focus-within:text-cyan-800" />
                  <input
                    value={logValues?.email}
                    onChange={(e) =>
                      setValues((prev: any) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                    required
                    type="email"
                    id="login_email"
                    name="login_email"
                    placeholder="Email"
                    className={`w-full h-full bg-white rounded-md lowercase placeholder:capitalize transition-all ${
                      incorrectWarning
                        ? "border-red-600"
                        : "border-slate-400/70"
                    } focus:border-cyan-700 focus:ring-0 transition-all placeholder:text-slate-400 text-slate-700 text-xs pl-10
                `}
                  />
                </label>
                <label
                  htmlFor="login_password"
                  className="mt-2 w-full h-12 relative group"
                >
                  <TbLock className="absolute left-3.5 top-3.5 text-slate-400 text-xl group-focus-within:text-cyan-800" />
                  <input
                    value={logValues?.passwd}
                    onChange={(e) =>
                      setValues((prev: any) => ({
                        ...prev,
                        passwd: e.target.value,
                      }))
                    }
                    required
                    type="password"
                    autoComplete="off"
                    id="login_password"
                    name="login_password"
                    placeholder="Password"
                    className={`w-full h-full bg-white rounded-md lowercase placeholder:capitalize transition-all ${
                      incorrectWarning
                        ? "border-red-600"
                        : "border-slate-400/70"
                    } focus:border-cyan-700 focus:ring-0 transition-all placeholder:text-slate-400 text-slate-700 text-xs pl-10
                `}
                  />
                </label>

                <div className="mt-3 w-full flex items-center justify-between text-xss px-1">
                  <label
                    htmlFor="remember_me"
                    className="w-fit flex items-center gap-1.5"
                  >
                    <input
                      onChange={(e) => {
                        if (e.target.checked) {
                          setValues((prev: any) => ({
                            ...prev,
                            remember: true,
                          }));
                          window.localStorage.setItem("remember", "true");
                        } else {
                          setValues((prev: any) => ({
                            ...prev,
                            remember: false,
                          }));
                          window.localStorage.setItem("remember", "false");
                        }
                      }}
                      type="checkbox"
                      name="remember_me"
                      id="remember_me"
                      checked={logValues.remember}
                      className="rounded bg-white checked:bg-cyan-800 checked:focus:bg-cyan-800 focus:ring-0 focus:border-0 
                  border-slate-400 sm:border-slate-300 h-3.5 w-3.5 focus:outline-none focus:border-cyan-700 accent-cyan-700
                   appearance-none hover:checked:bg-cyan-800"
                    />
                    <span className="whitespace-nowrap truncate text-slate-600 font-medium">
                      Remember me
                    </span>
                  </label>
                  <button
                    type="button"
                    onClick={() => {
                      if (logValues?.email) {
                        sendPasswordResetEmail(auth, logValues?.email)
                          .then(() => {
                            notifications.show({
                              title: "Emails sent",
                              message: "Password reset email sent!",
                              color: "green",
                            });
                          })
                          .catch((error) => {
                            notifications.show({
                              title: "We ran into an error",
                              message: error?.message?.split(":")[1],
                              color: "red",
                            });
                          });
                      } else {
                        notifications.show({
                          title: "We ran into an error",
                          message: "Enter valid a email",
                          color: "red",
                        });
                      }
                    }}
                    className="text-slate-600 font-medium hover:opacity-75 transition-all capitalize"
                  >
                    Forgot password ?
                  </button>
                </div>

                <button
                  type="submit"
                  className="mt-3 w-full h-12 rounded-md shrink-0 bg-cyan-800 text-xs uppercase transition-all
             text-white font-bold flex items-center justify-center gap-4 hover:gap-6 hover:opacity-80 
               hover:ring-1 ring-offset-2 ring-cyan-700"
                >
                  {!logging ? (
                    <>
                      <span>Sign In</span>
                    </>
                  ) : (
                    <div className="h-5 w-5 border-2 border-white border-l-cyan-700 animate-spin rounded-full"></div>
                  )}
                </button>

                {/**signup =================== */}
                <div className="mt-3 w-full flex items-center justify-between gap-4 overflow-hidden">
                  <div className="w-2/6 h-0.5 border-b border-slate-400/70"></div>
                  <span
                    className="text-xss font-semibold text-cyan-800 text-center
               whitespace-nowrap"
                  >
                    or create an account
                  </span>
                  <div className="w-2/6 h-0.5 border-b border-slate-400/70"></div>
                </div>
                <button
                  onClick={() => {
                    navigate("/signup");
                  }}
                  type="button"
                  className="mt-3 w-full h-12 rounded-md bg-white transition-all hover:opacity-80 text-xs uppercase
             text-cyan-800 font-bold flex items-center justify-center border border-cyan-800/60 shrink-0"
                >
                  sign up
                </button>
              </form>
            </div>
          ) : (
            <AnimatePresence>
           <Workspaces setOverlay={setOverlay} />
            </AnimatePresence>
          )}
          {/**Chose WorkSpace Overlay */}
          <AlertsWrapper />
        </>
      )}
    </>
  );
};

export default Login;
