import { FC, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { TbArrowNarrowLeft } from "react-icons/tb";
import { updateCart } from "../../../Redux/Slices/OrderSlice";
import { useClickOutside } from "@mantine/hooks";
import { motion, AnimatePresence } from "framer-motion";

type Props = {
  isCustomOpen: boolean;
  setCustom: any;
};

const CustomSale: FC<Props> = ({ isCustomOpen, setCustom }) => {
  const selectedCurrency = useSelector(
    (state: RootState) => state.SettingsData.selectedCurrency
  );
  const [value, setValues] = useState<any>({
    amount: "",
    note: "",
  });
  const textAreaRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const cart = useSelector((state: RootState) => state.OrderData.cart);
  const mdoalRef = useClickOutside(() => setCustom(false));

  //Handle Submit =========
  const handleSubmit = (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    dispatch(
      updateCart({
        ...cart,
        products: [
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...(cart?.products?.length >= 1 ? cart?.products : []),
          {
            prod_cart_uid: new Date().getTime() + value?.amount,
            prod_obj: {
              description: "Custom Sale",
              name: value?.note,
              category: "custom sale",
              has_stock_count: false,
              buying_price_in_usd: 0,
              customization_option: [],
              price_in_usd:
                Number(value?.amount) / selectedCurrency?.rate_multiplier,
              in_stock: "",
              gallery: [],
              best_before: "",
              id_two: "#CUSTOMSALE-83937",
              product_id: "#CUSTOMSALE-83937",
              id: new Date().getTime() + value?.amount,
            },
            quantity: 1,
          },
        ],
      })
    );
    setValues({
      amount: "",
      note: "",
    });
    setCustom(false);
  };

  //Update Value On Key Press
  useEffect(() => {
    const keyHandler = (event: any) => {
      if (isCustomOpen) {
        // Only execute the following code if the textarea is focused
        if (!(textAreaRef.current === document.activeElement)) {
          const e = Number(event.key);
          if (
            ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].some(
              (data: string) => data === e?.toString()
            )
          ) {
            setValues((prev: any) => ({
              ...prev,
              amount:
                prev?.amount?.toString()?.length < 10
                  ? prev?.amount + e?.toString()
                  : prev?.amount,
            }));
          }
          if (event.key && event.key === ".") {
            setValues((prev: any) => ({
              ...prev,
              amount:
                prev?.amount?.toString()?.length <= 0
                  ? "0."
                  : prev?.amount + ".",
            }));
          }
          if (event.key && event.key === "Backspace") {
            setValues((prev: any) => ({
              ...prev,
              amount:
                prev?.amount?.toString()?.length >= 1
                  ? prev?.amount?.toString()?.slice(0, -1)
                  : "",
            }));
          }
        }
        if ((event.key && event.key === "Enter") || event.keyCode === 13) {
          value?.note && handleSubmit();
        }
      }
    };
    document.addEventListener("keyup", keyHandler, true);
    return () => document.removeEventListener("keyup", keyHandler, true);
  });

  //component
  return (
    <AnimatePresence>
      {isCustomOpen && (
        <motion.form
          initial={{ top: "100%", bottom: "-100%", y: 0 }}
          exit={{ top: "100%", bottom: "-100%", y: 0 }}
          animate={{ y: isCustomOpen ? 0 : "100%", top: 0, bottom: 0 }}
          transition={{ duration: 0.15 }}
          onSubmit={handleSubmit}
          className={`h-full bg-black-800/20 fixed md:absolute overflow-hidden backdrop-blur-sm
           bottom-0  left-0 right-0 p-2 md:p-4 flex items-end`}
        >
          <div
            ref={mdoalRef}
            className="h-fit w-full bg-white dark:bg-black-700 rounded-2xl md:rounded-lg flex flex-col
           justify-between items-center pt-6 overflow-hidden dark:border dark:border-black-600"
          >
            <div
              className="mt-4 h-6 w-full flex justify-center items-center text-2xl 
            font-extrabold text-slate-700 dark:text-black-200 whitespace-nowrap truncate overflow-hidden"
            >
              {selectedCurrency?.symbol}&nbsp;
              {value?.amount}
              {value?.amount?.length <= 0 && !value?.amount?.includes(".")
                ? "0.00"
                : value?.amount?.length > 0 &&
                  !value?.amount?.includes(".") &&
                  value?.amount !== "0"
                ? ".00"
                : ""}
            </div>

            {/**Note */}
            <div className="mt-4 w-4/5 h-fit flex justify-center px-4">
              <input
                ref={textAreaRef}
                type="text"
                onChange={(e) => {
                  setValues((prev: any) => ({ ...prev, note: e.target.value }));
                }}
                value={value?.note}
                required
                name="custom_sale_note"
                id="custom_sale_note"
                placeholder="Note ..."
                autoComplete="off"
                className="w-full h-14 border-0 border-b-2 border-dashed bg-inherit border-slate-300 dark:border-black-500
              focus:border-cyan-750 focus:ring-0 resize-none text-xs text-slate-700 dark:text-black-300
               dark:placeholder:text-black-400 placeholder:text-slate-500 transition-all text-center"
              />
            </div>

            {/**Keys */}
            <div className="mt-4 w-full h-fit p-4">
              <div className="h-fit w-full bg-slate-200 dark:bg-black-700 grid grid-cols-3 gap-[2px] overflow-hidden">
                {[
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "0",
                  ".",
                  "B",
                ]?.map((cha: any) => {
                  return (
                    <button
                      type="button"
                      onClick={() => {
                        if (cha === "C") {
                          setValues((prev: any) => ({ ...prev, amount: "" }));
                        } else if (cha === "B") {
                          setValues((prev: any) => ({
                            ...prev,
                            amount:
                              prev?.amount?.toString()?.length >= 1
                                ? prev?.amount?.toString()?.slice(0, -1)
                                : "",
                          }));
                        } else {
                          if (cha === ".") {
                            setValues((prev: any) => ({
                              ...prev,
                              amount:
                                prev?.amount?.toString()?.length <= 0
                                  ? "0" + cha
                                  : prev?.amount + cha,
                            }));
                          } else {
                            setValues((prev: any) => ({
                              ...prev,
                              amount:
                                prev?.amount?.toString()?.length < 10
                                  ? prev?.amount + cha
                                  : prev?.amount,
                            }));
                          }
                        }
                      }}
                      key={cha}
                      className="col-span-1 h-12 hover:text-cyan-750 transition-all flex items-center justify-center
                     text-base font-semibold text-slate-700 dark:text-black-200 select-none cursor-pointer focus:outline-none
                     bg-white dark:bg-black-800"
                    >
                      {cha === "B" ? (
                        <TbArrowNarrowLeft className="text-xl" />
                      ) : (
                        cha
                      )}
                    </button>
                  );
                })}
              </div>
            </div>

            {/**Controls  */}
            <div className="grid grid-cols-2 h-14 w-full overflow-hidden">
              <button
                onClick={() => setCustom(false)}
                type="button"
                className="h-full col-span-1 text-xs uppercase transition-all border-t border-slate-200
               dark:border-black-500 font-bold text-cyan-800 bg-white dark:bg-black-800
                focus:outline-none hover:opacity-80"
              >
                cancel
              </button>
              <button
                type="submit"
                className="h-full col-span-1 text-xs uppercase  font-bold text-white bg-cyan-800 
                 focus:outline-none hover:bg-cyan-700 transition-all"
              >
                add
              </button>
            </div>
          </div>
        </motion.form>
      )}
    </AnimatePresence>
  );
};

export default CustomSale;
