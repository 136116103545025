import { FC, useEffect, useState, useRef } from "react";
import { TbArrowNarrowLeft } from "react-icons/tb";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { motion, AnimatePresence } from "framer-motion";
//import { useSelector } from "react-redux";
//import { org } from "../../Firebase/Firestore_Func";
//import { RootState } from "../../Redux/store";
//import { decrypt } from "../../Custom Functions/Functions";

type Props = {
  showAuthorize: boolean;
  setAuthorize: any;
  passedAuthFunc: any;
  showReason?: boolean;
  reason?: any;
  setReason?: any;
};

const Authorize: FC<Props> = ({
  showAuthorize,
  setAuthorize,
  passedAuthFunc,
  reason,
  setReason,
  showReason,
}) => {
  const [typedValue, setValue] = useState<string>("");
  const auth_data = useSelector(
    (state: RootState) => state.SettingsData.auth_data
  );
  // const local_ref = useSelector(
  //   (state: RootState) => state?.SettingsData.local_ref
  // );
  const [changeBorder, setBorder] = useState<boolean>(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  //Auth Fucnt
  const auth = (e: any) => {
    const pass = e;
    passedAuthFunc(pass);
  };

  //Handle Submit
  const handleSubmit = () => {
    // console.log(decrypt(org, local_ref)?.toString()?.toLowerCase());
    if (
      auth_data?.new
        ? auth_data?.new === typedValue?.toString()?.toLowerCase()
        : true
    ) {
      setBorder(false);
      auth(auth_data?.new === typedValue?.toString()?.toLowerCase());
      setValue("");
    } else {
      setBorder(true);
    }
  };

  //Disable Border Alert{
  useEffect(() => {
    const timer: any = setTimeout(() => {
      if (changeBorder) {
        setBorder(false);
      }
      return clearTimeout(timer);
    }, 3000);
  }, [changeBorder]);

  //Update Value On Key Press
  useEffect(() => {
    const keyHandler = (event: any) => {
      if (showAuthorize) {
        // Only execute the following code if the textarea is focused
        if (!(textAreaRef.current === document.activeElement)) {
          const e = Number(event.key);
          if (
            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].some((data: number) => data === e)
          ) {
            setValue((prev: any) => (prev?.length < 8 ? prev + e : prev));
          }
          if (event.key && event.key === "Backspace") {
            setValue((prev: any) =>
              prev?.length >= 1 ? prev?.slice(0, -1) : ""
            );
          }
        }
        if ((event.key && event.key === "Enter") || event.keyCode === 13) {
          handleSubmit();
        }
      }
    };
    document.addEventListener("keyup", keyHandler, true);
    return () => document.removeEventListener("keyup", keyHandler, true);
  });

  //Shorcut Event Listener =======
  useEffect(() => {
    const cancelAuthShortcut = (e: any) => {
      if (showAuthorize) {
        if (e.key?.toString() === "Escape") {
          setAuthorize(false);
          setReason && setReason("");
          setValue("");
        }
      }
    };
    window.addEventListener("keydown", cancelAuthShortcut, true);
    return () =>
      window.removeEventListener("keydown", cancelAuthShortcut, true);
  });

  //Component
  return (
    <AnimatePresence>
      {showAuthorize && (
        <motion.div
          initial={{ scale: 0.5, top: "100%", bottom: "-100%", y: 0 }}
          exit={{ scale: 0.5, top: "100%", bottom: "-100%", y: 0 }}
          animate={{
            scale: 1,
            y: showAuthorize ? 0 : "100%",
            top: 0,
            bottom: 0,
            position: "fixed",
          }}
          transition={{ duration: 0.2 }}
          className={`fixed left-0 right-0 select-none z-[999999] 
          flex justify-center items-center bg-light-bg backdrop-blur-sm`}
        >
          <div className="w-full md:w-[23rem] h-full md:h-fit bg-white md:rounded-xl box-shadow-one p-4 py-10 relative">
            <div className="w-full h-full overflow-hidden flex flex-col space-y-8 items-center justify-center">
              <div className="w-full flex items-center justify-center space-x-3">
                <span className="text-sm text-slate-700 font-bold uppercase">
                  {changeBorder
                    ? "Incorrect Passcode"
                    : "Authorization required"}
                </span>
              </div>

              <div className="flex justify-between items-center gap-4 w-fit">
                {[...Array(8)]
                  ?.map((item: any, index: number) => item || 1 + index)
                  ?.map((item: number) => {
                    return (
                      <div
                        key={item}
                        className={`h-4 w-4 rounded-full border-2 border-slate-300 ${
                          typedValue?.length >= item
                            ? "bg-cyan-800"
                            : "bg-slate-50"
                        } transition-all duration-200`}
                      ></div>
                    );
                  })}
              </div>

              <div className="grid grid-cols-4 gap-4 w-fit h-fit">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "C", "B"]?.map((cha: any) => {
                  return (
                    <button
                      onClick={() => {
                        if (cha === "C") {
                          setValue("");
                        } else if (cha === "B") {
                          setValue((prev: any) =>
                            prev?.length >= 1 ? prev?.slice(0, -1) : ""
                          );
                        } else {
                          setValue((prev: any) =>
                            prev?.length < 8 ? prev + cha : prev
                          );
                        }
                      }}
                      key={cha}
                      className="col-span-1 h-12 w-12 rounded border-2 border-slate-300 hover:bg-slate-50 transition-all
                   flex items-center justify-center text-base text-slate-700 select-none cursor-pointer
                   focus:outline-none"
                    >
                      {cha === "B" ? (
                        <TbArrowNarrowLeft className="text-xl" />
                      ) : (
                        cha
                      )}
                    </button>
                  );
                })}
              </div>

              {showReason && (
                <textarea
                  ref={textAreaRef}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                  required
                  value={reason}
                  name="void_reason"
                  id="void_reason"
                  placeholder="Reason ..."
                  maxLength={100}
                  className={`w-[15rem] h-16 rounded-sm border-2 border-dashed border-slate-300 focus:border-cyan-750 focus:ring-0 outline-none
               text-xs text-slate-600 placeholder:text-slate-400 resize-none`}
                ></textarea>
              )}

              <div className="w-[15.25rem] grid grid-cols-2 gap-4">
                <button
                  onClick={() => {
                    setAuthorize(false);
                    setReason && setReason("");
                    setValue("");
                  }}
                  className="h-10 col-span-1 bg-slate-50 text-cyan-800 text-[0.65rem] font-bold uppercase
              hover:bg-white transition-all outline-none focus:outline-none  rounded
               flex items-center justify-center space-x-2 border-2 border-cyan-800"
                >
                  <span>cancel</span>
                </button>
                <button
                  onClick={() => {
                    handleSubmit();
                  }}
                  className="h-10 col-span-1 bg-cyan-800 text-white text-[0.65rem] font-bold uppercase
              hover:bg-cyan-700 transition-all outline-none focus:outline-none rounded
               flex items-center justify-center space-x-2"
                >
                  <span>authorize</span>
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Authorize;
