import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { retriveObjectLocal } from "./SettingsSlice";
import { crypt } from "../../Custom Functions/Functions";

//Get Theme From Local Storage ==============
const stock_orders_date = localStorage.getItem("stock_orders_date");

interface InitialStateType {
  inventory_data: any[];
  stock_orders: any[];
  vendors: any[];
  stock_orders_date: any;
  categories: any[];
  favourites: any[];
  productsLoadingState: boolean;
  evaluations: any[];
}

const initialState: InitialStateType = {
  inventory_data: retriveObjectLocal("inventory_data")|| [],
  stock_orders: retriveObjectLocal("stock_orders")||[],
  vendors: retriveObjectLocal("vendors")|| [],
  stock_orders_date: stock_orders_date
    ? JSON.parse(stock_orders_date)
    : { start: new Date().getTime() - 86400000, end: new Date().getTime() },
  categories:  retriveObjectLocal("categories")||  [],
  favourites: (() => {
    const data = window.localStorage.getItem("recentSearchPOS");
    return data ? JSON.parse(data) : [];
  })(),
  productsLoadingState: false,
  evaluations: retriveObjectLocal("evaluations"),
};

export const InventorySlice = createSlice({
  name: "Inventory",
  initialState,
  reducers: {
    changeProductsLaodingState: (state, action: PayloadAction<boolean>) => {
      state.productsLoadingState = action.payload;
    },
    loadInventoryData: (state, action: PayloadAction<any[]>) => {
      state.inventory_data = action.payload;
      window.localStorage.setItem(
        "inventory_data",
        JSON.stringify(
          crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
        )
      );
    },
    addStock_Orders: (state, action: PayloadAction<any[]>) => {
      state.stock_orders = action.payload;
      window.localStorage.setItem(
        "stock_orders",
        JSON.stringify(
          crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
        )
      );
    },
    addVendors: (state, action: PayloadAction<any[]>) => {
      state.vendors = action.payload;
      window.localStorage.setItem("vendors", JSON.stringify(
        crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
      ));
    },
    changeStockOrder: (state, action: PayloadAction<any>) => {
      state.stock_orders_date = action.payload;
    },
    updateCategories: (state, action: PayloadAction<any>) => {
      state.categories = action.payload;
      window.localStorage.setItem("categories", JSON.stringify(
        crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
      ));
    },
    setFavourites: (state, action: PayloadAction<any>) => {
      const index = state.favourites?.findIndex(
        (favourite) =>
          favourite.name === action.payload?.name &&
          favourite?.id === action.payload.id
      );
      if (index !== -1) {
        // remove item if it exists
        state.favourites?.splice(index, 1);
      } else {
        // add item if it does not exist
        state.favourites?.push(action.payload);
      }

      // Save updated favourites array to local storage
      localStorage.setItem("favourites", JSON.stringify(state.favourites));
    },
    loadEvaluations: (state, action: PayloadAction<any>) => {
      state.evaluations = action.payload;
      action.payload &&
        window.localStorage.setItem(
          "evaluations",
          JSON.stringify(
            crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
          )
        );
    },
  },
});

export const {
  loadInventoryData,
  addStock_Orders,
  addVendors,
  changeStockOrder,
  updateCategories,
  setFavourites,
  changeProductsLaodingState,
  loadEvaluations
} = InventorySlice.actions;

export default InventorySlice.reducer;
