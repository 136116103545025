import React from "react";
import { TbBeer, TbCoffee, TbUser } from "react-icons/tb";
import {
  RiCake3Line,
  RiCaravanLine,
  RiRestaurant2Line,
  RiShoppingBasketLine,
  RiStore3Line,
} from "react-icons/ri";
import { motion } from "framer-motion";
import { StepsType } from "./SignUp";

type Props = {
  signUpValues: any;
  setValues: any;
  setStep: any;
};

const BusinessType = ({ signUpValues, setValues, setStep }: Props) => {
  const options = [
    {
      name: "retail",
      description:
        "Offers a wide range of consumer goods, catering to various shopping needs.",
    },
    {
      name: "coffee shop",
      description:
        "Offers a variety of coffee drinks, teas, and often pastries or light snacks.",
    },
    {
      name: "restaurant",
      description:
        "Serves a diverse menu of prepared meals for dine-in or takeout.",
    },
    {
      name: "bar",
      description:
        "Offers alcoholic beverages and may also provide a selection of food and entertainment.",
    },
    {
      name: "food truck",
      description:
        "Mobile eatery serving a range of quick and delicious food options on the go.",
    },
    {
      name: "bakery",
      description:
        "Focuses on freshly baked goods, such as bread, cakes, pastries, and desserts.",
    },
    {
      name: "street vendor",
      description:
        "Sells food, beverages, or other goods from a portable stall or cart in public spaces.",
    },
    {
      name: "others",
      description:
        "For businesses that do not fit into any of the predefined categories.",
    },
  ];

  return (
    <motion.div
      initial={{ right: "100%", left: "-100%", opacity: 0.3 }}
      exit={{ right: "100%", left: "-100%", opacity: 0.3 }}
      animate={{ right: 0, left: 0, opacity: 1 ,zIndex:9999}}
      transition={{ duration: 0.3 }}
      className="mt-3 w-full md:w-fit h-fit min-h-[25.5rem] px-2 md:px-0 relative"
    >
      {/**Restaurant Type ================================== */}
      <div className="mt-4 w-full md:w-[24.5rem] grid grid-cols-3 place-content-center gap-3">
        {options.map((option: { name: string; description: string }) => {
          return (
            <div
              role="radio"
              onClick={() => {
                setValues((prev: any) => ({
                  ...prev,
                  type: option.name,
                }));

                setStep((prev: StepsType[]) => [
                  ...prev.map((data: StepsType) => ({
                    ...data,
                    active: data?.name === "additional" ? true : false,
                  })),
                ]);
              }}
              key={option.name}
              className={`col-span-1 h-24 p-2 overflow-hidden box-shadow-half rounded-xl text-2xl leading-3
               flex flex-col items-center justify-center gap-2 relative transition-all shrink-0
               ${
                 option.name === signUpValues.type
                   ? "text-white bg-cyan-800"
                   : "bg-white text-cyan-800"
               } transition-all cursor-pointer select-none hover:!shadow-md`}
            >
              {option.name === "coffee shop" ? (
                <TbCoffee />
              ) : option.name === "restaurant" ? (
                <RiRestaurant2Line />
              ) : option.name === "bar" ? (
                <TbBeer />
              ) : option.name === "retail" ? (
                <RiStore3Line />
              ) : option.name === "bakery" ? (
                <RiCake3Line />
              ) : option.name === "street vendor" ? (
                <TbUser />
              ) : option.name === "food truck" ? (
                <RiCaravanLine />
              ) : (
                <RiShoppingBasketLine />
              )}
              <span className="text-xss capitalize font-semibold whitespace-nowrap truncate text-center">
                {option.name}
              </span>
            </div>
          );
        })}
      </div>

      {/**Conset */}
      <div className="mt-5 flex items-center gap-2 text-xs text-slate-600 whitespace-nowrap">
        By continuing you are agreeing to our
        <a
          rel="noreferrer"
          target="_blank"
          href="https://firebasestorage.googleapis.com/v0/b/stall-d635a.appspot.com/o/stall_defaults%2FSTALL%20TERMS%20AND%20CONDITIONS.pdf?alt=media&token=5c01d9bb-af1a-4e3d-8da7-01e2eef2b546"
          className=" text-cyan-800 underline hover:opacity-80 transition-all font-medium whitespace-nowrap truncate cursor-pointer"
        >
          terms and conditions
        </a>
      </div>
    </motion.div>
  );
};

export default BusinessType;
