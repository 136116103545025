import React from "react";
import { TbDownload, TbTrash } from "react-icons/tb";
import DatePicker from "../../Components/Date Picker/DatePicker";
import { changeSalesDate } from "../../Redux/Slices/SalesSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { RiFilter3Line, RiSearch2Line } from "react-icons/ri";
import { Select } from "@mantine/core";

type Props = {
  setSort: any;
  sortBy: any;
  setView: any;
  currentView: any;
  markedArray: any[];
  setActionPanel: any;
  setSearch: any;
  searchValue: string;
  setPagination: any;
  setFilters: any;
};

const TopNav = ({
  setSort,
  sortBy,
  setView,
  currentView,
  markedArray,
  setActionPanel,
  setFilters,
  setSearch,
  searchValue,
  setPagination,
}: Props) => {
  const sales_date = useSelector((state: RootState) => state.Sales.sales_date);
  const dispatch = useDispatch();

  //Component
  return (
    <div className="h-fit w-full grid grid-cols-3 lg:grid-cols-6 gap-4">
      <div className="col-span-3 lg:col-span-2 h-12 flex items-center space-x-2">
        {markedArray?.length >= 1 && currentView === "parked sales" && (
          <button
            onClick={() => {
              setActionPanel(true);
            }}
            className="h-12 w-12 flex items-center justify-center  space-x-2 rounded-sm border border-slate-300
             hover:border-red-800/50 bg-inherit font-semibold uppercase text-xs text-slate-600 hover:text-red-750 outline-none 
          focus:outline-none hover:opacity-80 hover:bg-red-50 transition-all"
          >
            <TbTrash className="text-xl" />
          </button>
        )}
        {markedArray?.length >= 1 && currentView === "completed sales" && (
          <button
            onClick={() => {
              console.log("hi");
            }}
            className="h-12 w-12 flex items-center justify-center 
          space-x-2 rounded-sm border border-slate-300 hover:border-red-800/50 bg-white font-semibold uppercase 
          text-xs text-slate-600 hover:text-red-750 outline-none 
          focus:outline-none hover:opacity-80 hover:bg-red-50 transition-all"
          >
            <TbDownload className="text-xl" />
          </button>
        )}
        <div
          className={`${
            markedArray?.length >= 1 && currentView === "parked sales"
              ? "w-[calc(100%-7rem)]"
              : "w-full"
          } relative h-fit overflow-hidden flex items-center gap-2`}
        >
          {/*Quick Search ====================== */}
          <input
            onChange={(e) => {
              setSearch(e.target.value?.toString());
              setPagination({ start: 0, end: 30 });
            }}
            value={searchValue}
            type="search"
            autoComplete="off"
            name="sale_search"
            id="sale_search"
            placeholder="Quick Search ..."
            className="h-12 w-full bg-white dark:bg-black-900 rounded-lg md:rounded border border-slate-300 dark:border-black-600
            font-medium  px-10 pl-3 focus:ring-0 focus:border-cyan-800/50 text-xs text-slate-500 dark:text-black-200
                placeholder:text-slate-400 dark:placeholder:text-black-400 focus:!border-cyan-700 transition-all"
          />
          <RiSearch2Line className="absolute right-3 top-4 text-base text-slate-600 dark:text-black-300" />
        </div>
        <button
          onClick={() => {
            setFilters(true);
          }}
          className="h-12 w-12 min-w-[3rem] flex md:hidden items-center justify-center space-x-2 rounded-lg md:rounded font-semibold uppercase text-2xl text-slate-600
             dark:text-black-200 bg-white dark:bg-black-900 hover:text-red-750 outline-none focus:outline-none border border-slate-300
              dark:border-black-600 hover:text-cyan-800 transition-all"
        >
          <RiFilter3Line className="text-xl" />
        </button>
      </div>

      {/**Date Picker ==================================== */}
      <DatePicker
        dates={sales_date}
        additionalStyles={`h-12 w-full bg-white dark:bg-black-900 rounded border border-slate-300
        dark:border-black-600 text-xs text-slate-500 dark:text-black-200 font-medium px-2`}
        changeDate={(e: any) => {
          dispatch(changeSalesDate(e));
        }}
        parentWidth="hidden md:flex col-span-3 md:col-span-2 lg:col-span-2"
      />

      <div
        className="rounded h-12 col-span-3 md:col-span-1 bg-white dark:bg-black-900 relative 
           border border-slate-300 dark:border-black-600 hover:border-cyan-800/50 transition-all
           hidden md:flex items-center justify-between px-3 text-xxs text-slate-600
            font-semibold cursor-pointer group"
      >
        <Select
          classNames={{
            input: `text-xxs text-slate-600 font-semibold uppercase focus:ring-0 border-none px-0 w-full bg-inherit`,
            root: `text-xxs text-slate-600 font-semibold uppercase focus:ring-0 border-none px-0 w-full`,
            item: `selected:bg-cyan-800 text-xxs text-slate-600 font-semibold uppercase`,
          }}
          placeholder={currentView}
          transitionProps={{
            transition: "pop-top-left",
            duration: 80,
            timingFunction: "ease",
          }}
          onChange={(e) => {
            setView(e);
            window.localStorage.setItem("salesView", JSON.stringify(e));
          }}
          data={[
            { value: "completed sales", label: "completed sales" },
            { value: "parked sales", label: "parked sales" },
          ]}
        />
      </div>

      <div
        className="rounded h-12 col-span-3 md:col-span-1 bg-white dark:bg-black-900 relative 
           border border-slate-300 dark:border-black-600 hover:border-cyan-800/50 transition-all
           hidden lg:flex items-center justify-between px-3 text-xxs text-slate-600
            font-semibold cursor-pointer group"
      >
        <Select
          classNames={{
            input: `text-xxs text-slate-600 font-semibold uppercase focus:ring-0 border-none px-0 w-full bg-inherit`,
            root: `text-xxs text-slate-600 font-semibold uppercase focus:ring-0 border-none px-0 w-full`,
            item: `selected:bg-cyan-800 text-xxs text-slate-600 font-semibold uppercase`,
          }}
          placeholder={"Sort by " + sortBy}
          transitionProps={{
            transition: "pop-top-left",
            duration: 80,
            timingFunction: "ease",
          }}
          onChange={(e) => {
            setSort(e);
            window.localStorage.setItem("salesSort", JSON.stringify(e));
          }}
          data={[
            { value: "date", label: "Date" },
            { value: "total", label: "Total" },
            { value: "status", label: "status" },
            { value: "sale_channel", label: "Sales Channel" },
            { value: "payment method", label: "Payment Method" },
          ]}
        />
      </div>
    </div>
  );
};

export default TopNav;
