import { FC, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { numberWithSpaces } from "../../../Reusable Functions/Functions";
import thubnail_holder from "../../../Assets/no_preview_card.png";
import {
  customizeProduct,
  openPreview,
  updateCart,
} from "../../../Redux/Slices/OrderSlice";
import { TbInfinity } from "react-icons/tb";
import { motion } from "framer-motion";
import Status from "./Status";
import useLongPress from "../../../Custom-Hooks/useLongPress";

type Props = {
  prod: any;
};

const Product: FC<Props> = ({ prod }): any => {
  const intervalRef = useRef<NodeJS.Timeout | null | any>(null);
  const cart = useSelector((state: RootState) => state.OrderData.cart);
  const dispatch = useDispatch();
  const selectedCurrency = useSelector(
    (state: RootState) => state.SettingsData.selectedCurrency
  );

  //Add Product To Cart =============================
  const addToCart = (product: any) => {
    if (
      Number(product?.in_stock > 0 && product?.has_stock_count) ||
      !product?.has_stock_count
    ) {
      const prod = {
        customization: [],
        prod_cart_uid: product?.id,
        prod_obj: product,
      };

      //Check if selected product is on the card
      const prodIsOnCart =
        cart?.products?.find(
          (data: any) => data?.prod_cart_uid === prod?.prod_cart_uid
        ) || {};

      ///Update Cart
      dispatch(
        updateCart({
          ...cart,
          products: [
            ...(cart?.products
              ? cart?.products?.filter(
                  (data: any) => data?.prod_cart_uid !== prod?.prod_cart_uid
                )
              : []),
            {
              ...prod,
              ...prodIsOnCart,
              quantity: (prodIsOnCart?.quantity || 0) + 1,
            },
          ],
        })
      );
    }
  };

  //Add To addToPreview =================================
  const addToPreview = (prod: any) => {
    if (
      Number(prod?.in_stock > 0 && prod?.has_stock_count) ||
      !prod?.has_stock_count
    ) {
      dispatch(
        customizeProduct({
          customization: [],
          prod_cart_uid: prod?.id + new Date().getTime(),
          prod_obj: prod,
          quantity: 1,
        })
      );
      dispatch(openPreview(true));
    }
  };

  //Handle Long Press ==================
  const {onMouseDown,onTouchStart,pressDuration} = useLongPress(
    () =>
      (Number(prod?.in_stock > 0 && prod?.has_stock_count) ||
        !prod?.has_stock_count) &&
      addToPreview(prod),
    { delay: 300, threshold: 700 }
  );

  //Component
  return (
    <motion.div
      ref={intervalRef}
      title={
        Number(prod?.in_stock) <= 0 && prod?.has_stock_count
          ? "Please add stock before selling"
          : ""
      }
      //layoutId={prod?.id}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      onClick={() => {
        addToCart(prod);
      }}
      {...{onMouseDown,onTouchStart}}
      className={`flex-col col-span-1 h-16 min-h-[11.5rem] transition-all select-none cursor-pointer group
     bg-white dark:bg-black-900 rounded-lg border-4 border-white dark:border-black-900 ${
       Number(prod?.in_stock) <= 0 && prod?.has_stock_count
         ? "!cursor-not-allowed"
         : ""
     } relative overflow-hidden hover:shadow-xl hover:border-2 hover:border-cyan-600 shadow`}
    >
      <div className="h-2/3 w-full overflow-hidden relative bg-slate-50 dark:bg-black-800 shrink-0">
        <img
          onError={(e) => {
            e.currentTarget.src = thubnail_holder;
            e.currentTarget.style.padding = "16px 32px 16px 32px"
          }}
          src={prod?.thumbnail || thubnail_holder}
          alt="prod_preview"
          className={`overflow-hidden object-fit object-center object-cover w-full h-full rounded-t-[inherit]
          pointer-events-none group-hover:scale-105 transition-all !bg-inherit ${
            (!prod?.thumbnail) && "px-8 py-4"
          }`}
        />
      </div>

      {/**Name && Instock plus pricing */}
      <div className="w-full h-1/3 p-2 flex flex-col justify-center gap-1">
        <div className="flex justify-between gap-1 w-full overflow-hidden">
          <div className="flex items-center gap-1 w-full shrink-0">
            <Status prod={prod} />
            <span
              className="text-xss text-slate-700 dark:text-slate-200 whitespace-nowrap
           truncate font-semibold capitalize"
            >
              {prod?.name}
            </span>
          </div>
          <span
            className="text-xxs text-slate-600 dark:text-slate-300 whitespace-nowrap
               truncate uppercase font-medium hidden"
          >
            {prod?.has_stock_count ? (
              Number(prod?.in_stock)?.toFixed(
                prod?.measurement_units?.decimal_precision || 0
              )
            ) : (
              <TbInfinity className="text-lg" />
            )}
            {prod?.measurement_units?.symbol}
          </span>
        </div>
        <div className="flex item-center w-full gap-1">
          <span
            className={`text-xs font-bold text-stone-700 dark:text-slate-200 whitespace-nowrap
            truncate text-right shrink-0 ${
              prod?.has_discounted_price && "line-through opacity-80"
            }`}
          >
            {selectedCurrency?.symbol}&nbsp;
            {numberWithSpaces(
              (
                selectedCurrency?.rate_multiplier * Number(prod?.price_in_usd)
              ).toFixed(2)
            )}
          </span>
          {prod?.has_discounted_price && (
            <span
              className={`text-xs font-bold text-stone-700 dark:text-slate-200 whitespace-nowrap
          truncate text-right shrink-0`}
            >
              {selectedCurrency?.symbol}&nbsp;
              {numberWithSpaces(
                (
                  selectedCurrency?.rate_multiplier *
                  Number(prod?.discount_price)
                ).toFixed(2)
              )}
            </span>
          )}
        </div>
      </div>
      {/**Indicate long Press */}
      <div
        style={{ height: `${(pressDuration / 1000) * 100}%` }}
        className="absolute bottom-0 left-0 right-0 bg-cyan-800/30 backdrop-blur-sm transition-all"
      >
      </div>
    </motion.div>
  );
};

export default Product;
