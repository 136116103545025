import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { crypt} from "../../Custom Functions/Functions";
import { retriveObjectLocal } from "./SettingsSlice";

const initialLocation = window.localStorage.getItem("locationPath") || "Stall";

interface InitialStateType {
  user: any;
  saved_workspaces: any[];
  routeLocation: any;
  current_workspace: string | any;
}

const initialState: InitialStateType = {
  user: retriveObjectLocal("bs-sessions-persit"),
  saved_workspaces: retriveObjectLocal("saved_workspaces"),
  routeLocation: initialLocation,
  current_workspace: retriveObjectLocal("current_workspace"),
};

export const UserSlice = createSlice({
  name: "UserInfo",
  initialState,
  reducers: {
    updateUserData: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      window.localStorage.setItem(
          "bs-sessions-persit",
          JSON.stringify(
            crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
          )
        );
    },
    saveWorkSpaces: (state, action: PayloadAction<any>) => {
      state.saved_workspaces = action.payload;
     window.localStorage.setItem(
          "saved_workspaces",
          JSON.stringify(
            crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
          )
        );
    },
    changeLocation: (state, action: PayloadAction<any>) => {
      state.routeLocation = action.payload;
    },
    setCurrent_workspace: (state, action: PayloadAction<string | null>) => {
      state.current_workspace = action.payload;
      action.payload &&
        window.localStorage.setItem(
          "current_workspace",
          JSON.stringify(
            crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
          )
        );
    },
  },
});

export const {
  updateUserData,
  saveWorkSpaces,
  changeLocation,
  setCurrent_workspace,
} = UserSlice.actions;

export default UserSlice.reducer;
