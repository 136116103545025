/* eslint-disable no-unsafe-optional-chaining */
import no_gallery from "../../../Assets/no_preview_card.png";
import empty_cart from "../../../Assets/empty_cart.png";
import { FC, useMemo } from "react";
import { numberWithSpaces } from "../../../Reusable Functions/Functions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import {
  customizeProduct,
  openPreview,
  updateCart,
} from "../../../Redux/Slices/OrderSlice";

type Props = {
  searchValue: any;
  opeFloat: any;
};

const ItemsList: FC<Props> = ({ searchValue, opeFloat }) => {
  const dispatch = useDispatch();
  const cart = useSelector((state: RootState) => state.OrderData.cart);
  const selectedCurrency = useSelector(
    (state: RootState) => state.SettingsData.selectedCurrency
  );
  const filteredList: any = useMemo(() => {
    return [...(cart?.products?.length >= 1 ? cart?.products : [])]?.filter(
      (data: any) =>
        data?.prod_obj?.name
          ?.toString()
          ?.toLowerCase()
          ?.replace(/\s/gim, "")
          ?.includes(
            searchValue?.toString()?.toLowerCase()?.replace(/\s/gim, "")
          )
    );
  }, [cart, searchValue]);
  const cash_float = useSelector((state: RootState) => state.Sales.cash_float);
  const openFloat = useMemo(() => {
    return cash_float?.length >= 1
      ? cash_float?.find((data: any) => data.status === "open")
      : null;
  }, [cash_float]);
  const paymnet_methods = useSelector(
    (state: RootState) => state.SettingsData.payment_methods
  );

  // addToPreview =================================
  const addToPreview = (prod: any) => {
    dispatch(customizeProduct(prod));
    dispatch(openPreview(true));
  };

  //Remove item from cart ====================
  const deleteFromCart = (id: string) => {
    dispatch(
      updateCart({
        ...cart,
        products: [
          ...(cart?.products
            ? cart?.products?.filter((data: any) => data?.prod_cart_uid !== id)
            : []),
        ],
      })
    );
  };

  //Component ==================================
  return (
    <ul
      className="w-full h-full overflow-hidden overflow-y-scroll
       flex flex-col gap-2 select-none no-scrollbar no-scrollbar::webkit-scrollbar pt-2"
    >
      {filteredList && filteredList?.length >= 1 ? (
        filteredList?.map((prod: any) => {
          return (
            <li
              key={prod?.prod_cart_uid}
              className={`w-full h-[3.5rem] bg-light-bg dark:bg-black-800 rounded border border-slate-200 dark:border-black-600 
               p-1 pr-2 flex items-center justify-between gap-2 transition-all cursor-pointer shrink-0 overflow-hidden relative group`}
            >
              <img
                onError={(e) => {
                  e.currentTarget.src = no_gallery;
                }}
                src={
                  prod?.prod_obj?.thumbnail
                    ? prod?.prod_obj?.thumbnail
                    : no_gallery
                }
                alt="img"
                className="h-full w-12 rounded  overflow-hidden object-center object-fit object-cover
                border border-slate-200 dark:border-black-600 bg-slate-50 dark:bg-black-700 shrink-0"
              />
              <div className="h-full w-[calc(100%-3.5rem)] flex flex-col justify-center">
                <div className="flex items-center justify-between gap-2">
                  <span
                    className="text-xxs font-bold text-slate-700 dark:text-black-200
                 whitespace-nowrap w-[70%] overflow-hidden text-ellipsis uppercase"
                  >
                    {prod?.prod_obj?.name}
                  </span>
                  <span
                    className="text-xxs font-semibold text-slate-600 dark:text-black-300
                 whitespace-nowrap w-[30%] overflow-hidden text-ellipsis text-right"
                  >
                    x{" "}
                    {Number(prod?.quantity)?.toFixed(
                      prod?.prod_obj?.measurement_units?.decimal_precision
                    )}{" "}
                    {prod?.prod_obj?.measurement_units?.symbol}
                  </span>
                </div>
                <div className="flex items-center justify-between space-x-2">
                  <div
                    className="flex items-center h-fit gap-2 w-[70%] text-xxs uppercase font-bold text-slate-600
                   dark:text-black-300"
                  >
                    <span
                      className={`truncate whitespace-nowrap shrink-0 ${
                        prod?.prod_obj?.has_discounted_price && "hidden"
                      }`}
                    >
                      {selectedCurrency?.symbol}&nbsp;
                      {numberWithSpaces(
                        (
                          selectedCurrency?.rate_multiplier *
                          Number(prod?.prod_obj?.price_in_usd) *
                          Number(prod?.quantity)
                        ).toFixed(2)
                      )}
                    </span>
                    {prod?.prod_obj?.has_discounted_price && (
                      <span className={`truncate whitespace-nowrap shrink-0`}>
                        {selectedCurrency?.symbol}&nbsp;
                        {numberWithSpaces(
                          (
                            selectedCurrency?.rate_multiplier *
                            Number(prod?.prod_obj?.discount_price) *
                            Number(prod?.quantity)
                          ).toFixed(2)
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="absolute right-0 flex flex-col shrink-0 h-full w-0 group-hover:w-16 bg-inherit border-l border-inherit
               overflow-hidden transition-all">
                <button
                  onClick={() => addToPreview(prod)}
                  disabled={prod?.prod_obj?.description === "Custom Sale"}
                  className="h-1/2 w-full text-xxs uppercase font-semibold text-cyan-700 hover:opacity-70 transition-all 
                  border-b border-inherit"
                >
                  update
                </button>
                <button
                  onClick={() => deleteFromCart(prod?.prod_cart_uid)}
                  className="h-1/2 w-full text-xxs uppercase font-semibold text-red-600 hover:opacity-70 transition-all"
                >
                  delete
                </button>
              </div>
            </li>
          );
        })
      ) : (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <img
            src={empty_cart}
            alt="empty_cart"
            className="h-12 w-12 overflow-hidden object-fit object-center
           object-cover object-fit opacity-70"
          />
          {(openFloat &&
            paymnet_methods?.find((data: any) => data?.name === "cash")
              ?.status === "active") ||
          paymnet_methods?.find((data: any) => data?.name === "cash")
            ?.status !== "active" ? (
            <p className="mt-3 text-xs text-center text-slate-400 dark:text-black-400 font-medium">
              Tap, search or scan to add to order
            </p>
          ) : (
            <p className="mt-3 text-xs text-center text-slate-400 dark:text-black-400 font-medium">
              If accepting cash payments make sure at least <br /> one session
              is open{" "}
              <span
                onClick={() => {
                  opeFloat();
                }}
                className="text-cyan-700 underline cursor-pointer opacity-80 transition-all"
              >
                here
              </span>
              .
            </p>
          )}
        </div>
      )}
    </ul>
  );
};

export default ItemsList;
