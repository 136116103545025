import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { RiEBike2Line, RiShipLine, RiStore3Line } from "react-icons/ri";
import { updateCart } from "../../../Redux/Slices/OrderSlice";
import { fulfillmentProviders } from "../../../Redux/Slices/SettingsSlice";

const Fulfillment = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state: RootState) => state.OrderData.cart);
  const fulfillment_providers = useSelector(
    (state: RootState) => state.SettingsData.fulfillment_providers
  );

  //Component =====================
  return (
    <div
      className="w-full h-11 rounded bg-slate-50 dark:bg-black-700 p-1 gap-1 border border-slate-300 
      dark:border-black-600 transition-all flex items-center justify-between overflow-hidden"
    >
      <div
        className="w-[calc(100%-7rem)] h-full grid grid-cols-3 divide-x divide-slate-200 dark:divide-black-500 rounded
         overflow-hidden bg-white dark:bg-black-800 border border-slate-200 dark:border-black-600"
      >
        {["collection", "delivery", "shipment"].map((type: string) => {
          return (
            <button
              title={type}
              disabled={
                !fulfillment_providers?.some(
                  (data: fulfillmentProviders) =>
                    data?.status === "active" &&
                    data?.type === type &&
                    data?.channels?.includes("pos")
                )
              }
              onClick={() => {
                const fulfiller = fulfillment_providers?.find(
                  (data: fulfillmentProviders) =>
                    data?.status === "active" &&
                    data?.type === type &&
                    data?.channels?.includes("pos")
                );
                dispatch(
                  updateCart({
                    ...cart,
                    total:
                      cart.total -
                      cart?.service_fee +
                      (Number(fulfiller?.fee_in_usd) || 0),
                    fulfillment_type: fulfiller?.type,
                    fulfillment_provider_id: fulfiller?.id,
                    service_fee: Number(fulfiller?.fee_in_usd) || 0,
                  })
                );
              }}
              type="button"
              key={type}
              className={`col-span-1 h-full flex items-center justify-center gap-1 text-xxs
                ${
                  cart?.fulfillment_type === type
                    ? "text-cyan-700"
                    : "text-slate-700 dark:text-black-300"
                }  font-semibold capitalize whitespace-nowrap truncate hover:opacity-70 transition-all
                 disabled:cursor-not-allowed disabled:opacity-80`}
            >
              {type === "shipment" ? (
                <RiShipLine className="text-sm" />
              ) : type === "delivery" ? (
                <RiEBike2Line className="text-sm" />
              ) : (
                <RiStore3Line className="text-sm" />
              )}{" "}
              <span className="hidden sm:flex">{type}</span>
            </button>
          );
        })}
      </div>
      <select
        title="Fulfillment Service Provider"
        onChange={(e) => {
          const fulfiller = e.target.value && JSON.parse(e.target.value);
          dispatch(
            updateCart({
              ...cart,
              total:
                cart.total -
                cart?.service_fee +
                (Number(fulfiller?.fee_in_usd) || 0),
              fulfillment_type: fulfiller?.type,
              fulfillment_provider_id: fulfiller?.id,
              service_fee: Number(fulfiller?.fee_in_usd) || 0,
            })
          );
        }}
        required
        name={cart?.fulfillment_type}
        id={cart?.fulfillment_type}
        className="h-full w-28 rounded text-xxs capitalize font-medium text-slate-700 dark:text-black-200
        bg-white dark:bg-black-800 border border-slate-200 dark:border-black-600 pt-1 focus:ring-0 focus:border-slate-300
         focus:text-cyan-700 transition-all"
      >
        {fulfillment_providers
          ?.filter(
            (item: fulfillmentProviders) =>
              item?.type === cart?.fulfillment_type &&
              item?.status === "active" &&
              item?.channels?.includes("pos")
          )
          ?.map((data: any) => {
            return (
              <option key={data?.name} value={JSON.stringify(data)}>
                {data?.name}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default Fulfillment;
