import { FC, useEffect } from "react";
import { onSnapshot } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../Redux/store";
import { customersRef } from "./Firestore_Func";
import { updateCustomers } from "../Redux/Slices/CustomersSlice";

const CustomersFirebase: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  //===================Fetch Data ============================

  // //Fetch Cash Float Data
  useEffect((): any => {
    return onSnapshot(customersRef,
      { includeMetadataChanges: true }, (snapshot: { docs: any[] }) => {
      dispatch(
        updateCustomers(
          snapshot.docs.map((doc: { data: () => any; id: any }) => ({
            ...doc.data(),
            id: doc.id,
          }))
        )
      );
    });
  }, [dispatch]);

  //Component
  return <></>;
};

export default CustomersFirebase;
