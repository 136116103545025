import { FC, useState, useMemo, useEffect } from "react";
import PaymentOptions from "./PaymentOptions";
import useClickOutside from "../../../Custom-Hooks/useOnClickOutsideRef";
import CustomerDetails from "./CustomerDetails";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { updateCart } from "../../../Redux/Slices/OrderSlice";
import { numberWithSpaces } from "../../../Reusable Functions/Functions";
import Fulfillment from "./Fulfillment";

type Props = {
  currentSlide: any;
  selectedMethod: any;
  selectMethod: any;
  setDetails: any;
  additional_details: any;
  openCheckout: any;
  setSlide: any;
  calcChange: any;
  change: any;
};

const CheckoutInfo: FC<Props> = ({
  currentSlide,
  selectedMethod,
  selectMethod,
  setDetails,
  additional_details,
  openCheckout,
  setSlide,
  calcChange,
  change,
}) => {
  const [tendered_amount, setAmount] = useState<any>(null);
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.UserInfo.user);
  const cart = useSelector((state: RootState) => state.OrderData.cart);
  const fetched_methods = useSelector(
    (state: RootState) => state.SettingsData.payment_methods
  );
  const selectedCurrency = useSelector(
    (state: RootState) => state.SettingsData.selectedCurrency
  );
  const paymentMethods = useMemo(() => {
    return fetched_methods?.filter(
      (method: any) =>
        method?.status === "active" &&
        (method?.currencies?.includes(selectedCurrency?.name) ||
          method?.currencies?.length >= 1)
    );
  }, [fetched_methods, selectedCurrency?.name]);
  const [openCurrencyDropDown, setCurrencyDropwDown] = useState<boolean>(false);
  const dropDownRef = useClickOutside(() => {
    setCurrencyDropwDown(false);
  });

  //Update Initial tendered Amount Value
  useEffect(() => {
    if (!tendered_amount) {
      setAmount(cart?.tendered_amount);
    }
  }, [cart?.tendered_amount, tendered_amount]);

  //Component ============================
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        dispatch(
          updateCart({
            ...cart,
            user: { name: user?.name, email: user?.email, id: user?.uid || "" },
            date: new Date().getTime(),
            customers_details: {
              name: additional_details?.customers_details?.name || "",
              "primary phone":
                additional_details?.customers_details?.["primary phone"] || "",
              email: additional_details?.customers_details?.email || "",
              address: additional_details?.customers_details?.address || "",
              id: additional_details?.customers_details?.id || "",
            },
            note: additional_details?.note || "",
            payment_method: selectedMethod?.name,
            origin: "pos",
          })
        );
        setSlide("pay");
        setAmount(null);
        e.currentTarget.reset();
      }}
      className={`absolute top-0 bottom-0 w-full bg-white dark:bg-black-900 flex flex-col gap-6  justify-between ${
        currentSlide === "info" ? "right-0 left-0" : "right-[200%] -left-[200%]"
      } transition-all duration-300 relative overflow-hidden p-4 sm:p-6`}
    >
      <div className="w-full h-[calc(100%-5rem)] flex flex-col gap-4 relative">
        {/**Payment Methods =============================== */}
        <PaymentOptions
          dropDownRef={dropDownRef}
          setCurrencyDropwDown={setCurrencyDropwDown}
          selectedMethod={selectedMethod}
          openCurrencyDropDown={openCurrencyDropDown}
          paymentMethods={paymentMethods}
          selectMethod={selectMethod}
        />

        {/**Order FulFullment Type ==================== */}
        <Fulfillment />

        {/**Change calculation */}
        {selectedMethod?.["requires tendered amount"] && (
          <div className="w-full flex flex-col space-y-4 items-center justify-between">
            <div
              className="flex w-full h-fit rounded bg-slate-50/50 dark:bg-black-700 border border-slate-300
                dark:border-black-600 hover:border-cyan-700/60 overflow-hidden transition-all font-medium"
            >
              <div
                className="w-28 h-10 px-4 text-xs text-slate-600 dark:text-black-300 font-semibold flex items-center
                     border-r border-slate-200 dark:border-black-500 whitespace-nowrap truncate"
              >
                {selectedCurrency?.symbol}&nbsp;
                {cart?.products?.length >= 1
                  ? numberWithSpaces(
                      (
                        selectedCurrency?.rate_multiplier *
                        (cart?.total + (cart?.tip_amount ?? 0))
                      ).toFixed(2)
                    )
                  : "0.0000"}
              </div>
              <div className="flex w-[calc(100%-7rem)] h-fit overflow-hidden">
                <input
                  required
                  onChange={(e) => {
                    setAmount(e.target.value);
                    calcChange(
                      Number(e.target.value) -
                        selectedCurrency?.rate_multiplier * cart?.total
                    );
                    dispatch(
                      updateCart({
                        ...cart,
                        cash: Number(e.target.value),
                        tendered_amount:
                          Number(e.target.value) /
                          selectedCurrency?.rate_multiplier,
                        change:
                          Number(e.target.value) -
                          selectedCurrency?.rate_multiplier * cart?.total,
                      })
                    );
                  }}
                  step="0.00001"
                  min={
                    isNaN(
                      selectedCurrency?.rate_multiplier *
                        (cart?.total + (cart?.tip_amount ?? 0))
                    )
                      ? 0
                      : Math.floor(
                          Number(
                            selectedCurrency?.rate_multiplier *
                              (cart?.total + (cart?.tip_amount ?? 0))
                          )
                        )
                  }
                  value={tendered_amount<=0?"":tendered_amount}
                  autoComplete="off"
                  type="number"
                  name="amount_recieved"
                  id="amount_recieved"
                  placeholder="Tendered cash ..."
                  className="w-[50%] h-10 rounded-sm rounded-r-none bg-inherit border-0 text-xs text-slate-700 dark:text-black-200 dark:placeholder:text-black-300
                    placeholder:text-slate-400 px-2 focus:ring-0 focus:border-0 focus:border-r focus:border-r-slate-200 transition-all border-r border-slate-200 dark:border-black-500"
                />
                <div
                  className="px-3 w-[50%] h-10 text-xs text-slate-600 dark:text-black-300 font-medium flex items-center justify-center
"
                >
                  {change <= 0 ? "Change" : numberWithSpaces(change.toFixed(2))}
                </div>
              </div>
            </div>
          </div>
        )}

        {/**Customer Details  =========================== */}
        <CustomerDetails
          setDetails={setDetails}
          additional_details={additional_details}
          selectedMethod={selectedMethod}
        />
      </div>

      {/**Controlls ========================================= */}
      {currentSlide === "info" && (
        <div className="h-14 grid grid-cols-2 absolute bottom-0 left-0 right-0">
          <button
            type="button"
            onClick={() => {
              openCheckout(false);
            }}
            className="h-full col-span-1 text-xs uppercase text-cyan-700 font-bold focus:outline-none bg-slate-50 dark:bg-black-700 hover:opacity-80 transition-all"
          >
            cancel
          </button>
          <button
            type="submit"
            title={
              selectedMethod?.method === "account" &&
              !additional_details?.customers_details?.email
                ? "Please select the customers account"
                : ""
            }
            disabled={
              selectedMethod?.["requires customer details"] &&
              !additional_details?.customers_details?.email
            }
            className="h-full col-span-1 text-xs uppercase text-white font-bold focus:outline-none
           bg-cyan-800 hover:opacity-80 transition-all disabled:opacity-80 disabled:cursor-not-allowed"
          >
            proceed
          </button>
        </div>
      )}
    </form>
  );
};

export default CheckoutInfo;
