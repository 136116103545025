export const topNav = [
  { name: "point of sale", path: "/", index: 0 },
  { name: "sales", path: "/sales", index: 1 },
  { name: "reports", path: "/reports", index: 2 },
  { name: "customers", path: "/customers", index: 3 },
  { name: "inventory", path: "/inventory", index: 4 },
  { name: "other channels", path: "/other_channels", index: 7 },
  { name: "apps & integrations", path: "/apps-integrations", index: 8 },
  { name: "campaigns", path: "/campaigns", index: 5 },
  { name: "settings", path: "/settings", index: 6 },
];

export const default_permissions = {
  index: 0,
  name: "",
  type: "default",
  permissions: {
    "can view point-of-sale and sale": true,
    "can view their sales/transactions": true,
    "can view all sales/transactions": true,
    "can make a refund": true,
    "can make an exchange": true,
    "can make a avoid": true,
    "can reprint invoices/reciept": true,
    "can view their overview sales resport": true,
    "can view all overview sales report": true,
    "can view customers report": true,
    "can view inventory report": true,
    "can build custom reports": true,
    "can download or print reports": true,
    "can view inventory": true,
    "can manage inventory (add/edit/delete) items": true,
    "can manage stock orders (place, receive, transfer,evaluate)": true,
    "can manage suppliers": true,
    "can view customers": true,
    "can manage customers (add/edit/delete) or manage accounts": true,
    "can manage campaigns and promotions": true,
    "can manage other portals like website and self service terminal": true,
    "manage admin settings like billing, payment methods,team etc": true,
    "can view systems logs like auth history and voids": true,
  },
};

export const payment_methods_template = {
  name: "",
  type: "",
  "account number": "",
  "account holder": "",
  "offline support": false,
  status: "",
  currencies: [],
  platforms: [],
  balance: 0,
  transactions: [],
  "requires tendered amount": false,
  "requires customer details": false,
};

export const demoAccounts = [
  {
    name: "cash",
    type: "physical",
    "account number": "",
    "routing number": "",
    "account holder": "",
    "offline support": true,
    status: "active",
    currencies: [],
    platforms: [],
    balance: 3443.56,
    transactions: [],
    "requires tendered amount": true,
    "requires customer details": false,
  },
  {
    name: "account",
    type: "digital",
    "account number": "",
    "account holder": "",
    "routing number": "",
    "offline support": true,
    status: "active",
    currencies: [],
    platforms: [],
    balance: 52390,
    transactions: [],
    "requires tendered amount": false,
    "requires customer details": true,
  },
  {
    name: "card",
    type: "digital",
    "account number": "90348989849389439",
    "routing number": "9054382",
    "account holder": "nd dosey",
    "offline support": false,
    status: "unavailable",
    currencies: [],
    platforms: [],
    balance: 0,
    transactions: [],
    "requires tendered amount": false,
    "requires customer details": true,
  },
  {
    name: "paypal",
    type: "digital",
    "account number": "",
    "routing number": "",
    "account holder": "",
    "offline support": false,
    status: "unavailable",
    currencies: [],
    platforms: [],
    balance: 0,
    transactions: [],
    "requires tendered amount": false,
    "requires customer details": true,
  },
  {
    name: "google pay",
    type: "digital",
    "account number": "",
    "routing number": "",
    "account holder": "",
    "offline support": false,
    status: "unavailable",
    currencies: [],
    platforms: [],
    balance: 0,
    transactions: [],
    "requires tendered amount": false,
    "requires customer details": true,
  },
  {
    name: "apple pay",
    type: "digital",
    "account number": "",
    "routing number": "",
    "account holder": "",
    "offline support": false,
    status: "unavailable",
    currencies: [],
    platforms: [],
    balance: 0,
    transactions: [],
    "requires tendered amount": false,
    "requires customer details": true,
  },
];

/*
Products should have the following:
Name -> Name of the product
id -> Product Unique ID
Actual_Level -> The Actual Stock Levels
Obtained_Level -> The outcome from the evaluation process
Difference -> The Difference Between the actual and the output
*/
export const stockEvaluationObj = {
  startDate: new Date().getTime(),
  endDate: "",
  duration: 0,
  location: "",
  products: [],
  additinal_notes: "",
  user: { name: "", email: "", uid: "" },
};

export const defaultReceiptOptions = [
  {
    name: "show logo",
    description:
      "display your company logo on both physical and digital receipts",
    value: true,
  },
  {
    name: "show notes",
    description: "display additional notes on receipts",
    value: false,
  },
  {
    name: "display address",
    description:
      "display your billing or shipping address on receipts using Mapbox",
    value: false,
  },
  {
    name: "display contact information",
    description: "display your contact information on receipts",
    value: false,
  },
  {
    name: "show barcodes",
    description: "display barcodes on receipts",
    value: false,
  },
  {
    name: "show qr codes",
    description: "display QR codes on receipts",
    value: false,
  },
  {
    name: "show salesperson name",
    description: "display the name of the salesperson on receipts",
    value: false,
  },
  {
    name: "show review link",
    description:
      "include a link for customers to leave a review on their receipts",
    value: false,
  },
  {
    name: "display promotional content",
    description: "display additional promotional content on receipts",
    value: false,
  },
];

export const allUsersInfo = [];