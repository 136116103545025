/* eslint-disable no-unsafe-optional-chaining */
import { FC, useEffect } from "react";
import { HiCheckCircle } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { numberWithSpaces } from "../../../Reusable Functions/Functions";
import Success from "./Success";
import { updateCart } from "../../../Redux/Slices/OrderSlice";
import Discount from "./Discount";
import Tip from "./Tip";
import { parkSale } from "../../../Firebase/Firestore_Func";

type Props = {
  currentSlide: any;
  setSlide: any;
  showProcessDone: any;
  setInvoiceOptions: any;
  invoiceOptions: any;
  additional_details: any;
  createTransaction: any;
  setDetails: any;
  openCheckout: any;
  transactProcess: any;
  setProcessDone: any;
  selectMethod: any;
  tipObj: any;
  setTip: any;
};

const Confirmation: FC<Props> = ({
  currentSlide,
  setSlide,
  showProcessDone,
  setInvoiceOptions,
  invoiceOptions,
  additional_details,
  createTransaction,
  setDetails,
  openCheckout,
  transactProcess,
  setProcessDone,
  selectMethod,
  tipObj,
  setTip,
}) => {
  const dispatch = useDispatch();
  const cart = useSelector((state: RootState) => state.OrderData.cart);
  const selectedCurrency = useSelector(
    (state: RootState) => state.SettingsData.selectedCurrency
  );

  //Create  A Transaction
  const clearAfterPark = () => {
    //Clear Cart
    dispatch(updateCart({}));
    setDetails({
      customers_details: {
        name: "",
        email: "",
        address: "",
        "primary phone": "",
      },
      note: "",
    });
    openCheckout(false);
  };

  //Check If there is eamil and eneable/disable mail option
  useEffect(() => {
    if (!additional_details?.customers_details?.email) {
      setInvoiceOptions((prev: any) => {
        localStorage.setItem(
          "invoiceOpt",
          JSON.stringify([...prev?.filter((data: any) => data !== "email")])
        );
        return [...prev?.filter((data: any) => data !== "email")];
      });
    }
  }, [additional_details, setInvoiceOptions]);

  //Component ====================
  return (
    <div
      className={`absolute top-0 bottom-0 w-full bg-white dark:bg-black-900 flex flex-col space-y-4 overflow-hidden ${
        currentSlide === "pay" ? "right-0 left-0" : "-right-[200%] left-[200%]"
      } transition-all duration-300 p-4 sm:p-6`}
    >
      {!showProcessDone ? (
        <div className="w-full h-full flex flex-col justify-between">
          <div className="w-full h-fit flex flex-col gap-4">
            {/**Ivoice Options and Currency switcher ============================== */}
            <div className="w-full h-fit grid grid-cols-3 gap-3 select-none">
              <button
                type="button"
                onClick={() => {
                  setInvoiceOptions((prev: any) => {
                    localStorage.setItem(
                      "invoiceOpt",
                      JSON.stringify(
                        invoiceOptions?.includes("print")
                          ? [...prev?.filter((data: any) => data !== "print")]
                          : [...prev, "print"]
                      )
                    );
                    return invoiceOptions?.includes("print")
                      ? [...prev?.filter((data: any) => data !== "print")]
                      : [...prev, "print"];
                  });
                }}
                className={`px-6 h-9 col-span-1 rounded bg-slate-50 dark:bg-black-700 border ${
                  invoiceOptions?.includes("print")
                    ? "border-cyan-800 text-cyan-800"
                    : "border-slate-300 text-slate-600"
                } 
                  text-[0.65rem] font-semibold flex flex-col items-center justify-center space-y-0.5
                  relative cursor-pointer uppercase`}
              >
                {invoiceOptions?.includes("print") && (
                  <div className="absolute text-base -top-1.5 -right-1.5 w-fit h-fit rounded-full">
                  <HiCheckCircle className="text-cyan-800 z-[99]" />
                </div>
                )}
                <span>Print</span>
              </button>
              <button
                type="button"
                disabled
                onClick={() => {
                  setInvoiceOptions((prev: any) => {
                    localStorage.setItem(
                      "invoiceOpt",
                      JSON.stringify(
                        invoiceOptions?.includes("sms")
                          ? [...prev?.filter((data: any) => data !== "sms")]
                          : [...prev, "sms"]
                      )
                    );
                    return invoiceOptions?.includes("sms")
                      ? [...prev?.filter((data: any) => data !== "sms")]
                      : [...prev, "sms"];
                  });
                }}
                className={`px-6 h-9 col-span-1 rounded bg-slate-50 dark:bg-black-700 border ${
                  invoiceOptions?.includes("sms")
                    ? "border-cyan-800 text-cyan-800"
                    : "border-slate-300 text-slate-600 dark:text-black-200 dark:border-black-600"
                }  text-[0.65rem] font-semibold flex flex-col items-center justify-center space-y-0.5
                       relative cursor-pointer uppercase disabled:cursor-not-allowed disabled:opacity-70`}
              >
                {invoiceOptions?.includes("sms") && (
                  <div className="absolute text-base -top-1.5 -right-1.5 w-fit h-fit rounded-full">
                  <HiCheckCircle className="text-cyan-800 z-[99]" />
                </div>
                )}
                <span>sms</span>
              </button>
              <button
                type="button"
                disabled={!additional_details?.customers_details?.email}
                onClick={() => {
                  setInvoiceOptions((prev: any) => {
                    localStorage.setItem(
                      "invoiceOpt",
                      JSON.stringify(
                        invoiceOptions?.includes("email")
                          ? [...prev?.filter((data: any) => data !== "email")]
                          : [...prev, "email"]
                      )
                    );
                    return invoiceOptions?.includes("email")
                      ? [...prev?.filter((data: any) => data !== "email")]
                      : [...prev, "email"];
                  });
                }}
                className={`px-6 h-9 col-span-1 rounded bg-slate-50 dark:bg-black-700 border ${
                  invoiceOptions?.includes("email")
                    ? "border-cyan-800 text-cyan-800"
                    : "border-slate-300 text-slate-600 dark:text-black-200 dark:border-black-600"
                }  text-[0.65rem] font-semibold flex flex-col items-center justify-center space-y-0.5
                       relative cursor-pointer uppercase disabled:cursor-not-allowed disabled:opacity-70`}
              >
                {invoiceOptions?.includes("email") && (
                  <div className="absolute text-base -top-1.5 -right-1.5 w-fit h-fit rounded-full">
                    <HiCheckCircle className="text-cyan-800 z-[99]" />
                  </div>
                )}
                <span>email</span>
              </button>
            </div>

            {/**Discount =====================================*/}
            <Discount />

            {/**Tip ======================================= */}
            <Tip tipObj={tipObj} setTip={setTip} />

            {/**Invoice | Reciept Summary ============================== */}
            <ul className="w-full h-fit flex flex-col justify-between p-2 border-y border-slate-200 dark:border-black-600 capitalize gap-1">
              <li className="h-fit w-full flex items-center justify-between">
                <span className="text-xs dark:text-black-200 text-slate-700 font-semibold">
                  Tip ({cart?.tip_percent}%)
                </span>
                <span className="text-xs dark:text-black-200 text-slate-700 font-semibold">
                  {selectedCurrency?.symbol}&nbsp;
                  {cart?.products?.length >= 1 && cart?.tip_amount
                    ? numberWithSpaces(
                        (
                          selectedCurrency?.rate_multiplier * cart?.tip_amount
                        ).toFixed(2)
                      )
                    : "0.00"}
                </span>
              </li>
              <li className="h-fit w-full flex items-center justify-between">
                <span className="text-xs dark:text-black-200 text-slate-700 font-semibold">
                  Discount ({cart?.discount_percent?.toFixed(2)}%)
                </span>
                <span className="text-xs dark:text-black-200 text-slate-700 font-semibold">
                  {selectedCurrency?.symbol}&nbsp;
                  {cart?.products?.length >= 1 && cart?.discount_amount
                    ? numberWithSpaces(
                        (
                          selectedCurrency?.rate_multiplier *
                          cart?.discount_amount
                        ).toFixed(2)
                      )
                    : "0.00"}
                </span>
              </li>
              <li className="h-fit w-full flex items-center justify-between">
                <span className="text-xs dark:text-black-200 text-slate-700 font-semibold">
                  {cart?.fulfillment_type} Fee
                </span>
                <span className="text-xs dark:text-black-200 text-slate-700 font-semibold">
                  {selectedCurrency?.symbol}&nbsp;
                  {cart?.service_fee
                    ? numberWithSpaces(
                        (
                          selectedCurrency?.rate_multiplier * cart?.service_fee
                        ).toFixed(2)
                      )
                    : "0.00"}
                </span>
              </li>
              <li className="h-fit w-full flex items-center justify-between">
                <span className="text-xs dark:text-black-200 text-slate-700 font-semibold">
                  Tax (0%)
                </span>
                <span className="text-xs dark:text-black-200 text-slate-700 font-semibold">
                  {selectedCurrency?.symbol}&nbsp;
                  {cart?.products?.length >= 1
                    ? numberWithSpaces(
                        (
                          selectedCurrency?.rate_multiplier * cart?.tax_in_usd
                        ).toFixed(2)
                      )
                    : "0.00"}
                </span>
              </li>
              <li className="h-fit w-full flex items-center justify-between">
                <span className="text-xs dark:text-black-200 text-slate-700 font-semibold">
                  Overall Total
                </span>
                <span className="text-xs dark:text-black-200 text-slate-700 font-semibold">
                  {selectedCurrency?.symbol}&nbsp;
                  {cart?.products?.length >= 1
                    ? numberWithSpaces(
                        (
                          selectedCurrency?.rate_multiplier *
                          (cart?.total + (cart?.tip_amount ?? 0))
                        ).toFixed(2)
                      )
                    : "0.00"}
                </span>
              </li>
            </ul>
            {/**Invoice | Reciept Summary ============================== */}
          </div>

          {/**Bottom Btns */}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              createTransaction();
            }}
            className="w-full h-fit flex flex-col gap-4"
          >
            {/**Transation options ============================== */}
            <div
              className="w-full h-12 grid grid-cols-3 rounded overflow-hidden
                  divide-x divide-cyan-600"
            >
              <button
                type="button"
                onClick={() => {
                  setSlide("info");
                }}
                className="h-full col-span-1 bg-cyan-800 hover:bg-cyan-700 transition-all font-medium 
                text-xs text-white uppercase disabled:cursor-not-allowed disabled:opacity-80
                 flex items-center justify-center"
              >
                back
              </button>
              <button
                type="button"
                onClick={() => {
                  parkSale({
                    ...cart,
                    status: "pending",
                    date: new Date().getTime(),
                  });
                  clearAfterPark();
                }}
                className="h-full col-span-1 bg-cyan-800 hover:bg-cyan-700 transition-all font-medium 
                text-xs text-white uppercase disabled:cursor-not-allowed disabled:opacity-80
                 flex items-center justify-center"
              >
                park
              </button>

              <button
                type="submit"
                disabled={transactProcess}
                className="h-full col-span-1 bg-cyan-800 hover:bg-cyan-700 transition-all font-medium 
                text-xs text-white uppercase disabled:cursor-not-allowed disabled:opacity-80
                 flex items-center justify-center"
              >
                {!transactProcess && <span>transact</span>}
                {transactProcess && (
                  <div
                    className="h-6 w-6 rounded-full border-4 border-cyan-50 border-l-cyan-700
                       animate-spin"
                  ></div>
                )}
              </button>
            </div>
          </form>
        </div>
      ) : (
        <Success
          setProcessDone={setProcessDone}
          openCheckout={openCheckout}
          setSlide={setSlide}
          selectMethod={selectMethod}
        />
      )}
    </div>
  );
};

export default Confirmation;
