import { FC, useEffect } from "react";
import { onSnapshot, query, where } from "firebase/firestore";
import {
  addStock_Orders,
  loadInventoryData,
  addVendors,
  updateCategories,
  changeProductsLaodingState,
  loadEvaluations,
} from "../Redux/Slices/InventorySlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../Redux/store";
import {
  inventoryRef,
  stockOrderRef,
  vendorsRef,
  categoriesRef,
  evaluationRef,
} from "./Firestore_Func";
import { notifications } from "@mantine/notifications";

const InventoryFirebase: FC = () => {
  const user = useSelector((state: RootState) => state.UserInfo.user);
  const dispatch: AppDispatch = useDispatch();
  const stock_orders_date = useSelector(
    (state: RootState) => state.Inventory.stock_orders_date
  );

  //===================Fetch Data ============================

  //Fetch Inventory Data
  useEffect((): any => {
    dispatch(changeProductsLaodingState(true));
    return onSnapshot(
      inventoryRef,
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        dispatch(
          loadInventoryData(
            snapshot.docs.map((doc: { data: () => any; id: any }) => ({
              ...doc.data(),
              id: doc.id,
            }))
          )
        );
        dispatch(changeProductsLaodingState(false));
      },
      (error) => {
        dispatch(changeProductsLaodingState(false));
        console.log(error);
        notifications.show({
          message: "We ran into an error trying to fetch data",
          color:"red"
        });
      }
    );
  }, [dispatch]);

  // // //Fetch Stock Order Data
  useEffect((): any => {
    return onSnapshot(
      user?.role === "admin"||user?.role === "root"
        ? query(
            stockOrderRef,
            where("date", ">=", new Date(stock_orders_date?.start).getTime()),
            where(
              "date",
              "<=",
              new Date(Number(stock_orders_date?.end) + 86400000).getTime()
            )
          )
        : query(
            stockOrderRef,
            where("user.email", "==", user?.email),
            where("date", ">=", new Date(stock_orders_date?.start).getTime()),
            where(
              "date",
              "<=",
              new Date(Number(stock_orders_date?.end) + 86400000).getTime()
            )
          ),
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        dispatch(
          addStock_Orders(
            snapshot.docs.map((doc: { data: () => any; id: any }) => ({
              ...doc.data(),
              id: doc.id,
            }))
          )
        );
      }
    );
  }, [dispatch, stock_orders_date, user]);

  // //Fetch Suppliers Data
  useEffect((): any => {
    return onSnapshot(
      vendorsRef,
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        dispatch(
          addVendors(
            snapshot.docs.map((doc: { data: () => any; id: any }) => ({
              ...doc.data(),
              id: doc.id,
            }))
          )
        );
      }
    );
  }, [dispatch]);

  // //Fetch Categories
  useEffect((): any => {
    return onSnapshot(
      categoriesRef,
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        dispatch(
          updateCategories(
            snapshot.docs.map((doc: { data: () => any; id: any }) => ({
              ...doc.data(),
              id: doc.id,
            }))
          )
        );
      }
    );
  }, [dispatch]);

  // //Fetch Evaluations
  useEffect((): any => {
    return onSnapshot(
      evaluationRef,
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        dispatch(
          loadEvaluations(
            snapshot.docs.map((doc: { data: () => any; id: any }) => ({
              ...doc.data(),
              id: doc.id,
            }))
          )
        );
      }
    );
  }, [dispatch]);

  return <></>;
};

export default InventoryFirebase;
