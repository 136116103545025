import { FC, useEffect } from "react";
import { onSnapshot } from "firebase/firestore";
import {
  currenciesRef,
  authPermissionsRef,
  workspaceSettingsRef,
  accessPermissionsRef,
  paymentsRef,
  systemRef,
  channelsRef,
  fulfillRef,
} from "./Firestore_Func";
import {
  channels,
  loadChannels,
  loadCurrencies,
  loadProviders,
  loadSystemSettings,
  updateAccessPermissionData,
  updateAuthData,
  updateAuthPermissionData,
  updateCustomerDispaySettings,
  updatePaymentsMethods,
  updateReceiptSettings,
  updateWorkspaceDetails,
} from "../Redux/Slices/SettingsSlice";
import { AppDispatch } from "../Redux/store";
import { useDispatch } from "react-redux";

const SettingsFirebase: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  //Fetch System Settings ===========================
  useEffect((): any => {
    return onSnapshot(
      systemRef,
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        dispatch(
          loadSystemSettings(
            snapshot.docs.map((doc: { data: () => any; id: any }) => ({
              ...doc.data(),
              id: doc.id,
            }))
          )
        );
      }
    );
  }, [dispatch]);

  //Fetch Currencies Data
  useEffect((): any => {
    return onSnapshot(
      currenciesRef,
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        dispatch(
          loadCurrencies(
            snapshot.docs.map((doc: { data: () => any; id: any }) => ({
              ...doc.data(),
              id: doc.id,
            }))
          )
        );
      }
    );
  }, [dispatch]);

  //Auth Data
  useEffect((): any => {
    return onSnapshot(
      authPermissionsRef,
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        //Fetch Pin
        dispatch(
          updateAuthData(
            snapshot.docs
              .map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))
              ?.find((data: any) => data?.name === "authorization_pin") || null
          )
        );

        //Fetch Permissions
        dispatch(
          updateAuthPermissionData(
            snapshot.docs
              .map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))
              ?.find(
                (data: any) => data?.name === "authorization_permissions"
              ) || null
          )
        );
      }
    );
  }, [dispatch]);

  //Access Data
  useEffect((): any => {
    return onSnapshot(
      accessPermissionsRef,
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        //Fetch Permissions
        dispatch(
          updateAccessPermissionData(
            snapshot.docs.map((doc: { data: () => any; id: any }) => ({
              ...doc.data(),
              id: doc.id,
            }))
          )
        );
      }
    );
  }, [dispatch]);

  //Payment Methods
  useEffect((): any => {
    return onSnapshot(
      paymentsRef,
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        //Payment Methods
        dispatch(
          updatePaymentsMethods(
            snapshot.docs.map((doc: { data: () => any; id: any }) => ({
              ...doc.data(),
              id: doc.id,
            }))
          )
        );
      }
    );
  }, [dispatch]);

  //Channels || Portals
  useEffect((): any => {
    return onSnapshot(
      channelsRef,
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        const data = snapshot.docs
          .map((doc: { data: () => any; id: any }) => ({
            ...doc.data(),
            id: doc.id,
          }))
          .sort((a: channels, b: channels) => (a.name > b.name ? 1 : -1));
        dispatch(loadChannels(data));
      }
    );
  }, [dispatch]);

  //Fulfillment Providers || Services
  useEffect((): any => {
    return onSnapshot(
      fulfillRef,
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        const data = snapshot.docs.map((doc: { data: () => any; id: any }) => ({
          ...doc.data(),
          id: doc.id,
        }));
        dispatch(loadProviders(data));
      }
    );
  }, [dispatch]);

  // Workspace Details
  useEffect((): any => {
    return onSnapshot(
      workspaceSettingsRef,
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        dispatch(
          updateWorkspaceDetails(
            snapshot.docs
              .map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))
              ?.find((data: any) => data?.id === "workspace_details") || null
          )
        );
        dispatch(
          updateReceiptSettings(
            snapshot.docs
              .map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))
              ?.find((data: any) => data?.id === "receipt_settings") || null
          )
        );
        dispatch(
          updateCustomerDispaySettings(
            snapshot.docs
              .map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))
              ?.find((data: any) => data?.id === "customer_display_settings") ||
              null
          )
        );
      }
    );
  }, [dispatch]);

  return <></>;
};

export default SettingsFirebase;
