import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import BusinessType from "./BusinessType";
import { AnimatePresence } from "framer-motion";
import AdditionalInfo from "./AdditionalInfo";
import PersonalInfo from "./PersonalInfo";
import { LineWobble } from "@uiball/loaders";

export type StepsType = { name: string; active: boolean; description: string };

const SignUp = () => {
  const [steps, setStep] = useState<StepsType[]>([
    {
      name: "business",
      active: true,
      description: "What Type of Business Do You Run?",
    },
    {
      name: "additional",
      active: false,
      description: "a bit more about your business",
    },
    {
      name: "personal",
      active: false,
      description: "let's get to know about you",
    },
  ]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [signUpValues, setValues] = useState<any>({
    name: "",
    company_name: "",
    email: "",
    password: "",
    type: "retail",
  });

  const handleNewAccount = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(import.meta.env.VITE_NEWACCOUNT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({...signUpValues,website:""}),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData?.message || "Failed to create account.");
      }
  
      const data = await response.json();
  
      setLoading(false);
      notifications.show({
        title: "New account added",
        message: data?.message,
        color: "green",
      });
      navigate("/login");
    } catch (error: any) {
      setLoading(false);
      notifications.show({
        title: "Failed To create account",
        message: error?.message || "An error occurred.",
        color: "red",
      });
      console.error("Function call failed:", error);
    }
  };  

  //Component ================
  return (
    <form
      onSubmit={(e: React.SyntheticEvent) => handleNewAccount(e)}
      className="fixed top-0 left-0 right-0 bottom-0 bg-light-bg p-4 flex flex-col items-center signup_container pt-28
       overflow-hidden overflow-y-scroll no-scrollbar no-scrollbar::-webkit-scrollbar h-full w-full"
    >
      <nav
        className="w-fit h-fit flex items-center overflow-hidden overflow-x-scroll no-scrollbar no-scrollbar::-webkit-scrollbar
        text-xs font-semibold text-slate-600 shrink-0 gap-0.5"
      >
        {steps.map((step: StepsType) => {
          return (
            <button
              onClick={() => {
                setStep((prev: StepsType[]) => [
                  ...prev.map((data: StepsType) => ({
                    ...data,
                    active: data?.name === step?.name ? true : false,
                  })),
                ]);
              }}
              key={step?.name}
              type="button"
              className={`w-fit h-fit p-2 px-6 hover:text-cyan-800 capitalize relative transition-all hover:after:flex after:absolute after:-bottom-0 after:left-0 after:h-0.5 after:w-0
              hover:after:w-full hover:transition-al after:transition-all after:ease-linear after:bg-cyan-800 ${
                step?.active ? "after:right-0 text-cyan-800 after:w-full" : ""
              }  `}
            >
              {step?.name}
            </button>
          );
        })}
      </nav>

      <h1
        className="mt-3 md:mt-6 text-cyan-800 text-lg md:text-2xl font-bold text-center capitalize px-6
         whitespace-nowrap truncate shrink-0"
      >
        {steps?.find((option: StepsType) => option?.active)?.description}
      </h1>
      <p className="mt-2 text-center text-xs md:text-sm text-slate-500 font-medium px-4 z-10">
        Make sure the details below are accurate or if you have an account &nbsp;{" "}
        <Link to="/login" className="text-cyan-800 underline">
          Login
        </Link>
      </p>

      {/**Tabs Or Current Step */}
      <AnimatePresence>
        {steps?.find((option: StepsType) => option?.active)?.name ===
          "business" && (
          <BusinessType
            signUpValues={signUpValues}
            setValues={setValues}
            setStep={setStep}
          />
        )}
       <AdditionalInfo
            signUpValues={signUpValues}
            setValues={setValues}
            setStep={setStep}
            active={
              steps?.find((option: StepsType) => option?.active)?.name ===
              "additional"
            }
          />
        {steps?.find((option: StepsType) => option?.active)?.name ===
          "personal" && (
          <PersonalInfo
            loading={loading}
            signUpValues={signUpValues}
            setValues={setValues}
            setStep={setStep}
          />
        )}
      </AnimatePresence>

      {loading && (
        <div
          className="fixed top-0 bottom-0 left-0 right-0 bg-white/30 backdrop-blur-sm 
          flex flex-col items-center justify-center gap-4 z-[9999]"
        >
          <LineWobble size={150} lineWeight={7} speed={2} color="#0e7490" />
          <span className="text-sm font-semibold text-cyan-700">
            Please wait ...
          </span>
        </div>
      )}
    </form>
  );
};

export default SignUp;
