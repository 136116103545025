import UserSlice from "./Slices/UserSlice";
import InventorySlice from "./Slices/InventorySlice";
import SettingsSlice from "./Slices/SettingsSlice";
import NotificationsSlice from "./Slices/NotificationsSlice";
import SalesSlice from "./Slices/SalesSlice";
import ReportSlice from "./Slices/ReportSlice";
import CustomersSlice from "./Slices/CustomersSlice";
import OrderSlice from "./Slices/OrderSlice";
import TeamSlice from "./Slices/TeamSlice";

const rootReducer = {
  UserInfo: UserSlice,
  Inventory:InventorySlice,
  SettingsData:SettingsSlice,
  NotificationsData: NotificationsSlice,
  Sales: SalesSlice,
  Reports:ReportSlice,
  Customers:CustomersSlice,
  OrderData:OrderSlice,
  TeamManagement:TeamSlice,
};

export default rootReducer;
