import { FC } from "react";
import { TbChevronLeft, TbTrash } from "react-icons/tb";
import { updateCart } from "../../../Redux/Slices/OrderSlice";
import { useDispatch } from "react-redux";
import { RiSearchLine } from "react-icons/ri";

type Props = {
  searchCart: any;
  searchValue: string;
  setSmCart: any;
};

const TopSearch: FC<Props> = ({ searchCart, searchValue, setSmCart }) => {
  const dispatch = useDispatch();

  //Component ==================
  return (
    <div className="w-full flex justify-between items-center gap-2 shrink-0">
      <button
        onClick={() => setSmCart(false)}
        className="h-10 w-10 min-w-[2.5rem] outline-none focus:outline-none text-slate-600 dark:text-black-300
         text-2xl stroke-[3] border border-slate-300 dark:border-black-600 rounded-md flex justify-center
          items-center lg:hidden bg-slate-50/50 dark:bg-black-800"
      >
        <TbChevronLeft />
      </button>
      <div
        className="w-full h-10 relative flex gap-1 border border-slate-300 dark:border-black-600
           overflow-hidden rounded-md focus-within:border-cyan-800/50 transition-all bg-slate-50/50 dark:bg-black-800"
      >
        <RiSearchLine className="absolute left-2 top-2.5 text-slate-500 dark:text-black-400 text-lg" />
        <input
          onChange={(e) => {
            searchCart(e.target.value?.toString());
          }}
          value={searchValue}
          type="search"
          name="search_cart"
          id="search_cart"
          placeholder="Search Cart ..."
          className="w-[calc(100%-2.5rem)] h-full bg-transparent border-0 px-3 pl-8 text-xs text-slate-600 dark:text-black-300
           placeholder:text-slate-400 dark:placeholder:text-black-400 focus:ring-0 transition-all
            outline-none focus:outline-none ring-0"
        />
        <button
          onClick={() => {
            dispatch(updateCart({}));
            setSmCart(false);
          }}
          className="h-full w-11 min-w-[2.5ren] bg-slate-50/50 dark:bg-black-700 hover:bg-red-50 transition-all 
          flex items-center justify-center text-lg text-red-500 border-l border-slate-200 dark:border-black-600"
        >
          <TbTrash />
        </button>
      </div>
    </div>
  );
};

export default TopSearch;
