import React from "react";
import { useDispatch } from "react-redux";
import useLongPress from "../../../Custom-Hooks/useLongPress";
import no_gallery from "../../../Assets/no_preview_card.png";
import {
  customizeProduct,
  openPreview,
} from "../../../Redux/Slices/OrderSlice";

type Props = {
  data: any;
  addToCart: any;
  focusIndex: any;
  index: any;
  quantity: any;
  setQuantity: any;
  openSearch:any
};

const Item = ({
  data,
  addToCart,
  focusIndex,
  index,
  quantity,
  setQuantity,
  openSearch
}: Props) => {
  const dispatch = useDispatch();

  //Add To addToPreview =================================
  const addToPreview = (prod: any) => {
    if (
      Number(prod?.in_stock > 0 && prod?.has_stock_count) ||
      !prod?.has_stock_count
    ) {
      dispatch(
        customizeProduct({
          customization: [],
          prod_cart_uid: prod?.id + new Date().getTime(),
          prod_obj: prod,
          quantity: 1,
        })
      );
      openSearch(false)
      dispatch(openPreview(true));
    }
  };

  //Handle Long Press ==================
  const {onMouseDown,onTouchStart,pressDuration} = useLongPress(
    () =>
      (Number(data?.in_stock > 0 && data?.has_stock_count) ||
        !data?.has_stock_count) &&
      addToPreview(data),
    { delay: 300, threshold: 600 }
  );

  //Component= ==========================
  return (
    <li
      onClick={() => {
        if (
          (Number(data?.in_stock) > 0 && data?.has_stock_count) ||
          !data?.has_stock_count
        ) {
          addToCart(data);
        }
      }}
      {...{onMouseDown,onTouchStart}}
      className={`w-full h-10 rounded-md border border-slate-200 dark:border-black-500 bg-light-bg dark:bg-black-800 group
      px-0.5 shrink-0 ${
              focusIndex === index ? "!border-cyan-800/20 !bg-cyan-800/10" : ""
            } flex items-center  gap-3 select-none cursor-pointer hover:bg-cyan-800/10 hover:border-cyan-800/50
            relative transition-all`}
    >
      <img
        onError={(e) => {
          e.currentTarget.src = no_gallery;
        }}
        src={data?.thumbnail || no_gallery}
        alt="img"
        className="h-8 w-8 bg-slate-100 border-slate-200 rounded object-fit object-cover object-center"
      />
      <div className="h-full w-[calc(100%-7rem)] flex flex-col justify-center space-y-1">
        <div
          className="text-slate-600 dark:text-black-300 font-semibold capitalize text-xs w-full whitespace-nowrap
               overflow-hidden text-ellipsis flex items-center space-x-1"
        >
          <span
            onClick={() => {
              addToCart(data);
            }}
            className="max-w-[50%] whitespace-nowrap
               overflow-hidden text-ellipsis"
          >
            {data?.name}
          </span>
          {focusIndex === index &&
          ((Number(data?.in_stock) > 0 && data?.has_stock_count) ||
            !data?.has_stock_count) ? (
            <>
              {" "}
              <span>- Quantity</span>
              <input
                type="number"
                value={quantity}
                step="0.01"
                autoFocus
                onFocus={(e) => {
                  e.target.select();
                }}
                autoComplete="off"
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    setQuantity(
                      ((Number(data?.in_stock) >= Number(e.target.value) &&
                        data?.has_stock_count) ||
                        !data?.has_stock_count) &&
                        Number(e.target.value) > 0
                        ? Number(e.target.value)
                        : 1
                    );
                  } else {
                    setQuantity(e.target.value);
                  }
                }}
                className="h-4 border-0 bg-inherit text-xs focus:ring-0 text-cyan-800 px-1"
              />
            </>
          ) : (
            ""
          )}
          <span
            className={`${
              focusIndex !== index &&
              ((Number(data?.in_stock) > 0 && data?.has_stock_count) ||
                !data?.has_stock_count)
                ? "hidden group-hover:flex z-10"
                : "hidden group-hover:hidden"
            }`}
          >
            <span>- Quantity</span>
            <input
              type="number"
              value={quantity}
              step="0.01"
              autoFocus
              onFocus={(e) => {
                e.target.select();
              }}
              autoComplete="off"
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  setQuantity(
                    ((Number(data?.in_stock) >= Number(e.target.value) &&
                      data?.has_stock_count) ||
                      !data?.has_stock_count) &&
                      Number(e.target.value) > 0
                      ? Number(e.target.value)
                      : 1
                  );
                } else {
                  setQuantity(e.target.value);
                }
              }}
              className="h-4 w-14 border-0 bg-inherit text-xs focus:ring-0 text-cyan-800 px-1"
            />
          </span>
        </div>
      </div>

       {/**Indicate long Press */}
       <div
        style={{ width: `${(pressDuration / 1000) * 100}%` }}
        className="absolute bottom-0.5 left-0.5 top-0.5 bg-cyan-800/30 backdrop-blur-sm transition-all rounded"
      >
      </div>
    </li>
  );
};

export default Item;
