import { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { TbUserSearch } from "react-icons/tb";

type Props = {
  searchValue: string;
  setActiveCustomer: any;
};

const CustomersList: FC<Props> = ({ searchValue, setActiveCustomer }) => {
  const [clicked, setClicked] = useState<boolean>(false);
  const customers = useSelector(
    (state: RootState) => state.Customers.customers
  );
  const filteredList = useMemo(() => {
    return customers?.length >= 1
      ? customers
          ?.filter((item: any) =>
            Object.values(item).some((val: any) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.replace(/\s/gim, "")
                ?.includes(
                  searchValue?.toString()?.toLowerCase()?.replace(/\s/gim, "")
                )
            )
          )
          ?.slice(0, 5)
          ?.sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
      : [];
  }, [customers, searchValue]);

  //Component
  return (
    <div
      className={`absolute top-12 w-full min-h-[4rem] max-h-[15rem] rounded-lg
       bg-white dark:to-black-700 border border-slate-300 shadow-2xl drop-shadow-2xl p-4 ${
         !clicked
           ? "group-focus-within:scale-100 hover:scale-100 scale-0"
           : "hidden"
       } transition-all select-none flex flex-col space-y-2 overflow-hidden 
       overflow-y-scroll no-scrollbar no-scrollbar::-webkit-scrollbar`}
    >
      {filteredList?.length >= 1 ? (
        filteredList?.map((customer: any, index: number) => {
          return (
            <div
              onClick={() => {
                setClicked(true);
                setTimeout(() => {
                  setClicked(false);
                }, 1000);
                setActiveCustomer((prev: any) => ({
                  ...prev,
                  customers_details: {
                    ...customer,
                    "primary phone": customer?.["primary phone"]?.replace(/[+ ]/gmi,""),
                  },
                }));
              }}
              key={customer?.account?.acount_number ?? "" + index}
              className="h-12 w-full rounded-md bg-slate-50 border border-slate-200 p-2
              flex items-center gap-2 cursor-pointer hover:border-cyan-700/60 transition-all"
            >
              <div
                className="h-8 w-8 rounded-full bg-cyan-800 text-white text-xs font-bold uppercase
        flex items-center justify-center"
              >
                {customer?.name?.replace(/\s/gim, "")?.charAt(0)}
              </div>
              <div className="w-[100%-3rem] h-full flex flex-col justify-center space-y-0 overflow-hidden">
                <span className="w-full overflow-hidden truncate text-xs  font-semibold capitalize text-slate-700">
                  {customer?.name}
                </span>
                <span className="w-full overflow-hidden truncate text-xs  font-medium lowercase text-slate-500">
                  {customer?.email}
                </span>
              </div>
            </div>
          );
        })
      ) : (
        <div className="h-12 w-full p-2 flex items-center justify-center space-x-2">
          <TbUserSearch className="text-lg text-slate-500" />
          <span className="text-xs text-slate-500 font-medium">No results</span>
        </div>
      )}
    </div>
  );
};

export default CustomersList;
