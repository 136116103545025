import React from "react";
import { RootState } from "../../Redux/store";
import { useSelector } from "react-redux";
import { numberWithSpaces } from "../../Reusable Functions/Functions";

type Props = {
  sale: any;
  componentRef?: any;
};

const Reciept = ({ sale, componentRef }: Props) => {
  const workspace_details = useSelector(
    (state: RootState) => state.SettingsData.workspace_details
  );
  const selectedCurrency = useSelector(
    (state: RootState) => state.SettingsData.selectedCurrency
  );

  //Component ===============
  return (
    <div
      ref={componentRef}
      className="w-full md:w-[27rem] h-fit flex flex-col !text-black
           border border-slate-200 rounded-sm p-6 bg-slate-50 print:bg-white print:max-w-full print:w-full print:border-none
            print:fixed print:top-0 print:left-0 print:bottom-0 print:right-0 prirnt:z-[9999] print:rounded-none"
    >
      <div className="h-fit w-full pb-4 border-b border-dashed border-slate-300">
        <div className="w-full h-fit overflow-hidden text-black space-y-1 flex flex-col">
          <span className="text-xs uppercase font-bold w-full whitespace-nowrap overflow-hidden text-ellipsis">
            {workspace_details?.name || "N/A"}
          </span>
          <span className="text-xs uppercase font-medium w-full whitespace-nowrap overflow-hidden text-ellipsis">
            {workspace_details?.address}
          </span>
          <span className="text-xs uppercase font-medium w-full whitespace-nowrap overflow-hidden text-ellipsis">
            Phone : {workspace_details?.primary_phone}
          </span>
          <span className="text-xs lowercase font-medium w-full whitespace-nowrap overflow-hidden text-ellipsis">
            <span className="uppercase">email :</span>{" "}
            {workspace_details?.email}
          </span>
          <span className="text-xs capitalize font-medium w-full whitespace-nowrap overflow-hidden text-ellipsis">
            <span className="uppercase">Served by :</span> {sale?.user?.name}
          </span>
          <span className="text-xs capitalize font-medium w-full whitespace-nowrap overflow-hidden text-ellipsis">
            {new Date(sale?.date)?.toString()?.split("(")[0]}
          </span>
          <span className="text-xs uppercase font-medium w-full whitespace-nowrap overflow-hidden text-ellipsis">
            RECEIPT #{sale?.transact_id}
          </span>
        </div>
      </div>

      <ul className="w-full h-fit space-y-1 py-4 overflow-hidden">
        {sale?.products?.length >= 1 &&
          sale?.products?.map((prod: any, index: number) => {
            return (
              <li
                key={prod?.prod_cart_uid + index}
                className="w-full h-5 flex justify-between items-center uppercase text-xs text-black"
              >
                <span>
                  {prod?.prod_obj?.name}&nbsp;&nbsp;
                  <span className="lowercase">x</span> {prod?.quantity}
                </span>
                <span>
                  {selectedCurrency?.symbol}&nbsp;
                  {numberWithSpaces(
                    (
                      selectedCurrency?.rate_multiplier *
                      Number(prod?.prod_obj?.price_in_usd) *
                      Number(prod?.quantity)
                    ).toFixed(2)
                  )}
                </span>
              </li>
            );
          })}
        {sale?.refunded_products?.length >= 1 && (
          <>
            <span
              className="text-xs uppercase font-semibold
                      text-black w-full whitespace-nowrap overflow-hidden text-ellipsis"
            >
              Returned items
            </span>
            {sale?.refunded_products?.map((prod: any, index: number) => {
              return (
                <li
                  key={prod?.prod_cart_uid + index}
                  className="w-full h-5 flex justify-between items-center uppercase text-xs text-black"
                >
                  <span>
                    {prod?.prod_obj?.name}&nbsp;&nbsp;
                    <span className="lowercase">x</span> {prod?.quantity}
                  </span>
                  <span>
                    {selectedCurrency?.symbol}&nbsp;
                    {numberWithSpaces(
                      (
                        selectedCurrency?.rate_multiplier *
                        Number(prod?.prod_obj?.price_in_usd) *
                        Number(prod?.quantity)
                      ).toFixed(2)
                    )}
                  </span>
                </li>
              );
            })}
          </>
        )}
      </ul>

      {/**Totals */}
      <ul className="w-full h-fit overflow-hidden py-4 space-y-1 border-dashed border-y
       border-slate-300 dark:border-black-600 capitalize">
        {sale?.payment_method === "cash" && (
          <>
            <li className="w-full h-4 flex items-center justify-between">
              <span className="text-xs text-black font-semibold">
                Tendered Amount
              </span>
              <span className="text-xs text-black font-semibold">
                {selectedCurrency?.symbol}&nbsp;
                {sale?.products?.length >= 1 && sale?.tendered_amount
                  ? numberWithSpaces(
                      (
                        selectedCurrency?.rate_multiplier *
                        sale?.tendered_amount
                      ).toFixed(2)
                    )
                  : "0.00"}
              </span>
            </li>
            <li className="w-full h-4 flex items-center justify-between">
              <span className="text-xs text-black font-semibold">Change</span>
              <span className="text-xs text-black font-semibold">
                {selectedCurrency?.symbol}&nbsp;
                {sale?.products?.length >= 1 && sale?.change
                  ? numberWithSpaces(
                      (
                        selectedCurrency?.rate_multiplier * sale?.change
                      ).toFixed(2)
                    )
                  : "0.00"}
              </span>
            </li>
          </>
        )}
        <li className="w-full h-4 flex items-center justify-between">
          <span className="text-xs text-black font-semibold">
            Tip ({sale?.tip_percent ?? 0}%)
          </span>
          <span className="text-xs text-black font-semibold">
            {selectedCurrency?.symbol}&nbsp;
            {sale?.products?.length >= 1 && sale?.tip_amount
              ? numberWithSpaces(
                  (
                    selectedCurrency?.rate_multiplier * sale?.tip_amount
                  ).toFixed(2)
                )
              : "0.00"}
          </span>
        </li>
        <li className="w-full h-4 flex items-center justify-between">
          <span className="text-xs text-black font-semibold">
            Discount ({sale?.discount_percent?.toFixed(2) ?? 0}%)
          </span>
          <span className="text-xs text-black font-semibold">
            {selectedCurrency?.symbol}&nbsp;
            {sale?.products?.length >= 1 && sale?.discount_amount
              ? numberWithSpaces(
                  (
                    selectedCurrency?.rate_multiplier * sale?.discount_amount
                  ).toFixed(2)
                )
              : "0.00"}
          </span>
        </li>
        {sale?.fulfillment_type && (
          <li className="w-full h-4 flex items-center justify-between">
            <span className="text-xs text-black font-semibold">
              {sale?.fulfillment_type} fee
            </span>
            <span className="text-xs text-black font-semibold">
              {selectedCurrency?.symbol}&nbsp;
              {sale?.service_fee
                ? numberWithSpaces(
                    (
                      selectedCurrency?.rate_multiplier * sale?.service_fee
                    ).toFixed(2)
                  )
                : "0.00"}
            </span>
          </li>
        )}
        <li className="w-full h-4 flex items-center justify-between">
          <span className="text-xs text-black font-semibold">
            VAT ({sale?.tax_percentage}%)
          </span>
          <span className="text-xs text-black font-semibold">
            {selectedCurrency?.symbol}&nbsp;
            {sale?.products?.length >= 1
              ? numberWithSpaces(
                  (
                    selectedCurrency?.rate_multiplier * sale?.tax_in_usd
                  ).toFixed(2)
                )
              : "0.00"}
          </span>
        </li>
        {sale?.refund_amount && (
          <li className="w-full h-4 flex items-center justify-between">
            <span className="text-xs text-black font-semibold">
              Refund Amount
            </span>
            <span className="text-xs text-black font-semibold">
              {selectedCurrency?.symbol}&nbsp;
              {sale?.products?.length >= 1
                ? numberWithSpaces(
                    (
                      selectedCurrency?.rate_multiplier * sale?.refund_amount
                    ).toFixed(2)
                  )
                : "0.00"}
            </span>
          </li>
        )}
        <li className="w-full h-4 flex items-center justify-between">
          <span className="text-xs text-black font-semibold">Total</span>
          <span className="text-xs text-black font-semibold">
            {selectedCurrency?.symbol}&nbsp;
            {sale?.products?.length >= 1
              ? numberWithSpaces(
                  (selectedCurrency?.rate_multiplier * sale?.total).toFixed(2)
                )
              : "0.00"}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default Reciept;
