import React from "react";

type Props = {
  prod: any;
};

const Status = ({ prod }: Props) => {
  //Component ===================
  return (
    <div className="w-fit flex items-center justify-between gap-1">
      <div
        className={`h-2.5 w-2.5 p-0.5 rounded-full order-last md:order-first ${
          Number(prod?.in_stock) > 0 || !prod?.has_stock_count
            ? "bg-green-600/30"
            : "bg-red-600/30"
        } shrink-0`}
      >
        <div
          className={`h-full w-full rounded-full ${
            Number(prod?.in_stock) > 0 || !prod?.has_stock_count
              ? "bg-green-600"
              : "bg-red-600"
          }`}
        ></div>
      </div>
    </div>
  );
};

export default Status;
