import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { crypt } from "../../Utils/Reusable_Func";
import { retriveObjectLocal } from "./SettingsSlice";

interface InitialStateType {
  team_members: any;
}

const initialState: InitialStateType = {
  team_members:retriveObjectLocal("teammembers")||[]
};

export const TeamSlice = createSlice({
  name: "TeamManagement",
  initialState,
  reducers: {
    udpateTeam: (state, action: PayloadAction<any>) => {
      state.team_members = action.payload;
      window.localStorage.setItem("teamembers",JSON.stringify(
        crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
      ))
    },
  },
});

export const { udpateTeam } = TeamSlice.actions;

export default TeamSlice.reducer;
