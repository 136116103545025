import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { TbCaretLeft, TbCaretRight, TbCheckbox } from "react-icons/tb";

type Props = {
  selectedCategory: any;
  addCategory: any;
};

const Categories = ({ selectedCategory, addCategory }: Props) => {
  const categoryContainerRef = useRef<HTMLDivElement>(null);
  const categories = useSelector(
    (state: RootState) => state.Inventory.categories
  );

  //Component ============
  return (
    <div className="h-7 w-full shrink-0 flex items-center justify-between gap-2 relative overflow-hidden bg-inherit">
      <div
        ref={categoryContainerRef}
        className="flex items-center space-x-1 h-full w-full overflow-hidden overflow-x-scroll                         
        no-scrollbar no-scrollbar::-webkit-scrollbar pr-24 bg-inherit"
      >
        {categories?.length <= 0 && (
          <div
            className={`h-full w-fit px-4 bg-white dark:bg-black-900 rounded-md border text-xxs uppercase font-semibold
             border-slate-300 dark:border-black-600 text-slate-500 dark:text-slate-200 hover:text-cyan-800
              hover:border-cyan-800 flex items-center space-x-2 transition-all shrink-0 select-none`}
          >
            <span className="whitespace-nowrap">no categories</span>{" "}
          </div>
        )}
        {categories?.length >= 1 &&
          [...categories]
            ?.sort((a: any, b: any) => (a?.name < b?.name ? -1 : 1))
            ?.map((cat: any) => {
              return (
                <button
                  onClick={() => {
                    if (
                      selectedCategory?.some((data: any) =>
                        data
                          ?.toString()
                          ?.toLowerCase()
                          ?.includes(cat?.name?.toString()?.toLowerCase())
                      )
                    ) {
                      addCategory((prev: any) => [
                        ...prev?.filter(
                          (data: any) =>
                            data?.toString()?.toLowerCase() !==
                            cat?.name?.toString()?.toLowerCase()
                        ),
                      ]);
                    } else {
                      addCategory((prev: any) => [
                        ...prev,
                        cat?.name?.toString()?.toLowerCase(),
                      ]);
                    }
                  }}
                  key={cat?.id_two}
                  className={`h-full w-fit px-3 leading-[0.15rem] pt-0.5 bg-white dark:bg-black-900 rounded-md border text-xxs uppercase font-semibold ${
                    selectedCategory?.some((data: any) =>
                      data
                        ?.toString()
                        ?.toLowerCase()
                        ?.includes(cat?.name?.toString()?.toLowerCase())
                    )
                      ? "text-cyan-800 border-cyan-800 pl-1"
                      : "border-slate-300 dark:border-black-600 text-slate-500 dark:text-slate-200"
                  } hover:text-cyan-800 hover:border-cyan-800 flex items-center space-x-1 transition-all focus:outline-none `}
                >
                  {selectedCategory?.some((data: any) =>
                    data
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(cat?.name?.toString()?.toLowerCase())
                  ) && <TbCheckbox className="text-base" />}
                  <span className="whitespace-nowrap">{cat?.name}</span>{" "}
                </button>
              );
            })}
      </div>
      <div
        className="h-full w-fit px-0.5 pl-2 bg-inherit hidden md:flex items-center gap-0.5
        justify-end absolute right-0"
      >
        <button
          onClick={() => {
            if (categoryContainerRef && categoryContainerRef?.current) {
              categoryContainerRef.current.scrollLeft -= 200;
            }
          }}
          className="h-full w-6 focus:outline-none text-lg stroke-[3] text-slate-500 dark:text-slate-200 bg-white dark:bg-black-900 rounded-l-md
            border border-slate-300 dark:border-black-600 flex items-center justify-center"
        >
          <TbCaretLeft />
        </button>
        <button
          onClick={() => {
            if (categoryContainerRef && categoryContainerRef?.current) {
              categoryContainerRef.current.scrollLeft += 200;
            }
          }}
          className="h-full w-6 focus:outline-none text-lg stroke-[3] text-slate-500 dark:text-slate-200 bg-white dark:bg-black-900 rounded-r-md
          border border-slate-300 dark:border-black-600 flex items-center justify-center"
        >
          <TbCaretRight />
        </button>
      </div>
    </div>
  );
};

export default Categories;
