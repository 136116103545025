import React, { FC } from "react";
import { TbChecks } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { updateCart } from "../../../Redux/Slices/OrderSlice";
import { generateWhatsAppURL } from "../../../Utils/Reusable_Func";
import { capitalize } from "moderndash";

type Props = {
  setProcessDone: any;
  openCheckout: any;
  setSlide: any;
  selectMethod: any;
};

const Success: FC<Props> = ({
  setProcessDone,
  openCheckout,
  setSlide,
  selectMethod,
}) => {
  const cart = useSelector((state: RootState) => state.OrderData.cart);
  const user = useSelector((state: RootState) => state.UserInfo.user);
  const dispatch = useDispatch();

  //component ================================
  return (
    <div className="w-full h-full flex flex-col items-center justify-center space-y-6">
      <div className="h-20 w-20 rounded-full bg-green-200 p-2">
        <div
          className="h-full w-full rounded-full bg-green-600 drop-shadow-lg
          flex items-center justify-center text-2xl text-white"
        >
          <TbChecks />
        </div>
      </div>
      <span className="text-sm text-slate-600 font-semibold text-center">
        Transaction Completed Successfully
      </span>
      <div className="w-full h-fit flex items-center justify-center space-x-6">
        <button
          onClick={() => {
            //Clear Cart
            dispatch(updateCart({}));
            setProcessDone(false);
            openCheckout(false);
            setSlide("info");
            selectMethod({
              method: "cash",
              selected: true,
            });
          }}
          className="h-9 w-36 bg-cyan-800 rounded-full text-xs text-white font-medium"
        >
          Done
        </button>
        <a
          rel="noreferrer"
          href={generateWhatsAppURL(cart, capitalize(user?.company_name) || "")}
          target="_blank"
          onClick={() => {
            //Clear Cart
            dispatch(updateCart({}));
            setProcessDone(false);
            openCheckout(false);
            setSlide("info");
            selectMethod({
              method: "cash",
              selected: true,
            });
          }}
          className="h-9 w-36 border-2 border-cyan-800 rounded-full text-xs text-cyan-800 font-medium
            flex items-center justify-center hover:bg-cyan-50 transition-all capitalize"
        >
          Whatsapp invoice
        </a>
      </div>
    </div>
  );
};

export default Success;
