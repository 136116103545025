import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { retriveObjectLocal } from "./SettingsSlice";
import { crypt } from "../../Utils/Reusable_Func";

//Get Theme From Local Storage ==============
const sales_date = localStorage.getItem("sales_date");

interface InitialStateType {
  parked_sales: any[];
  completed_sales: any[];
  transactions_archieve: any[];
  cash_float: any[];
  cash_float_date: any;
  sales_date: any;
  salesDataLoading: any;
}

const initialState: InitialStateType = {
  parked_sales:  retriveObjectLocal("parked_sales") || [],
  completed_sales: retriveObjectLocal("completed_sales") || [],
  transactions_archieve: retriveObjectLocal("transactions_archieve") || [],
  cash_float:retriveObjectLocal("cash_float") || [],
  cash_float_date: retriveObjectLocal("cash_float_date") || {
    start: new Date().getTime() - 186400000,
    end: new Date().getTime(),
  },
  sales_date: sales_date
    ? JSON.parse(sales_date)
    : { start: new Date().getTime() - 86400000, end: new Date().getTime() },
  salesDataLoading: false,
};

export const SalesSlices = createSlice({
  name: "Sales",
  initialState,
  reducers: {
    parkSales: (state, action: PayloadAction<any[]>) => {
      state.parked_sales = action.payload;
      //Save Data Locally
      window.localStorage.setItem(
        "parked_sales",
        JSON.stringify(
          crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
        )
      );
    },
    addSales: (state, action: PayloadAction<any[]>) => {
      state.completed_sales = action.payload;
      //Save Data Locally
      window.localStorage.setItem(
        "completed_sales",
        JSON.stringify(
          crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
        )
      );
    },
    archieveSale: (state, action: PayloadAction<any[]>) => {
      state.transactions_archieve = action.payload;
    },
    updateFloat: (state, action: PayloadAction<any[]>) => {
      state.cash_float = action.payload;
      window.localStorage.setItem("cash_float",  JSON.stringify(
        crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
      ));
    },
    changeFloatDate: (state, action: PayloadAction<any>) => {
      state.cash_float_date = action.payload;
      action.payload &&
        window.localStorage.setItem(
          "cash_float_date",
          JSON.stringify(
            crypt(import.meta.env.VITE_API_KEY, JSON.stringify(action.payload))
          )
        );
    },
    changeSalesDate: (state, action: PayloadAction<any>) => {
      state.sales_date = action.payload;
      //Save Data Locally
      window.localStorage.setItem("sales_date", JSON.stringify(action.payload));
    },
    setLoadingStatus: (state, action: PayloadAction<boolean>) => {
      state.salesDataLoading = action.payload;
    },
  },
});

export const {
  parkSales,
  addSales,
  archieveSale,
  updateFloat,
  changeFloatDate,
  changeSalesDate,
  setLoadingStatus,
} = SalesSlices.actions;

export default SalesSlices.reducer;
