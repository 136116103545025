import { FC, useEffect } from "react";
import { onSnapshot, query, where } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../Redux/store";
import { cash_floatRef } from "./Firestore_Func";
import { updateFloat } from "../Redux/Slices/SalesSlice";

const CashFloatFirebase: FC = () => {
  const user = useSelector((state: RootState) => state.UserInfo.user);
  const dispatch: AppDispatch = useDispatch();
  const cash_float_date = useSelector(
    (state: RootState) => state.Sales.cash_float_date
  );

  //===================Fetch Data ============================

  // //Fetch Cash Float Data
  useEffect((): any => {
    return onSnapshot(
      user?.access === "admin"
        ? query(
            cash_floatRef,
            where("date", ">=", new Date(cash_float_date?.start).getTime()),
            where(
              "date",
              "<=",
              new Date(Number(cash_float_date?.end) + 86400000).getTime()
            )
          )
        : query(
            cash_floatRef,
            where("user.email", "==", user?.email),
            where("date", ">=", new Date(cash_float_date?.start).getTime()),
            where(
              "date",
              "<=",
              new Date(Number(cash_float_date?.end) + 86400000).getTime()
            )
          ),
          { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        dispatch(
          updateFloat(
            snapshot.docs.map((doc: { data: () => any; id: any }) => ({
              ...doc.data(),
              id: doc.id,
            }))
          )
        );
      }
    );
  }, [dispatch, cash_float_date, user]);

  //Component
  return <></>;
};

export default CashFloatFirebase;
