/* eslint-disable no-unsafe-optional-chaining */
import { notifications } from "@mantine/notifications";
import {
  addVoidedSale,
  deleteSale,
  updateFloat,
  updateStock,
} from "../../Firebase/Firestore_Func";

export const voidSale = (
  verify: any,
  currentSale: any,
  reason: any,
  cash_float: any,
  selectedCurrency: any,
  inventory_data: any,
  setReason: any,
  setAuthorize: any,
  setCurrentSale: any
) => {
  if (new Date()?.getTime() - currentSale?.date < 86400000) {
    if (verify) {
      //Delete Sale
      deleteSale(currentSale?.id);

      //Save a voided Sale
      addVoidedSale({
        ...currentSale,
        reason: reason || "None",
        archieve_date: new Date()?.getTime(),
      });

      //Upate float if payment cash
      if (currentSale?.payment_method === "cash") {
        const openFloat = (() => {
          return cash_float?.length >= 1
            ? cash_float?.find((data: any) => data.status === "open")
            : null;
        })();
        if (openFloat) {
          updateFloat({
            id: openFloat?.id,
            total: Number(openFloat?.total) - currentSale?.total,
            refunds: Number(openFloat?.refunds) + currentSale?.total,
            sales: Number(openFloat?.sales) - currentSale?.total,
            activities: [
              ...openFloat?.activities,
              {
                note: "Voided Transaction",
                amount: "-" + currentSale?.total,
                time: new Date()?.getTime(),
                currency: selectedCurrency?.name,
              },
            ],
            edited: true,
            isNew: false,
            isDeleted: false,
          });
        }
      }

      //Return Stock to the Inventory
      currentSale.products?.forEach((prod: any) => {
        if (prod?.prod_obj?.has_stock_count) {
          updateStock({
            ...prod?.prod_obj,
            in_stock:
              Number(
                [...inventory_data]?.filter(
                  (data: any) => data?.id === prod?.prod_obj?.id
                )[0]?.in_stock
              ) + Number(prod?.quantity) ?? 0,
            last_editedAt: new Date().getTime(),
          });
        }
      });
      notifications.show({
        message: "Sale voided successfully",
        color: "green",
      });
      setReason("");
      setAuthorize(false);
      setCurrentSale(null);
    } else {
      notifications.show({
        message: "Wrong passcode",
        color: "red",
      });
    }
  } else {
    notifications.show({
      message: "You can't void this sale, try a refund",
      color: "red",
    });
  }
};
