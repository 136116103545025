import React from "react";
import { motion } from "framer-motion";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { RiBuilding2Line } from "react-icons/ri";
import { StepsType } from "./SignUp";
import { TbLink } from "react-icons/tb";

type Props = {
  signUpValues: any;
  setValues: any;
  setStep: any;
  active: boolean;
};

const AdditionalInfo = ({
  signUpValues,
  setValues,
  setStep,
  active,
}: Props) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 10,zIndex:-10 }}
      exit={{ opacity: 0, y: 10,zIndex:-10 }}
      animate={
        active
          ? { opacity: 1, y: 0 }
          : {
              opacity: 0,
              y: 10,
              position: "absolute",
            }
      }
      transition={{ duration: 0.4 }}
      className="mt-5 w-full md:w-fit h-fit min-h-[25rem] px-2 md:px-0 relative flex flex-col gap-3"
    >
      {/**Comapny Name ============== */}
      <div className="w-full md:w-[24.5rem] h-fit shrink-0 relative">
        <RiBuilding2Line className="text-xl text-slate-500 absolute left-3 top-3.5 z-10" />
        <input
          type="text"
          required
          onChange={(e) => {
            setValues((prev: any) => ({
              ...prev,
              company_name: e.target.value,
            }));
          }}
          value={signUpValues?.company_name}
          placeholder="Company name ..."
          className="text- text-xs font-medium text-slate-700 placeholder:text-slate-400 h-12 w-full 
           bg-white box-shadow-half rounded-lg outline-none border-0 relative hover:!shadow-cyan-800/50
            hover:border hover:border-cyan-800/50 transition-all px-4 pl-10 focus:outline-none focus:ring-0"
        />
      </div>

      {/**Comapny address ============== */}
      <div className="w-full md:w-[24.5rem] h-fit shrink-0 relative">
        <HiOutlineLocationMarker className="text-xl text-slate-500 absolute left-3 top-3.5 z-10" />
        <input
          type="text"
          required
          onChange={(e) => {
            setValues((prev: any) => ({
              ...prev,
              address: e.target.value,
            }));
          }}
          value={signUpValues?.address}
          placeholder="Company address ..."
          className="text- text-xs font-medium text-slate-700 placeholder:text-slate-400 h-12 w-full 
           bg-white box-shadow-half rounded-lg outline-none border-0 relative hover:!shadow-cyan-800/50
            hover:border hover:border-cyan-800/50 transition-all px-4 pl-10 focus:outline-none focus:ring-0"
        />
      </div>

      {/**Comapny Website ============== */}
      <div className="w-full md:w-[24.5rem] h-fit shrink-0 relative">
        <TbLink className="text-xl text-slate-500 absolute left-3 top-3.5 z-10" />
        <input
          type="text"
          onChange={(e) => {
            setValues((prev: any) => ({
              ...prev,
              website: e.target.value,
            }));
          }}
          value={signUpValues?.website||"no-site"}
          placeholder="Company website ..."
          className="text- text-xs font-medium text-slate-700 placeholder:text-slate-400 h-12 w-full 
         bg-white box-shadow-half rounded-lg outline-none border-0 relative hover:!shadow-cyan-800/50
          hover:border hover:border-cyan-800/50 transition-all px-4 pl-10 focus:outline-none focus:ring-0"
        />
      </div>

      {/**Controls ============== */}
      <div className="mt-6 h-fit w-full md:w-[24.5rem] flex items-center justify-between gap-6 px-1">
        <button
          onClick={() => {
            setStep((prev: StepsType[]) => [
              ...prev.map((data: StepsType) => ({
                ...data,
                active: data?.name === "business" ? true : false,
              })),
            ]);
          }}
          type="button"
          className="h-10 w-32 rounded-full border border-slate-200 hover:border-cyan-800 
           transition-all text-xs font-semibold text-slate-600 bg-white"
        >
          Prev
        </button>
        <button
          onClick={() => {
            setStep((prev: StepsType[]) => [
              ...prev.map((data: StepsType) => ({
                ...data,
                active: data?.name === "personal" ? true : false,
              })),
            ]);
          }}
          type="button"
          className="h-10 w-32 rounded-full border border-slate-200 hover:border-cyan-800 
           transition-all text-xs font-semibold text-slate-600 bg-white capitalize"
        >
          next
        </button>
      </div>
    </motion.div>
  );
};
export default AdditionalInfo;
