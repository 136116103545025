import { FC } from "react";
import CustomersList from "./CustomersList";
import { RiDeleteBin6Line } from "react-icons/ri";

type Props = {
  setDetails: any;
  additional_details: any;
  selectedMethod: any;
};

const CustomerDetails: FC<Props> = ({
  setDetails,
  additional_details,
  selectedMethod,
}) => {
  //Component =============
  return (
    <div
      className={`w-full h-fit gap-2 p-4 rounded-md bg-slate-50/50 dark:bg-black-700 
      border border-slate-300 dark:border-black-600 flex flex-col`}
    >
      <div className="h-fit w-full relative group">
        <input
          onChange={(e) => {
            setDetails((prev: any) => ({
              ...prev,
              customers_details: {
                ...prev?.customers_details,
                name: e.target.value,
              },
            }));
          }}
          required={selectedMethod?.["requires customer details"]}
          value={additional_details?.customers_details?.name}
          autoComplete="off"
          type="text"
          name="ename"
          id="ename"
          placeholder={
            selectedMethod?.["requires customer details"]
              ? "Search for an account"
              : "Customer's name ..."
          }
          className="checkputCustomerInput"
        />
        <CustomersList
          searchValue={additional_details?.customers_details?.name ?? ""}
          setActiveCustomer={setDetails}
        />
      </div>

      <input
        onChange={(e) => {
          setDetails((prev: any) => ({
            ...prev,
            customers_details: {
              ...prev?.customers_details,
              "primary phone": e.target.value,
            },
          }));
        }}
        readOnly={selectedMethod?.["requires customer details"]}
        required={selectedMethod?.["requires customer details"]}
        value={additional_details?.customers_details?.["primary phone"]}
        autoComplete="off"
        type="text"
        name="primary phone"
        id="primary phone"
        placeholder="Customer's phone like 27 12 345 6789"
        className="checkputCustomerInput"
      />

      <input
        onChange={(e) => {
          setDetails((prev: any) => ({
            ...prev,
            customers_details: {
              ...prev?.customers_details,
              email: e.target.value,
            },
          }));
        }}
        readOnly={selectedMethod?.["requires customer details"]}
        required={selectedMethod?.["requires customer details"]}
        value={additional_details?.customers_details?.email}
        autoComplete="off"
        type="email"
        name="email"
        id="email"
        placeholder="Customer's email ..."
        className="checkputCustomerInput"
      />

      <input
        onChange={(e) => {
          setDetails((prev: any) => ({
            ...prev,
            customers_details: {
              ...prev?.customers_details,
              address: e.target.value,
            },
          }));
        }}
        readOnly={selectedMethod?.["requires customer details"]}
        required={selectedMethod?.["requires customer details"]}
        value={additional_details?.customers_details?.address}
        autoComplete="off"
        type="text"
        name="address"
        id="address"
        placeholder="Customer's address ..."
        className="checkputCustomerInput"
      />

      <div className="mt-2 w-full flex items-center gap-4">
        <textarea
          onChange={(e) => {
            setDetails((prev: any) => ({
              ...prev,
              note: e.target.value,
            }));
          }}
          value={additional_details?.note}
          autoComplete="off"
          name="note"
          id="note"
          placeholder="Add a note ..."
          className="w-full h-12 rounded bg-white dark:bg-black-700 border-2
           border-dashed border-slate-300 dark:border-black-500 darkpl
             text-xs text-slate-700 placeholder:text-slate-500 dark:placeholder:text-black-400 p-3 focus:ring-0
              focus:border-cyan-800 transition-all resize-none font-medium"
        ></textarea>
        <button
          onClick={() => {
            setDetails((prev: any) => ({
              ...prev,
              customers_details: {
                ...prev?.customers_details,
                address: "",
                name: "",
                email: "",
                "primary phone": "",
              },
              note: "",
            }));
          }}
          title="Clear cutomers data"
          type="button"
          className="h-12 w-12 min-w-[3rem] bg-white dark:bg-black-700 rounded border flex items-center
           justify-center text-red-600 text-lg hover:border-red-500 dark:border-black-500 ransition-all"
        >
          <RiDeleteBin6Line />
        </button>
      </div>
    </div>
  );
};

export default CustomerDetails;
