import { onSnapshot, query, where } from "firebase/firestore";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { allusersRef, org } from "./Firestore_Func";
import { udpateTeam } from "../Redux/Slices/TeamSlice";

const TeamData = () => {
  const dispatch = useDispatch();

  //Fetch Data And Store It Locally
  useEffect((): any => {
    return onSnapshot(
      query(allusersRef, where("workspaces_names", "array-contains", org)),
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        dispatch(
          udpateTeam(
            snapshot.docs.map((doc: { data: () => any; id: any }) => ({
              ...doc.data()?.workspaces?.find((data: any) => data?.workspace_name === org),
              main_id: doc.id,
              id: doc.id,
            }))
          )
        );
      }
    );
  }, [dispatch]);

  //Compoent ===========================
  return <></>;
};

export default TeamData;
