import { FC, useMemo } from "react";
import Product from "./Product";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import no_data from "../../../Assets/pos_empty.png";
import ProductPreview from "./ProductPreview";
import { AnimatePresence } from "framer-motion";

type Props = {
  selectedCategory: any;
};

const ProductsList: FC<Props> = ({ selectedCategory }) => {
  const navigate = useNavigate();
  const productsLoadingState = useSelector(
    (state: RootState) => state.Inventory.productsLoadingState
  );
  const fetchedInventoryData = useSelector(
    (state: RootState) => state.Inventory.inventory_data
  );
  const filteredInventoryData = useMemo(() => {
    return fetchedInventoryData
      ?.filter((inven: any) => {
        return (inven?.platforms_visibility?.includes("pos") &&
          (selectedCategory?.length >= 1
            ? selectedCategory?.some(
                (category: string) =>
                  inven?.category
                    ?.toString()
                    ?.toLowerCase()
                    ?.replace(/\s/gim, "") === category
              )
            : true)
        );
      })
      .sort((a: any, b: any) => (a?.name < b?.name ? -1 : 1))
      .slice(0, 100);
  }, [fetchedInventoryData,selectedCategory]);
  const selected_product = useSelector(
    (state: RootState) => state.OrderData.selected_product
  );

  //COmponent =============
  return (
    <div className="w-full h-[calc(100%-4rem)] flex flex-col overflow-hidden bg-transparent">
      <div
        className="w-full h-full bg-transparent px-0.5 overflow-hidden overflow-y-scroll no-scrollbar 
        no-scrollbar::-webkit-scrollbar grid grid-cols-2 md:grid-cols-3 sm:grid-cols-4 lg:grid-cols-3
         xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-2 auto-rows-min relative"
      >
        <AnimatePresence>
          {filteredInventoryData?.length >= 1 || productsLoadingState ? (
            !productsLoadingState ? (
              filteredInventoryData?.map((prod: any) => (
                <Product key={prod?.id} prod={prod} />
              ))
            ) : (
              Array(8)
                .fill(0)
                .map((holder: undefined | any, index: number) => {
                  return (
                    <div
                      key={holder + index}
                      className="flex-col col-span-1 p-2 gap-2 h-16 min-h-[11.5rem]
                       select-none cursor-pointer group bg-white dark:bg-black-900 rounded-md border
                     border-slate-200 dark:border-black-800"
                    >
                      <div
                        className="w-full h-28 rounded-lg bg-slate-100/80 selection:dark:bg-black-700/80 animate-pulse"
                      ></div>
                      <div className="flex flex-col justify-center gap-2 w-full">
                        <div className="w-full h-4 bg-slate-100/80 dark:bg-black-700/80 rounded-sm animate-pulse"></div>
                        <div className="w-4/5 h-4 bg-slate-100/80 dark:bg-black-700/80 rounded-sm animate-pulse"></div>
                      </div>
                    </div>
                  );
                })
            )
          ) : (
            <div className="absolute left-0 right-0 bottom-0 top-0 flex flex-col items-center justify-center gap-3">
              <img
                src={no_data}
                alt="no_data"
                className="opacity-70 h-14 w-auto"
              />
              <p className="text-center text-xs font-medium text-slate-600">
                You have no data under this view, click the <br /> button below
                to add products
              </p>
              <div className="flex justify-center w-full">
                <button
                  onClick={() => navigate("/inventory")}
                  className="h-9 w-32 rounded bg-cyan-800 hover:bg-cyan-700 text-xxs
                     uppercase text-white font-semibold transition-all"
                >
                  add products
                </button>
              </div>
            </div>
          )}
        </AnimatePresence>
      </div>

      {/** ============= Product Preview================ */}
      <AnimatePresence>
        {selected_product?.prod_obj?.id && <ProductPreview />}
      </AnimatePresence>
    </div>
  );
};

export default ProductsList;
