import React from "react";
import { motion } from "framer-motion";
import { StepsType } from "./SignUp";
import { TbLock, TbPhone, TbUserEdit } from "react-icons/tb";
import { MdAlternateEmail } from "react-icons/md";
import { getPasswordStrength } from "../../../Utils/Reusable_Func";

type Props = {
  signUpValues: any;
  setValues: any;
  setStep: any;
  loading: boolean;
};

const PersonalInfo = ({ signUpValues, setValues, setStep, loading }: Props) => {
  const strength = getPasswordStrength(
    signUpValues?.password,
    signUpValues?.name,
    signUpValues?.email
  );

  //Component ====================
  return (
    <motion.div
      initial={{ right: "-100%", left: "100%", opacity: 0.3 }}
      exit={{ right: "-100%", left: "100%", opacity: 0.3 }}
      animate={{ right: 0, left: 0, opacity: 1 ,zIndex:9999}}
      transition={{ duration: 0.3 }}
      className="mt-5 w-full md:w-fit h-fit min-h-[25rem] px-2 md:px-0 relative flex flex-col gap-3"
    >
      {/**Fullname ============== */}
      <div className="w-full md:w-[24.5rem] h-fit shrink-0 relative">
        <TbUserEdit className="text-xl text-slate-500 absolute left-3 top-3.5 z-10" />
        <input
          type="text"
          required
          onChange={(e) => {
            setValues((prev: any) => ({ ...prev, name: e.target.value }));
          }}
          value={signUpValues?.name}
          placeholder="Your full name ..."
          className="text- text-xs font-medium text-slate-700 placeholder:text-slate-400 h-12 w-full 
           bg-white box-shadow-half rounded-lg outline-none border-0 relative hover:!shadow-cyan-800/50
            hover:border hover:border-cyan-800/50 transition-all px-4 pl-10 focus:outline-none focus:ring-0"
        />
      </div>

      {/**Phone ============== */}
      <div className="w-full md:w-[24.5rem] h-fit shrink-0 relative">
        <TbPhone className="text-xl text-slate-500 absolute left-3 top-3.5 z-10" />
        <input
          type="number"
          required
          minLength={10}
          onChange={(e) => {
            setValues((prev: any) => ({ ...prev, phone: e.target.value }));
          }}
          value={signUpValues?.phone}
          placeholder="Phone number..."
          className="text- text-xs font-medium text-slate-700 placeholder:text-slate-400 h-12 w-full 
        bg-white box-shadow-half rounded-lg outline-none border-0 relative hover:!shadow-cyan-800/50
          hover:border hover:border-cyan-800/50 transition-all px-4 pl-10 focus:outline-none focus:ring-0"
        />
      </div>

      {/**Email Address============== */}
      <div className="w-full md:w-[24.5rem] h-fit shrink-0 relative">
        <MdAlternateEmail className="text-xl text-slate-500 absolute left-3 top-3.5 z-10" />
        <input
          type="email"
          required
          onChange={(e) => {
            setValues((prev: any) => ({ ...prev, email: e.target.value }));
          }}
          value={signUpValues?.email}
          placeholder="Email address ..."
          className="text- text-xs font-medium text-slate-700 placeholder:text-slate-400 h-12 w-full 
           bg-white box-shadow-half rounded-lg outline-none border-0 relative hover:!shadow-cyan-800/50
            hover:border hover:border-cyan-800/50 transition-all px-4 pl-10 focus:outline-none focus:ring-0"
        />
      </div>

      {/**Password ============== */}
      <div className="flex flex-col gap-2 w-full md:w-[24.5rem]">
        <div className="w-full h-fit shrink-0 relative">
          <TbLock className="text-xl text-slate-500 absolute left-3 top-3.5 z-10" />
          <input
            type="password"
            pattern="^(?=.*[A-Z])(?=.*[!@#$&.*])(?=.*[0-9]).{8,}$"
            minLength={8}
            autoComplete="none"
            required
            onChange={(e) => {
              setValues((prev: any) => ({ ...prev, password: e.target.value }));
            }}
            value={signUpValues?.password}
            placeholder="Password ..."
            className="text- text-xs font-medium text-slate-700 placeholder:text-slate-400 h-12 w-full 
        bg-white box-shadow-half rounded-lg outline-none border-0 relative hover:!shadow-cyan-800/50
          hover:border hover:border-cyan-800/50 transition-all px-4 pl-10 focus:outline-none focus:ring-0"
          />
        </div>
        <div className="h-3 w-full flex justify-between items-center gap-2 px-0.5 shrink-0">
          {[25, 50, 75, 90].map((threshold, index) => (
            <div
              key={index}
              className={`h-2.5 w-1/4 rounded-full border border-slate-200 ${
                index === 0 && Number(strength) < threshold
                  ? "bg-white"
                  : Number(strength) >= threshold
                  ? "bg-green-500"
                  : Number(strength) >= threshold - 15
                  ? "bg-lime-400"
                  : Number(strength) >= threshold - 25
                  ? "bg-yellow-400"
                  : "bg-white"
              } transition-all duration-200`}
            ></div>
          ))}
        </div>

        <p className="w-full text-center text-slate-500 text-xs font-medium px-0.5">
          Your password must be 8 or more charater including at least one
          special characters (e.g. @, #, $, %, .), number and an uppercase.
        </p>
      </div>

      {/**Controls ============== */}
      <div className="mt-6 h-fit w-full md:w-[24.5rem] flex items-center justify-between gap-6 px-1">
        <button
          onClick={() => {
            setStep((prev: StepsType[]) => [
              ...prev.map((data: StepsType) => ({
                ...data,
                active: data?.name === "additional" ? true : false,
              })),
            ]);
          }}
          type="button"
          className="h-10 w-32 rounded-full border border-slate-200 hover:border-cyan-800 
           transition-all text-xs font-semibold text-slate-600 bg-white"
        >
          Back
        </button>
        <button
          onClick={() => {
            if (
              !signUpValues?.company_name ||
              !signUpValues?.address
            ) {
              setStep((prev: StepsType[]) => [
                ...prev.map((data: StepsType) => ({
                  ...data,
                  active: data?.name === "additional" ? true : false,
                })),
              ]);
            }
          }}
          type="submit"
          className="h-10 w-32 rounded-full flex items-center justify-center border border-slate-200 hover:border-cyan-800 
           transition-all text-xs font-semibold text-slate-600 bg-white capitalize"
        >
          {!loading ? (
            <span>Complete</span>
          ) : (
            <div className="h-5 w-5 border-4 border-cyan-100 border-l-cyan-800 animate-spin rounded-full"></div>
          )}
        </button>
      </div>
    </motion.div>
  );
};

export default PersonalInfo;
