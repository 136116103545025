import { FC, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//import GettingStarted from "./Pages/GettingStarted";
import Login from "./Pages/Auth/Login";
import SignUp from "./Pages/Auth/Signup/SignUp";
import PointOfSell from "./Pages/POS/PointOfSell";
import Sales from "./Pages/Sales/Sales";
import ErrorBoundary from "./ErrorBoundary";
import PrivateRoutes from "./PrivateRoutes";
import Loader from "./Components/Misc/Loader";
import NotFound from "./not-found";
//Lazy Loading Components
const Apps = lazy(() => import("./Pages/Apps/Apps"));
const AppShell = lazy(() => import("./Pages/App Shell/AppShell"));
const Campaigns = lazy(() => import("./Pages/Campaigns/Campaigns"));
const Reports = lazy(() => import("./Pages/Reports/Reports"));
const Customers = lazy(() => import("./Pages/Customers/Customers"));
const Inventory = lazy(() => import("./Pages/Inventory/Inventory"));
const OtherPortals = lazy(() => import("./Pages/Other Portals/OtherPortals"));
const Settings = lazy(() => import("./Pages/Settings/Settings"));
const Products = lazy(() => import("./Pages/Inventory/Products/Products"));
const StockEvaluation = lazy(
  () => import("./Pages/Inventory/Evaluation/StockEvaluation")
);
const StockOrder = lazy(
  () => import("./Pages/Inventory/Stock Orders/StockOrder")
);
const Vendors = lazy(() => import("./Pages/Inventory/Vendors/Vendors"));
const CustomerFacingDisplay = lazy(
  () => import("./Pages/Customers Facing Display/CDisplay")
);

const App: FC = () => {
  //Component || App
  return (
    <ErrorBoundary>
      <div className="overflow-hidden">
        <BrowserRouter>
          <Suspense
            fallback={
              <Loader/>
            }
          >
            <Routes>
                {/**Error Page ===========================*/}
                <Route path="*" element={<NotFound />} />
              <Route path="/logIn" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route
                path="/customer-display"
                element={<CustomerFacingDisplay />}
              />
              <Route element={<PrivateRoutes />}>
                <Route path="/" element={<AppShell />}>
                  <Route path="" element={<PointOfSell />} />
                  <Route path="sales" element={<Sales />} />
                  <Route path="campaigns" element={<Campaigns />} />
                  <Route path="apps-integrations" element={<Apps />} />
                  <Route path="reports" element={<Reports />} />
                  <Route path="customers" element={<Customers />} />
                  <Route path="inventory" element={<Inventory />}>
                    <Route path="" element={<Products />} />
                    <Route path="stock-orders" element={<StockOrder />} />
                    <Route path="vendors" element={<Vendors />} />
                    <Route path="evaluation" element={<StockEvaluation />} />
                  </Route>
                  <Route path="other_channels" element={<OtherPortals />} />
                  <Route path="settings" element={<Settings />} />
                </Route>
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
      </div>
    </ErrorBoundary>
  );
};

export default App;
