import { FC, useState } from 'react'
import Cart from '../Cart/Cart';
import CheckOut from '../Checkout/CheckOut';

type Props = {
    smallScreenCart:any;
     setSmCart:any;
}

const CartContainer:FC<Props> = ({smallScreenCart, setSmCart}) => {
    const [isCheckout, openCheckout] = useState<boolean>(false);

    //Components ============
  return (
    <>{/** ============= Cart ================ */}
    <Cart
      openCheckout={openCheckout}
      smallScreenCart={smallScreenCart}
      setSmCart={setSmCart}
    />

    {/**CheckOut ================== */}
    <CheckOut
      isCheckout={isCheckout}
      openCheckout={openCheckout}
      setSmCart={setSmCart}
    /></>
  )
}

export default CartContainer