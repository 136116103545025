import { Ring } from "@uiball/loaders";
import React from "react";

const Loader = () => {
  return (
    <div
      className="h-screen w-screen  bg-gradient-to-br from-white via-cyan-800/10 to-light-bg
       flex flex-col items-center justify-center overflow-hidden"
    >
      <Ring size={60} lineWeight={5} speed={2} color="#155e75" />
    </div>
  );
};

export default Loader;
