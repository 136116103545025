/* eslint-disable no-unsafe-optional-chaining */
import { FC, useEffect, useMemo, useState, useRef } from "react";
import no_gallery from "../../../Assets/no_preview_card.png";
import { TbPlus, TbMinus } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { numberWithSpaces } from "../../../Reusable Functions/Functions";
import {
  customizeProduct,
  openPreview,
  updateCart,
} from "../../../Redux/Slices/OrderSlice";
import { motion } from "framer-motion";
import Customizations from "./Customizations";
import { RiArrowDownSLine } from "react-icons/ri";
import { useClickOutside } from "@mantine/hooks";

// type Props = {
// };

const ProductPreview: FC = () => {
  const dispatch = useDispatch();
  const previewOpen = useSelector(
    (state: RootState) => state.OrderData.previewOpen
  );
  const selected_product = useSelector(
    (state: RootState) => state.OrderData.selected_product
  );
  const cart = useSelector((state: RootState) => state.OrderData.cart);
  const [quantity, setQuantity] = useState<number | null>(null);
  const selectedCurrency = useSelector(
    (state: RootState) => state.SettingsData.selectedCurrency
  );
  const inventory_data = useSelector(
    (state: RootState) => state.Inventory.inventory_data
  );
  const currentProd = useMemo(() => {
    return inventory_data?.find(
      (data: any) => data?.id_two === selected_product?.prod_obj?.id_two
    );
  }, [selected_product?.prod_obj, inventory_data]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [selectedImg, setImg] = useState<any>(null);
  const imgGallery = useMemo(() => {
    let urls = currentProd && [...currentProd?.gallery];
    if (currentProd?.thumbnail) {
      urls = [
        { id: new Date().getTime(), url: currentProd?.thumbnail },
        ...urls,
      ];
    }

    return currentProd && urls;
  }, [currentProd]);

  //UseEffect Top Set Image if empty
  useEffect(() => {
    if (!selectedImg) {
      setImg(selected_product?.thumbnail);
    }
  }, [selected_product, selectedImg]);

  //Update Quantity On Initial Load
  useEffect(() => {
    if (!quantity) {
      setQuantity(selected_product?.quantity || 1);
    }
  }, [quantity, selected_product?.quantity]);

  //Close Fucntion ===================
  const closeFunc = () => {
    dispatch(customizeProduct(null));
    setImg("");
    dispatch(openPreview(false));
    setQuantity(1);
  };

  //Close on click Outside
  const modalRef = useClickOutside(() => closeFunc());

  //Add Product To Cart =============================
  const addToCart = () => {
    dispatch(
      updateCart({
        ...cart,
        products: [
          ...(cart?.products
            ? cart?.products?.filter(
                (data: any) =>
                  data?.prod_cart_uid !== selected_product?.prod_cart_uid
              )
            : []),
          {
            ...selected_product,
            quantity: Number(quantity) <= 0 ? 1 : Number(quantity),
          },
        ],
      })
    );
    closeFunc();
  };

  //Check If Quanity Is greater than stock
  useEffect(() => {
    if (
      quantity &&
      currentProd?.length >= 1 &&
      Number(currentProd?.in_stock) < quantity &&
      currentProd?.has_stock_count
    ) {
      setQuantity(Number(currentProd?.in_stock));
    }
  }, [currentProd, quantity, setQuantity]);

  //Shorcut Event Listener =======
  useEffect(() => {
    const cancelPreviewShortcut = (e: any) => {
      if (previewOpen) {
        if (e.key?.toString() === "Escape") {
          closeFunc();
        }
        if (e?.key?.toString() === "F9") {
          inputRef && inputRef?.current && inputRef?.current?.focus();
        }
        if (e.key === "Enter" && !e.shiftKey) {
          addToCart();
        }
      }
    };
    window.addEventListener("keydown", cancelPreviewShortcut, true);
    return () =>
      window.removeEventListener("keydown", cancelPreviewShortcut, true);
  });

  //Component
  return (
    <motion.div
      initial={{ bottom: "-100%", top: "100%" }}
      exit={{ bottom: "-100%", top: "100%" }}
      animate={{ bottom: 0, top: 0, position: "fixed" }}
      transition={{ duration: 0.3, type: "spring" }}
      className={`fixed top-0 bottom-0 left-0 right-0 z-[999999] flex justify-center items-center
       backdrop-blur-sm bg-white dark:bg-black-900 dark:md:bg-black-900/30 
       md:bg-black-900/30 overflow-hidden`}
    >
      <div
        ref={modalRef}
        className="h-full md:h-fit w-fit p-0 md:p-6 md:pb-12 md:rounded-lg bg-slate-50/80 dark:bg-black-700/80 md:border
       border-slate-300 dark:border-black-600 backdrop-blur-sm md:shadow-2xl max-w-screen overflow-hidden relative"
      >
        {/**Md Close Btn */}
        <div
          className="absolute bottom-2 right-6 left-6 h-8 rounded-sm md:rounded outline-none focus:outline-none
        bg-transparent transition-all text-slate-600 dark:text-black-300 text-xs font-semibold
        md:flex items-center justify-between hidden"
        >
          <span>
            Last updated :{" "}
            {
              new Date(selected_product?.prod_obj?.last_editedAt || "")
                .toString()
                ?.split("GMT")?.[0]
            }
          </span>
          <button
            onClick={() => closeFunc()}
            className="bg-inherit hover:!text-cyan-600"
          >
            Close (Esc)
          </button>
        </div>

        <div
          className="h-full w-screen md:w-[42rem] md:h-[30rem] max-h-full max-w-full p-4 pt-20 md:pt-4 bg-white
           dark:bg-black-900 md:rounded-lg relative grid grid-cols-2 md:grid-cols-4 gap-4 md:border
            border-slate-300 dark:border-black-600 overflow-hidden overflow-y-scroll md:overflow-y-hidden no-scrollbar 
            no-scrollbar::webkit-scrollbar"
        >
          {/**Close Btn SM  ====================*/}
          <div className="w-full h-fit py-4 flex md:hidden fixed top-0 bg-inherit z-10">
            <button
              onClick={() => closeFunc()}
              className="h-9 w-9 outline-none focus:outline-none bg-light-bg dark:bg-black-700 rounded-md md:rounded border
             border-slate-200 dark:border-black-600 transition-all text-slate-600 dark:text-black-300
             text-lg font-semibold flex items-center justify-center md:hidden shrink-0"
            >
              <RiArrowDownSLine className="text-2xl" />
            </button>
          </div>

          {/**images  Prev*/}
          <div className="col-span-2 w-full h-full flex flex-col gap-4 md:overflow-hidden">
            <div
              className="w-full h-[20rem] md:h-[calc(100%-5.25rem)] shrink-0 bg-light-bg dark:bg-black-800
              rounded-md overflow-hidden"
            >
              <img
                onError={(e) => {
                  e.currentTarget.src = no_gallery;
                }}
                src={
                  selectedImg
                    ? selectedImg
                    : currentProd?.thumbnail || no_gallery
                }
                alt="prod_preview"
                className="w-full h-full object-center object-fit object-cover overflow-hidden
                   bg-light-bg dark:bg-black-700 border-2 border-slate-200 dark:border-black-600
                   cursor-zoom-in rounded-lg"
              />
            </div>
            <div
              className="w-full h-16 flex items-center gap-0.5 overflow-hidden overflow-x-scroll no-scrollbar
              no-scrollbar::-webkit-scrollbar rounded-md md:rounded bg-inherit p-0.5 shrink-0
               border border-slate-200 dark:border-black-600 bg-light-bg dark:bg-black-700"
            >
              {imgGallery?.length >= 1 &&
                imgGallery?.map((img: any) => {
                  return (
                    <img
                      onError={(e) => {
                        e.currentTarget.src = no_gallery;
                      }}
                      onClick={() => setImg(img?.url)}
                      key={img?.id}
                      src={img?.url}
                      alt="prev"
                      className="w-14 h-14 shrink-0 rounded-[inherit] bg-white dark:bg-black-900
                       object-center object-cover object-fit cursor-pointer overflow-hidden
                       border border-slate-200 dark:border-black-600"
                    />
                  );
                })}
            </div>
          </div>

          {/**Details*/}
          <div
            className="col-span-2 w-full h-full flex flex-col justify-between gap-4 relative md:overflow-hidden"
          >
            <div className="w-full h-fit md:h-full flex flex-col overflow-hidden">
              <span
                className="mt-2 text-lg font-bold text-slate-700 dark:text-black-200 whitespace-nowrap
                   overflow-hidden text-ellipsis uppercase w-full shrink-0"
              >
                {selected_product?.prod_obj?.name}
              </span>
              <div
                className={`w-w-full text-sm font-bold text-slate-600 dark:text-black-300 uppercase flex items-center gap-1`}
              >
                <span
                  className={`truncate whitespace-nowrap shrink-0 ${
                    selected_product?.prod_obj?.has_discounted_price &&
                    "line-through opacity-80"
                  }`}
                >
                  {selectedCurrency?.symbol}&nbsp;
                  {numberWithSpaces(
                    (
                      selectedCurrency?.rate_multiplier *
                      Number(selected_product?.prod_obj?.price_in_usd) *
                      Number(quantity)
                    ).toFixed(2)
                  )}
                </span>
                {selected_product?.prod_obj?.has_discounted_price && (
                  <span className={`truncate whitespace-nowrap shrink-0`}>
                    {selectedCurrency?.symbol}&nbsp;
                    {numberWithSpaces(
                      (
                        selectedCurrency?.rate_multiplier *
                        Number(selected_product?.prod_obj?.discount_price) *
                        Number(quantity)
                      ).toFixed(2)
                    )}
                  </span>
                )}
              </div>
              <span
                className="text-xss font-medium text-slate-500 dark:text-black-300 whitepsace-nowrap
                   truncate uppercase w-full shrink-0"
              >
                In Stock :&nbsp;
                <span className="text-cyan-800 dark:text-cyan-600 text-xs font-bold">
                  {currentProd?.has_stock_count
                    ? Number(currentProd?.in_stock)?.toFixed(
                        currentProd?.measurement_units?.decimal_precision
                      )
                    : "unlimited"}{" "}
                  {currentProd?.measurement_units?.symbol}
                </span>
              </span>
              <hr className="my-3 w-full border-slate-300 dark:border-black-500" />
              <span
                className="text-xs font-bold text-slate-600 dark:text-black-300
                whitespace-nowrap overflow-hidden text-ellipsis uppercase w-full shrink-0"
              >
                Description
              </span>
              <p
                className="mt-1 text-xs font-normal text-slate-600 dark:text-black-300 line-clamp-3
               overflow-hidden shrink-0"
              >
                {selected_product?.prod_obj?.description}
              </p>

              <hr className="my-2 w-full border-slate-300 dark:border-black-500" />
              <Customizations selected_product={selected_product} />
            </div>

            {/**Controls*/}
            <div className="w-full h-14 shrink-0 flex items-center justify-between bg-white dark:bg-black-900 sticky -bottom-4">
              <div
                className="h-10 w-fit rounded-full md:rounded flex justify-between items-center overflow-hidden border
               border-cyan-800 divide-x divide-cyan-800/30 bg-light-bg dark:bg-black-700"
              >
                <button
                  disabled={quantity ? quantity <= 1 : false}
                  onClick={() => {
                    setQuantity((prev: number | null) =>
                      prev || 1 <= 1 ? 1 : prev || 1 - 1
                    );
                  }}
                  className="h-full w-9 flex items-center justify-center text-base text-cyan-800
                hover:bg-cyan-50 transition-all disabled:cursor-not-allowed disabled:opacity-80"
                >
                  <TbMinus />
                </button>
                <input
                  ref={inputRef}
                  onChange={(e) => {
                    setQuantity(Number(e.target.value));
                  }}
                  value={quantity || 1}
                  onFocus={(e) => e.target.select()}
                  autoComplete="none"
                  type="number"
                  name={selected_product?.prod_obj?.id_two}
                  id={selected_product?.prod_obj?.id_two}
                  className="h-full w-16 border-0 text-xs text-slate-600 dark:text-black-300 px-2 text-center font-medium
                   focus:ring-0 bg-inherit"
                />
                <button
                  onClick={() => {
                    if (
                      quantity &&
                      currentProd &&
                      Number(currentProd?.in_stock) > quantity &&
                      currentProd?.has_stock_count
                    ) {
                      setQuantity((prev: number | null) => (prev || 1) + 1);
                    } else if (!currentProd?.has_stock_count) {
                      setQuantity((prev: number | null) => (prev || 1) + 1);
                    }
                  }}
                  className="h-full w-9 flex items-center justify-center text-base text-cyan-800
             hover:bg-cyan-50 transition-all"
                >
                  <TbPlus />
                </button>
              </div>
              <button
                onClick={() => addToCart()}
                className="h-10 w-40 rounded-full md:rounded flex items-center justify-center space-x-3
               bg-cyan-800 text-white text-xs font-semibold uppercase hover:bg-cyan-800 transition-all"
              >
                <span> Add to cart</span>{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ProductPreview;
